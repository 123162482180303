import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';
import Footer from './Footer';
import DropDownImg from '../Assets/Home/down.svg';
import exchange from '../Assets/Home/exchange.svg';
import trustedBy from '../Assets/Home/trustedBy.svg';
import AllProduct from './Cards/AllProducts';
import flexTradeImage from '../Assets/Home/flexTrades.svg';
import flexTradeText from '../Assets/Home/bannerFlex.svg';
import ReviewsCont from './Cards/ReviewsCont';
import PhotoCont from './Cards/PhotoCont';
import EximProcess from './Cards/EximProcess';
import left from '../Assets/Home/blackLeft.svg';
import right from '../Assets/Home/blackRight.svg';
import leftG from '../Assets/Home/leftGray.svg';
import rightG from '../Assets/Home/rightGray.svg';
import { PrimaryThemeColor, PrimaryFont } from './Constants';
import { useDispatch, useSelector } from 'react-redux';

import {
	about,
	contactLoader,
	getHomeProducts,
	ThemeColor,
	token,
	uid,
	settings,
} from '../ReduxToolkit/useReducer';
import { Helmet } from 'react-helmet';
import LazyLoad from 'react-lazy-load';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Popups from './Cards/Popups';
import axios from 'axios';
import { useHistory } from 'react-router';

import flag1 from '../Assets/svg/ad.svg';
import flag2 from '../Assets/svg/ae.svg';
import flag3 from '../Assets/svg/af.svg';
import flag4 from '../Assets/svg/ag.svg';
import flag5 from '../Assets/svg/al.svg';
import Loader from './Loader';

const w = 450 + 'px';

const MainCont = styled(motion.div)`
	width: 70%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	margin-top: 40px;
	overflow: hidden;
	max-width: 1200px;
	@media (max-width: 1366px) {
		margin-top: 10px;
	}
	@media (max-width: 1100px) {
		width: 95%;
	}
	@media (max-width: 768px) {
		width: 90%;
		max-width: ${w};
		overflow: hidden;
	}
`;

const HeaderPart = styled(motion.div)`
	display: flex;
	justify-content: center;
`;

const Currency = styled(motion.div)`
	display: flex;
	flex-direction: row;
	margin-top: 10px;
	justify-content: space-around;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	height: 65px;
	width: 450px;
	background-color: #fff;
	border-radius: 10px;
	margin-bottom: 20px;
	padding: 15px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

	@media (max-width: 768px) {
		width: 98%;
		margin-top: 10px;
		height: 50px;
		max-width: ${w};
	}
`;

const DropDownItems = styled(motion.h1)`
	text-decoration: none;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 5px;
	width: 80%;
	cursor: pointer;
	font-family: ${PrimaryFont};
	font-weight: 400;
	font-size: 17px;
	color: ${(prop) => prop.Color};
	/* color: ${PrimaryThemeColor}; */
	z-index: 15;
	/* font-diapls:swap; */
`;

const DropDownCont = styled(motion.div)`
	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;
`;

const DropDownTitle = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 700;
	font-size: 17px;
	/* color: ${PrimaryThemeColor}; */
	color: ${(prop) => prop.Color};
`;

const Image = styled(motion.img)`
	height: 12px;
	width: 10px;
	margin-left: 5px;

	@media (max-width: 1366px) {
	}

	@media (max-width: 768px) {
	}
`;

const Imagee = styled(motion.img)`
	height: 17px;
	width: 20px;
	/* margin-left: 5px; */

	@media (max-width: 1366px) {
	}

	@media (max-width: 768px) {
	}
`;

const DropDownContainer = styled(motion.div)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* left: 50%; */
	/* margin-top: 220px; */
	background-color: #fff;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
		rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
	/* height: 500px; */
	width: 150px;
	height: 300px;
	/* overflow: scroll; */
	border-radius: 10px;
`;

const CurrencyRate = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 400;
	font-size: 19px;
	text-align: center;
`;

const CurrExchange = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 400;
	font-size: 16px;
	margin-top: -10px;
	text-align: center;
	color: #3e3e3e;
`;

const DropD = styled(motion.div)`
	position: absolute;
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	width: 450px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
`;

//! Hero Section ==========================================================>

const HeroSection = styled(motion.div)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: nowrap;
	margin-top: 50px;
	margin-bottom: 50px;
	align-items: center;
	align-content: center;

	@media (max-width: 768px) {
		flex-direction: column;
		margin-top: 20px;
		margin-bottom: 20px;
		flex-wrap: wrap;
	}
`;

const HeaderTitle = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 800;
	font-size: 61px;
	width: 40%;
	line-height: 61px;
	color: #242424;

	@media (max-width: 1366px) {
		margin-bottom: 50px;
		width: 50%;
	}
	/* @media (max-width: 1100px) {
  font-size: 41px;
  line-height: 41px;
} */
	@media (max-width: 768px) {
		width: 90%;
		font-size: 38px;
		line-height: 38px;
	}
`;

const HeaderImagesCont = styled(motion.div)`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	/* justify-content: center;
align-items: center; */
	gap: 5px;
	width: 50%;
	background-color: #fff;
	overflow: hidden;
	/* padding: 10px; */
	border-radius: 30px;

	@media (max-width: 768px) {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}
`;

const HeaderImageBlock = styled(motion.div)`
	display: flex;
`;

const HeaderImages = styled(motion.img)`
	height: 250px;
	width: 100%;
	border-radius: 30px;
	will-change: transform;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
		rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
	object-fit: cover;

	@media (max-width: 1366px) {
		height: 180px;
	}
	@media (max-width: 768px) {
		height: 150px;
	}
	@media (max-width: 400px) {
		height: 130px;
	}
`;

//! All Products Button ==========================================================>

const AllProducts = styled(motion.button)`
	font-family: ${PrimaryFont};
	font-weight: 600;
	font-size: 18px;
	text-align: right;
	background-color: ${(prop) => prop.Color};
	color: #fff;
	width: 80%;
	/* margin-top: -130px; */
	border-radius: 20px;
	align-content: center;
	justify-content: center;
	align-items: center;
	height: 52px;
	outline: none;
	border: none;
	cursor: pointer;

	text-align: center;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

	@media (max-width: 1366px) {
		/* margin-top: -40px; */
	}
	@media (max-width: 768px) {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
	}
`;

//! Trusted By ==========================================================>

const TrustedBy = styled(motion.div)`
	width: 100%;
	background-color: #f4f4f4;
	display: flex;
	height: 315px;
	justify-content: center;
	align-items: center;
	padding-top: 30px;
	padding-bottom: 30px;
	overflow: hidden;
	margin-bottom: 200px;
	margin-top: 200px;

	@media (max-width: 768px) {
		margin-bottom: 50px;
		margin-top: 50px;
	}
`;

const TrustedByCont = styled(motion.div)`
	display: flex;
	height: 100%;
	width: 100%;
	/* margin-left: 900px; */
	margin-top: 30px;
	align-items: center;
	@media (max-width: 768px) {
		width: 90%;
		flex-wrap: nowrap;
		margin-left: 50px;
	}
`;

const TrustedByTitle = styled.h1`
	font-family: ${PrimaryFont};
	font-weight: 700;
	font-size: 46px;
	color: #242424;
	line-height: 46px;
	text-align: center;

	@media (max-width: 768px) {
		font-size: 26px;
	}
`;

const TrustedBySubtitle = styled.h2`
	font-family: ${PrimaryFont};
	font-weight: 400;
	font-size: 16px;
	color: #242424;
	line-height: 36px;
	text-align: center;
	@media (max-width: 768px) {
		font-size: 10px;
	}
`;

const TrustedByImage = styled(motion.img)`
	height: 109px;
	/* width: 174px; */
	will-change: transform;
	margin-left: 50px;
	margin-right: 50px;
	/* margin-right: auto; */
	object-fit: cover;
	/* margin-bottom: 50px; */
`;

const FlexibleTitle = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 700;
	font-size: 46px;
	line-height: 26px;
	/* margin-top: 200px; */
	color: #000;
	@media (max-width: 768px) {
		font-size: 36px;
		line-height: 45px;
		/* text-align: center; */
	}
`;

const Trade = styled(motion.div)`
	width: 70%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 200px;
	margin-bottom: 200px;
	position: relative;
	max-width: 1200px;
	@media (max-width: 1100px) {
		width: 95%;
	}
	@media (max-width: 768px) {
		width: 90%;
		margin-top: 50px;
		margin-bottom: 50px;
	}
`;

const TradeImage = styled(motion.img)`
	width: 100%;
	margin-top: 100px;

	@media (max-width: 1100px) {
		width: 95%;
	}
	@media (max-width: 768px) {
		width: 150%;
		object-fit: cover;
		margin-top: 10px;
	}
`;

const TradeImageItem1 = styled(motion.img)`
	height: 41px;
	width: 41px;
	border-radius: 50%;
	position: absolute;
	right: 0%;
	top: 80%;

	@media (max-width: 768px) {
		display: none;
	}
`;

const TradeImageItem2 = styled(motion.img)`
	height: 64px;
	width: 64px;
	border-radius: 50%;
	position: absolute;
	right: 25%;
	top: 25%;

	@media (max-width: 768px) {
		height: 30px;
		width: 30px;
		right: 10%;
		top: 20%;
	}
`;

const TradeImageItem3 = styled(motion.img)`
	height: 85px;
	width: 85px;
	border-radius: 50%;
	position: absolute;
	right: 40%;
	top: 90%;

	@media (max-width: 768px) {
		height: 32px;
		width: 32px;
		right: 20%;
		top: 95%;
	}
`;

const TradeImageItem4 = styled(motion.img)`
	height: 35px;
	width: 35px;
	border-radius: 50%;
	position: absolute;
	right: 60%;
	top: 30%;

	@media (max-width: 768px) {
		height: 14px;
		width: 14px;
		right: 55%;
		top: 30%;
	}
`;

const TradeImageItem5 = styled(motion.img)`
	height: 120px;
	width: 120px;
	border-radius: 50%;
	position: absolute;
	right: 65%;
	top: 60%;

	@media (max-width: 768px) {
		height: 38px;
		width: 38px;
		right: 70%;
		top: 80%;
	}
`;

const TradeImageItem6 = styled(motion.img)`
	height: 28px;
	width: 28px;
	border-radius: 50%;
	position: absolute;
	right: 100%;
	top: 90%;

	@media (max-width: 768px) {
		display: none;
	}
`;

//! Review Block ==========================================================>

const TitleReview = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 600;
	font-size: 46px;
	color: #000;
	width: 70%;
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
	margin-top: 200px;
	@media (max-width: 768px) {
		font-size: 36px;
		width: 90%;
		margin-top: 50px;
		max-width: ${w};
	}
`;

//! Employee Block ==========================================================>

const EmpComp = styled(motion.div)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 70%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 200px;
	margin-top: 200px;
	overflow: hidden;
	max-width: 1200px;
	@media (max-width: 1100px) {
		width: 95%;
	}
	@media (max-width: 768px) {
		flex-direction: column;
		width: 90%;
		margin-bottom: 50px;
		margin-top: 50px;
		max-width: ${w};
	}
`;

const EmpCompCard = styled(motion.div)`
	width: 40%;
	@media (max-width: 768px) {
		margin-left: auto;
		margin-right: auto;
		width: 90%;
		margin-bottom: 50px;
	}
`;

const EmpCompText = styled(motion.div)`
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	@media (max-width: 768px) {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}
`;

const EmpName = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 600;
	font-size: 46px;
	line-height: 26px;
	color: #242424;
	@media (max-width: 1366px) {
		font-size: 40px;
	}
	@media (max-width: 768px) {
		font-size: 22px;
	}
`;

const EmpOccu = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 400;
	font-size: 20px;
	margin-top: 15px;

	@media (max-width: 1366px) {
		font-size: 15px;
		margin-top: 10px;
	}

	color: #000;
	@media (max-width: 768px) {
		font-size: 13px;
		margin-top: 5px;
	}
`;

const EmpAbout = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 300;
	font-size: 30px;
	color: #000;
	margin-top: 50px;
	@media (max-width: 1366px) {
		font-size: 20px;
	}
	@media (max-width: 768px) {
		font-size: 11px;
		margin-top: 20px;
	}
`;

//! Exim Process Block ==========================================================>

const EximBlock = styled(motion.div)`
	width: 85%;
	margin-left: auto;
	margin-bottom: 200px;
	margin-top: 200px;
	overflow: hidden;
	@media (min-width: 2200px) {
		max-width: 1200px;
		margin-left: none;
		margin-right: auto;
		overflow: hidden;
	}
	@media (max-width: 1100px) {
		width: 95%;
	}
	@media (max-width: 768px) {
		width: 90%;
		/* margin-left: 0px; */
		margin-left: none;
		margin-right: auto;
		margin-bottom: 50px;
		margin-top: 50px;
		max-width: ${w};
	}
`;

const EximContent = styled(motion.div)`
	display: flex;
	flex-direction: row;
	/* flex-wrap: nowrap; */

	margin-top: 50px;

	/* background-color: black; */
	/* overflow: scroll; */
`;

const EximTitle = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 700;
	font-size: 87px;
	line-height: 26px;
	margin-top: 50px;
	margin-bottom: 100px;
	@media (max-width: 768px) {
		font-size: 55px;
		line-height: 56px;
	}
`;

//! 68+ contries ==========================================================>

const ContriesBolck = styled(motion.div)`
	width: 70%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 200px;
	margin-top: 200px;
	/* background-color: black; */
	max-width: 1200px;
	overflow: hidden;
	@media (max-width: 1100px) {
		width: 95%;
	}
	@media (max-width: 768px) {
		width: 90%;
		/* margin-left: 0px; */
		margin-bottom: 50px;
		margin-top: 50px;
		max-width: ${w};
	}
`;

const ContriesFlags = styled(motion.div)`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	gap: 50px;
	margin-top: 20px;

	@media (max-width: 1366px) {
		gap: 20px;
	}
	@media (max-width: 768px) {
		grid-template-columns: 1fr 1fr 1fr;
		gap: 10px;
	}
`;

const Flags = styled(motion.img)`
	height: 85px;
	width: 135px;
	background-color: '#131313';
	border-radius: 10px;
	object-fit: cover;
	margin-bottom: 8px;
	margin-top: 8px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	@media (max-width: 1366px) {
		height: 65px;
		width: 105px;
	}
	@media (max-width: 768px) {
		height: 50px;
		width: 85px;
	}
`;

const ContiesTitle = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 800;
	font-size: 87px;
	display: flex;
	flex-wrap: wrap;

	flex-direction: row;
	@media (max-width: 768px) {
		font-size: 55px;
		line-height: 50px;
	}
`;

const ContiesNumber = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 800;
	font-size: 87px;
	margin-right: 20px;
	color: ${(prop) => prop.color};
	@media (max-width: 768px) {
		font-size: 55px;
	}
`;

//! Why Us Block ==========================================================>

const WhyUsBlock = styled(motion.div)`
	margin-bottom: 50px;

	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 30px;
	margin-top: 50px;
	/* background-color: black; */

	@media (max-width: 768px) {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		gap: 20px;
		/* justify-content: space-between; */
	}
`;

const WhyUs = styled(motion.div)`
	min-width: 30%;
	min-height: 444px;
	border-radius: 15px;
	border: 2px dashed #707070;
	padding: 15px;
	cursor: pointer;
	/* justify-content: center; */
	/* background-color: black; */
	@media (max-width: 1366px) {
		min-height: 304px;
	}
	@media (max-width: 1100px) {
		min-height: 254px;
	}
	@media (max-width: 768px) {
		min-width: 90%;
		margin-left: auto;
		margin-right: auto;
	}
`;

const WhyUsTitle = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 400;
	font-size: 28px;
	margin-top: 50px;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	@media (max-width: 1366px) {
		font-size: 21px;
	}
	@media (max-width: 1100px) {
		font-size: 18px;
		margin-top: 30px;
	}
`;

const WhyUsDesc = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 200;
	font-size: 14px;
	width: 90%;
	margin-top: 50px;
	margin-left: auto;
	margin-right: auto;
	@media (max-width: 1366px) {
		font-size: 11px;
	}
	@media (max-width: 1100px) {
		font-size: 10px;
		margin-top: 30px;
	}
`;

// ! Arrow right and left =>>>>>>>>>>>>>>>>>>>>>>

const Arrows = styled(motion.div)`
	display: none;
	@media (max-width: 768px) {
		display: block;
		z-index: 50;
		width: 100%;
		margin-top: 50px;
		margin-bottom: 10px;
		justify-content: space-between;
	}
`;

const Arrow = styled(motion.img)`
	height: 60px;
	width: 60px;
	margin: 10px;
	cursor: pointer;
`;

// ! Popup =>>>>>>>>>>>>>>>>>>>>>>

const PopupMainCont = styled(motion.div)`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 120vh;
	width: 100%;
	position: absolute;
	background-color: #000000d1;
	z-index: 100;
	top: 0;
	position: fixed;
	backdrop-filter: blur(4px);
`;

const WhyUsCont = (props: any) => {
	const variant = {
		hover: {
			'box-shadow':
				'rgba(50, 50, 93, 0.25) 0px 50px 20px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset',
			transition: {
				diration: 3,
			},
		},
	};

	return (
		<WhyUs
			variants={variant}
			initial={{ opacity: 0.9, x: 10, y: 10 }}
			animate={{
				opacity: 1,
				x: props.positive ? [1500, 0] : [-1500, 0],
			}}
			transition={{
				type: 'spring',
				stiffness: 500,
				damping: 30,
				duration: 0.3,
			}}
			whileHover='hover'
		>
			<WhyUsTitle>{props.data.title}</WhyUsTitle>
			<WhyUsDesc>{props.data.details}</WhyUsDesc>
		</WhyUs>
	);
};

//! =========================>>>>>>>> FUNCTION <<<<<<<<<==========================================================>

function Home(props: any) {
	const [curr, setCurr] = useState('US');
	const [currInd, setCurrInd] = useState('INR');
	const [leftDropDown, setLeftDropDown] = useState(false);
	const [rightDropDown, setRightDropDown] = useState(false);

	const [loader, setLoader] = useState(true);

	const [allData, setAllData] = useState([]);

	const history = useHistory();
	const [sideCurrency, SetSideCurrency] = useState();
	// const [ rightSideCurrency , SetRightSideCurrency] = useState([])
	const dispatch = useDispatch();
	const settingsData = useSelector(settings);
	const Token = useSelector(token);
	const Uid = useSelector(uid);
	const Color = useSelector(ThemeColor);
	const aboutData = useSelector(about);
	const contactUsLoader = useSelector(contactLoader);

	const [leftSideCurrencyType, SetleftSideCurrencyType] = useState('USD');
	const [rightSideCurrencyType, SetRightSideCurrencyType] = useState('INR');

	const [numberOfItems, setNumberOfItems] = useState(
		props.dimensions.width < 1370 ? (props.dimensions.width < 768 ? 1 : 1) : 2,
	);

	const [index, setIndex] = useState(0);

	const [indexForImg, setIndexForImg] = useState(0);

	const [positive, setPositive] = useState(false);

	const [popUp, setPopUp] = useState(false);

	const [headerImages, setHeaderImages] = useState([]);
	const [country, setCountry] = useState([]);
	const [liProfile, setLiProfile] = useState();
	const [headerData, setHeaderData] = useState('');
	const [productsPage, setProductsPage] = useState([]);

	const [contWidth, setContWidth] = useState(100);

	const [whyUs, setWhyUS] = useState([]);

	const [reviews, setReviews] = useState([]);

	const [trustedByImages, setTrustedByImages] = useState([]);

	const eximP = useRef([]);
	const container = useRef(null);

	useEffect(() => {
		// ScrollTrigger.refresh();
		// console.log("*****************************",)
		setTimeout(() => {
			if (indexForImg >= 3) {
				setIndexForImg(0);
			} else {
				setIndexForImg((curr) => curr + 1);
			}

			// console.log(indexForImg)
		}, 3000);
		return () => {
			clearInterval();
		};
	}, [indexForImg]);

	useEffect(() => {
		// ScrollTrigger.refresh();
		// ScrollTrigger.clearScrollMemory();
		document.title = settingsData.company_name
			? `${settingsData.company_name} / Home`
			: '';
		gsap.registerPlugin(ScrollTrigger);
		let sections = gsap.utils.toArray('.layer');

		gsap.to(sections, {
			xPercent: -100 * (sections.length - numberOfItems),
			ease: 'none',
			// overwrite: false,
			// immediateRender: false,
			scrollTrigger: {
				trigger: '.container',
				pin: true,
				// pinSpacing: true,

				start:
					props.dimensions.width < 768
						? '-50% -5%'
						: props.dimensions.width < 2250
						? '-10% 5%'
						: '-70% 5%',
				end: props.dimensions.width < 768 ? 'top -150%' : 'top -115%',
				// endTrigger:"bottom bottom",
				scrub: 1,

				anticipatePin: 1,
				// markers: true,
				// snap: 1 / (sections.length -1 ),
				// base vertical scrolling on how wide the container is so it feels more natural.
				// invalidateOnRefresh: true,
				// toggleActions: "play complete",
				// onEnterBack: function (self) {
				// 	console.log(self, 'SCROLL TRIGGER');
				// 	self.enable();
				// },
				// onLeaveBack: function (self) {
				// 	console.log(self, 'SCROLL TRIGGER');
				// 	self.disable();
				// 	// self.disable();
				// 	// self.animation.progress(1);
				// },
			},
		});

		//   ScrollTrigger.create({
		//     scroller: container.current,
		//     trigger: layer,
		//     pin: true,
		//     start: 'top top',
		//     pinSpacing: false,
		//     markers:true,
		//   });
		// });

		// gsap.to(".layer", {
		//   scrollTrigger: {
		//     scroller: container.current,
		//     trigger: ".layer",
		//     start: "center 55%",
		//     markers: true,
		//     toggleActions: "play complete",
		//     scrub: true
		//   },
		//   duration: 2,
		//   ease: "back"
		// });

		// console.log("=>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",eximP.current)
		return () => {
			ScrollTrigger.getAll().forEach((t) => t.kill());
		};
	}, []);

	useEffect(() => {
		var FormData = require('form-data');
		var data = new FormData();
		data.append('token', Token);
		data.append('user_id', Uid);

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_BASE_URL}api/home`,

			data: data,
		};

		axios(config)
			.then(function (response: any) {
				console.log('HOME:++++>>>>>', response.data);

				const { home, country, headerImage, li_profile } = response.data;

				if (response.data.home !== null) {
					setAllData(response.data.home);
					setHeaderImages(headerImage);
					setCountry(country);
					setLiProfile(li_profile);
					setHeaderData(home);
					setProductsPage(response.data.mainProducts);
					setTrustedByImages(response.data.trustedby);
					dispatch(getHomeProducts(response.data.mainProducts));

					const reviews = home.product_review;
					const whyUS = JSON.parse(home.whyus);
					setReviews(reviews);
					setWhyUS(whyUS);

					if (Number(headerData.is_display_trusted)) {
						const widthOfTrustCont =
							document.getElementById('trust').offsetWidth;
						setContWidth(widthOfTrustCont);
					}

					console.log(
						home,
						country,
						headerImage,
						li_profile,
						response.data,
						'products',
					);

					const data = JSON.stringify(response.data.mainProducts);
					window.localStorage.setItem('HomePageProducts', data);
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [Token, Uid]);

	useEffect(() => {
		axios
			.get(
				'https://freecurrencyapi.net/api/v2/latest?apikey=98690c90-2e86-11ec-a607-ff5df5bc7244&base_currency=USD',
			)
			.then((r) => {
				// console.log("currency" , r)
				SetSideCurrency(r.data.data.INR.toFixed(2));
			})

			.catch((e) => console.log(e));
	}, []);

	const onclick = () => {
		setPopUp(!popUp);
	};

	const spring = {
		type: 'spring',
		stiffness: 500,
		damping: 30,
	};

	const DemoArray = [1, 2, 3, 4, 5];

	const ImagesSrc = [
		{
			img: 'https://cdn.pixabay.com/photo/2016/11/21/13/20/port-1845350_960_720.jpg',
		},
		{
			img: 'https://cdn.pixabay.com/photo/2021/08/20/15/04/ship-6560671_960_720.jpg',
		},
		{
			img: 'https://cdn.pixabay.com/photo/2018/01/30/13/28/container-3118783_960_720.jpg',
		},
		{
			img: 'https://cdn.pixabay.com/photo/2020/07/12/09/31/ship-5396662_960_720.jpg',
		},
	];

	console.log('ABOUT HOME DATA', liProfile);

	return (
		<div
			id='view'
			style={{
				overflow: 'hidden',
			}}
		>
			{(headerImages.length === 0 || contactUsLoader) && <Loader />}
			{/* <Helmet>
        <title>Mangesh Export</title>
        <meta name="description" content="Welcome to Mangesh Export" />
      </Helmet> */}
			<AnimatePresence>
				{popUp && (
					<>
						<PopupMainCont
							whileTap={{ opacity: 0, scale: 1.5 }}
							transition={{
								duration: 0.3,
							}}
							exit={{ opacity: 0 }}
							onClick={onclick}
						></PopupMainCont>

						<Popups width={props.dimensions.width} onclick={onclick} />
					</>
				)}
			</AnimatePresence>

			<MainCont>
				<Currency>
					<DropDownCont onClick={() => setLeftDropDown(!leftDropDown)}>
						<DropDownTitle Color={Color}>{curr}</DropDownTitle>
						{/* <Image src={DropDownImg} alt="Drop Down Icon" /> */}
					</DropDownCont>

					<CurrencyRate>1 $</CurrencyRate>
					<Imagee src={exchange} alt='Exchange' />

					<DropDownCont onClick={() => setRightDropDown(!rightDropDown)}>
						<DropDownTitle Color={Color}>{currInd}</DropDownTitle>
						{/* <Image src={DropDownImg} alt="Drop Down Icon" /> */}
					</DropDownCont>

					<CurrencyRate>{sideCurrency} ₹</CurrencyRate>
				</Currency>
				{/* <DropD>
        {  rightDropDown &&
          <DropDownContainer>
            {
          currencyIn.map((d,i) => {
            // console.log(d.title)
             return(
               <DropDownItems
               onClick={() =>{
                setCurrInd(d.title)
                setRightDropDown(!rightDropDown)
               } }
                key={i}>{d.title}</DropDownItems>
             )
           })}
            </DropDownContainer>
        }
              {  leftDropDown &&
          <DropDownContainer>
            {
          sideCurrency.map((d,i) => {
            // // console.log(d.title)
             return(
               <DropDownItems
               onClick={() =>{
                setCurr(d)
                setLeftDropDown(!leftDropDown)
               } }
                key={i}>{d}</DropDownItems>
             )
           })}
            </DropDownContainer>
        }
        </DropD> */}
				<HeroSection>
					<HeaderTitle>
						{headerData !== '' && headerData.product_quote}
						{/* <h3
              style={{
                fontFamily: "Poppins",
                fontWeight: 600,
                fontSize: 18,
                color: "#8D8D8D",
              }}
            >
              12+ more products explore
            </h3> */}
						{props.dimensions.width > 768 && (
							<AllProducts
								Color={Color}
								whileTap={{ scale: 0.99 }}
								onClick={() => history.push('/products')}
							>
								View All Products
							</AllProducts>
						)}
					</HeaderTitle>
					<HeaderImagesCont>
						{headerImages !== [] &&
							headerImages.map((d, i) => {
								return (
									<HeaderImages
										key={i}
										animate={
											indexForImg === i
												? {
														scale: 1.5,
														filter: 'blur(0px)',
														x:
															props.dimensions.width < 700
																? indexForImg === 0 || indexForImg === 2
																	? '50px'
																	: '-50px'
																: indexForImg === 0 || indexForImg === 2
																? '150px'
																: '-150px',
														y:
															props.dimensions.width < 700
																? indexForImg === 0 || indexForImg === 1
																	? '80px'
																	: '-80px'
																: indexForImg === 0 || indexForImg === 1
																? '100px'
																: '-100px',
														zIndex: 20,
												  }
												: { opacity: 0.5, color: '#000', filter: 'blur(5px)' }
										}
										transition={spring}
										src={d.banner_image}
										alt='Mango'
									/>
								);
							})}
					</HeaderImagesCont>
					{props.dimensions.width < 768 && (
						<AllProducts
							Color={Color}
							whileTap={{ scale: 0.99 }}
							onClick={() => history.push('/products')}
						>
							View All Products
						</AllProducts>
					)}
				</HeroSection>
			</MainCont>
			{Number(headerData.is_display_trusted) ? (
				<>
					<TrustedBy>
						<div
							style={{
								height: '60%',
								// backgroundColor:"#131313",
								display: 'flex',
								flexDirection: 'column',
								// justifyContent:"space-between",
								alignContent: 'space-between',
							}}
						>
							<TrustedByTitle>WE ARE TRUSTED BY</TrustedByTitle>
							<TrustedByCont
								id='trust'
								animate={{
									// x:props.dimensions.width < 700 ? [1000,-1000] :[1500,-1500],
									// x:[props.dimensions.width/2 + contWidth , - props.dimensions.width - contWidth]
									x: [
										props.dimensions.width / 2 + 300,
										-props.dimensions.width / 2 - 300,
									],
								}}
								transition={{
									ease: 'linear',
									duration: props.dimensions.width < 700 ? 5 : 10,
									repeat: Infinity,
								}}
							>
								{trustedByImages.length > 0 &&
									trustedByImages.map((d, i) => {
										return <TrustedByImage key={i} src={d} alt='Trusted By' />;
									})}
							</TrustedByCont>
						</div>
					</TrustedBy>
				</>
			) : null}
			{Number(headerData.is_display_top_12_product) ? (
				<LazyLoad effect='blur' offsetVertical={1000}>
					<AllProduct data={productsPage} title='ALL PRODUCTS' />
				</LazyLoad>
			) : null}
			<LazyLoad offsetVertical={1000}>
				<Trade>
					<FlexibleTitle>FLEXIBILITY IN TRADE</FlexibleTitle>

					<TradeImage src={flexTradeText} alt='Flex trade image' />
					<TradeImageItem1
						animate={{ scale: [0.5, 1.5, 0.5] }}
						transition={{
							type: 'spring',
							stiffness: 100,
							duration: 1,
							repeat: Infinity,
						}}
						src={ImagesSrc[0].img}
					/>
					<TradeImageItem2
						animate={{ scale: [0.5, 1.5, 0.5] }}
						transition={{
							type: 'spring',
							delay: 0.3,
							stiffness: 100,
							duration: 1.4,
							repeat: Infinity,
						}}
						src={ImagesSrc[1].img}
					/>
					<TradeImageItem3
						animate={{ scale: [0.5, 1.5, 0.5] }}
						transition={{
							type: 'spring',
							stiffness: 100,
							delay: 0.4,
							duration: 1.3,
							repeat: Infinity,
						}}
						src={ImagesSrc[2].img}
					/>
					<TradeImageItem4
						animate={{ scale: [0.5, 1.5, 0.5] }}
						transition={{
							type: 'spring',
							stiffness: 100,
							delay: 0.5,
							duration: 1,
							repeat: Infinity,
						}}
						src={ImagesSrc[3].img}
					/>
					<TradeImageItem5
						animate={{ scale: [0.5, 1.3, 0.5] }}
						transition={{
							type: 'spring',
							stiffness: 100,
							delay: 0.7,
							duration: 2,
							repeat: Infinity,
						}}
						src={ImagesSrc[0].img}
					/>
					<TradeImageItem6
						animate={{ scale: [0.5, 1.5, 0.5] }}
						transition={{
							type: 'spring',
							stiffness: 100,
							delay: 0.8,
							duration: 1.5,
							repeat: Infinity,
						}}
						src={ImagesSrc[1].img}
					/>
				</Trade>
			</LazyLoad>
			{reviews.length >= 0 && (
				<>
					<TitleReview>Product Review</TitleReview>
					<ReviewsCont width={props.dimensions.width} data={reviews} />{' '}
				</>
			)}

			{liProfile !== undefined && (
				<EmpComp>
					<EmpCompCard>
						<PhotoCont
							name={liProfile.name}
							job={liProfile.job}
							position={liProfile.position}
							// desc={headerData !== "" && headerData.lk_description}
							experience={liProfile.experience}
							image={liProfile.image}
						/>
					</EmpCompCard>
					<EmpCompText>
						<EmpName>{liProfile.name}</EmpName>
						<EmpOccu>{liProfile.position}</EmpOccu>
						<EmpAbout>
							{headerData !== '' && headerData.lk_description}
						</EmpAbout>
						{/* <EmpAbout>{aboutData.linkedin_quote}</EmpAbout> */}
					</EmpCompText>
				</EmpComp>
			)}

			{/* <EximBlock>
        <EximTitle>EXIM PROCESS</EximTitle>
        <EximContent className="container" ref={container}>
          {DemoArray.map((d, i) => {
            return (
              <EximProcess
                className="layer"
                width={props.dimensions.width}
                ref={eximP}
                key={i}
              />
            );
          })}

          {/* <EximProcess/>
            <EximProcess/>
            <EximProcess/>
            <EximProcess/>
            <EximProcess/>  */}
			{/* </EximContent>
      </EximBlock>  */}

			<EximBlock>
				<EximTitle>EXIM PROCESS</EximTitle>
				<EximContent className='container' ref={container}>
					{/* {DemoArray.map((d, i) => {
            return ( */}
					<EximProcess
						className='layer'
						width={props.dimensions.width}
						ref={eximP}
						step={1}
						txt1='Your details, Our safety'
						txt2='Provide us with your company details '
					/>
					<EximProcess
						className='layer'
						width={props.dimensions.width}
						ref={eximP}
						step={2}
						txt1='Drop a message'
						txt2='Importer or buyer, we are here for all. Few clicks and we will help you with your inquiries.'
					/>
					<EximProcess
						className='layer'
						width={props.dimensions.width}
						ref={eximP}
						step={3}
						txt1='Quotation in 24 hours'
						txt2='Our quick service won’t make you wait long.
Please note* for logistic inquiries we will get back to you in the next 48 hours,'
					/>
					<EximProcess
						className='layer'
						width={props.dimensions.width}
						ref={eximP}
						step={4}
						txt1='Commercial terms'
						txt2='The backbone of export is the right commercial and payment terms. We give you 100% service with zero uncertainty'
					/>
					<EximProcess
						step={5}
						className='layer'
						width={props.dimensions.width}
						ref={eximP}
						txt1='Onboard'
						txt2='Once the product is set, your journey to the new market begins'
					/>
					<EximProcess
						step={6}
						className='layer'
						width={props.dimensions.width}
						ref={eximP}
						txt1='Quality check'
						txt2='To insure the quality of goods, quality check by an independent agency at loading port'
					/>

					{/* );
          })} */}

					{/* <EximProcess/>
            <EximProcess/>
            <EximProcess/>
            <EximProcess/>
            <EximProcess/>  */}
				</EximContent>
			</EximBlock>

			<ContriesBolck>
				<ContiesTitle>
					<ContiesNumber color={Color}>
						{headerData !== null
							? headerData.country_total + '+'
							: country.length + '+'}
					</ContiesNumber>
					Contries
				</ContiesTitle>
				<ContriesFlags>
					{country.map((d, i) => {
						return <Flags key={i} src={d.image} />;
					})}
				</ContriesFlags>
			</ContriesBolck>

			<ContriesBolck>
				<ContiesTitle>Why Us?</ContiesTitle>
				<WhyUsBlock>
					{whyUs.map((d, i) => {
						return props.dimensions.width < 768 ? (
							index === i && <WhyUsCont data={d} positive={positive} key={i} />
						) : (
							<WhyUsCont data={d} positive={positive} key={i} />
						);
					})}
				</WhyUsBlock>
				<Arrows>
					<Arrow
						whileTap={{ scale: 0.9 }}
						onClick={() => {
							// console.log("hello")
							setPositive(false);
							if (index != 0) {
								setIndex((curr) => curr - 1);
							} else {
								setIndex(DemoArray.length - 1);
							}
						}}
						src={index <= 0 ? leftG : left}
					/>
					<Arrow
						whileTap={{ scale: 0.9 }}
						onClick={() => {
							setPositive(true);
							// console.log(DemoArray.length)
							if (index < DemoArray.length - 1) {
								setIndex((curr) => curr + 1);
							} else {
								setIndex(0);
							}
						}}
						src={index > DemoArray.length - 2 ? rightG : right}
					/>
				</Arrows>
			</ContriesBolck>

			<AllProduct dimensions={props.dimensions} title='Exim Blogs' blog />

			<LazyLoad offsetVertical={500}>
				<Footer onclick={onclick} />
			</LazyLoad>
		</div>
	);
}

export default Home;

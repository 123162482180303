import React,{useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion';
import { ErrorMessage,Formik, useFormik } from 'formik';
import emailjs from 'emailjs-com';
import * as yup from 'yup';
import { PrimaryThemeColor ,PrimaryFont } from '../Constants';
import { useSelector,useDispatch } from "react-redux";

import { getContactUsFields, getFooterLoader, ThemeColor, token , uid } from '../../ReduxToolkit/useReducer';

import axios from "axios"
import Loader from '../Loader';
import Tostify from './Tostify';
import { toast } from 'react-toastify';
const MainCont = styled(motion.div)`
display: flex;
width: 40%;
flex-direction: column;
border-radius: 30px;
background-color: #fff;
height: 500px;
padding: 40px;
/* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
margin-top: 50px;
margin-bottom: 50px;

@media (max-width: 1366px) {
 width: 45%;
 height: 400px;

  }


@media (max-width: 768px) {
width: 90%;
padding-bottom: 50px;
height: 400px;

}
`;

const Form = styled(motion.form)`
display: flex;
flex-direction: column;
justify-content: space-between;
/* height: 500px; */
`;


 const InputBox = styled.input`
  padding: 16px 16px 0px 0px;
  text-align: center;
  border: none;
  outline: none;
  border-bottom: 1px solid #B2B2B2;
  /* background: rgba(0, 0, 0, 0.003); */
  font-family: ${PrimaryFont};
font-weight: "Medium";
font-size: 16px;
outline: none;
color: #D1D1D1;
margin-top: 20px;
width: 80%;
margin-left: auto;
margin-right: auto;

@media (max-width: 1366px) {
  margin-top: 5px;
  font-size: 18px;

  }


@media (max-width: 768px) {
  margin-top: 10px;
  font-size: 15px;
}

`;

const AddDetails = styled(motion.h1)`
  font-family: ${PrimaryFont};
font-weight: 300;
font-size: 18px;
text-align: right;
color: ${prop => prop.Color};
width: 80%;
margin-left: auto;
margin-right: auto;
margin-top: 20px;
cursor: pointer;
@media (max-width: 1366px) {
  margin-top: 10px;
  font-size: 20px;
  }


@media (max-width: 768px) {
  margin-top: 20px;
  font-size: 18px;

}
`;

const ContactusBtn = styled(motion.button)`
display: flex;
  font-family: ${PrimaryFont};
font-weight: 600;
font-size: 27px;
text-align: right;
background-color:${prop => prop.Color};
color: #fff;
width: 90%;
margin-left: auto;
margin-right: auto;
margin-top: 20px;
border-radius: 20px;
align-content: center;
justify-content: center;
align-items:center;
height: 85px;
outline: none;
border: none;
cursor: pointer;

@media (max-width: 1366px) {
  font-size: 21px;
 height: 70px;
  }


@media (max-width: 768px) {
  font-size: 22px;
height: 60px;

}
`;

const ErrorMsg = styled(motion.h1)`
 font-family: 'Montserrat', sans-serif,;
 text-align: center;
 font-size: 12px;
 font-weight: 400;
 margin-top: 2px;
 /* line-height: 8px; */
 color: red;
`;


const Extra = styled(motion.div)`
height: 15px;
`;





function ContactUs(props) {

  const Color = useSelector(ThemeColor)
  const dispatch = useDispatch();
  const Token = useSelector(token)
  const Uid = useSelector(uid)


  const validate = yup.object().shape({
    name : yup.string().required("Please enter your name"),
    email : yup.string().email("Email is invalid").required("Please enter your email"),
    phone : yup.number().typeError("Phone must be a number").required("Please enter your phone").positive().integer(),
  })



    const formik = useFormik({



        initialValues: {       
        name:"",
          email: '',
          phone:"",
   
        },

        validationSchema: validate,
        validateOnChange:false,
        validateOnBlur:false,
      
   
        onSubmit: (values,{setSubmitting, setErrors, setStatus, resetForm}) => {
          dispatch(getFooterLoader())
          // alert(JSON.stringify(values, null, 2));

          // emailjs.sendForm('service_u55dlne', 'template_gy63qqu',values, 'user_vczSbs7sebZUOZVFvBHaP')
          //   .then((result) => {
          //       // console.log(result.text);
          //   }, (error) => {
          //       // console.log(error.text);
          //   });

          const {name,email,phone} = values;

          var FormData = require('form-data');
          var data = new FormData();
          data.append('token', Token);
          data.append('user_id', Uid);
          data.append('name', name);
          data.append('email', email);
          data.append('phone_no', phone);


          var config = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}api/contacted`,

            data : data
          };

          axios(config)
          .then(function (response) {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              });
            dispatch(getFooterLoader())
            console.log(JSON.stringify(response.data));
            resetForm();
           
          })
          .catch(function (error) {
            console.log(error);
            toast.error(error.response && error.response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
             
              });
          });

    
            // emailjs.send('service_u55dlne', 'template_gy63qqu',values, 'user_vczSbs7sebZUOZVFvBHaP')
            // .then((result) => {
            //     // console.log(result.text)
            //     alert("Success");
            // }, (error) => {
            //     // console.log(error.text)
            //     alert("Falied");
            // })

        
          
   
        },
   
      });




    return (
       <MainCont>
          <Tostify />
     
        <Form onSubmit={formik.handleSubmit}
        
        >
            <InputBox

            id="name"

            name="name"

            type="text"

            placeholder="Your Name"


            onChange={formik.handleChange}

            value={formik.values.name}

            />
        
           {formik.errors.name ? <ErrorMsg>{formik.errors.name}</ErrorMsg> : <Extra />}
            <InputBox

            id="email"

            name="email"

            type="email"

            placeholder="Your Email Address"


            onChange={formik.handleChange}

            value={formik.values.email}

            />
  {formik.errors.email ? <ErrorMsg>{formik.errors.email}</ErrorMsg> : <Extra/>}

            <InputBox

            id="phone"

            name="phone"

            type="text"

            placeholder="Your Phone Number"


            onChange={formik.handleChange}

            value={formik.values.phone}

            />
  {formik.errors.phone ? <ErrorMsg>{formik.errors.phone}</ErrorMsg> : <Extra/>}
                <AddDetails
                Color={Color}
                 onClick={()  => {
                   props.onclick()
                   console.log(formik.values.phone)
                   dispatch(getContactUsFields({name:formik.values.name,email:formik.values.email,phone:formik.values.phone}))
                   }}>Add Details</AddDetails>

            <ContactusBtn Color={Color} type="submit" >Connect Us</ContactusBtn>

        </Form>
       </MainCont>
    )
}

export default ContactUs

import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import eximP from "../../Assets/Home/EximP.svg";
import { isConditionalExpression } from "typescript";
import { PrimaryThemeColor, PrimaryFont } from "../Constants";
import { useSelector } from "react-redux";

import { ThemeColor, token, uid } from "../../ReduxToolkit/useReducer";
import EximFile from "./EximFile";
const MainCont = styled(motion.div)`
  position: relative;
  width: 100%;
`;

const BackImg = styled(motion.div)`
  object-fit: contain;
  position: relative;

  @media (max-width: 1100px) {
  }
  @media (max-width: 768px) {
    height: 350px;
  }
`;

const Step = styled(motion.h1)`
  font-family: ${PrimaryFont};
  font-weight: 300;
  font-size: 21px;
  position: absolute;
  margin-left: 100px;
  bottom: 500px;
  @media (max-width: 768px) {
    bottom: 300px;
    margin-left: 60px;
    font-size: 16px;
  }
`;

const MiddleText = styled(motion.h1)`
  font-family: ${PrimaryFont};
  font-weight: 400;
  font-size: 21px;
  position: absolute;
  margin-left: 120px;
  bottom: 420px;
  width: 50%;
  text-align: center;
  @media (max-width: 768px) {
    bottom: 240px;
    font-size: 16px;
    margin-left: 50px;
    width: 70%;
  }
`;

const LastText = styled(motion.h1)`
  font-family: ${PrimaryFont};
  font-weight: 300;
  font-size: 14px;
  position: absolute;
  margin-left: 120px;
  bottom: 120px;
  width: 50%;
  text-align: center;
  @media (max-width: 768px) {
    bottom: 60px;
    margin-left: 50px;
    font-size: 8px;
    width: 60%;
  }
`;

function EximProcess(props: any) {
  const Color = useSelector(ThemeColor);

  useEffect(() => {
    // console.log(props)
  }, []);
  return (
    <MainCont className={props.className}>
      {/* <BackImg src={eximP} /> */}

      <div
        style={{
          height: props.width > 768 ? 600 : 350,
          width: props.width > 768 ? 500 : 300,
          objectFit: "contain",
          overflow: "hidden",
        }}
      >
        <EximFile color={Color} />
      </div>

      <Step>Step {props.step}</Step>
      <MiddleText>{props.txt1}</MiddleText>
      <LastText>{props.txt2}</LastText>
    </MainCont>
  );
}

export default EximProcess;

import {createGlobalStyle} from "styled-components";



const GlobalStyle = createGlobalStyle`
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
/* cursor: none; */

}
`

// const fonts ={
//     font-family: 'Montserrat', sans-serif,;

//     font-family: 'Open Sans', sans-serif;

//     font-family: 'Poppins', sans-serif;
// }



export default GlobalStyle
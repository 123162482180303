import React from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux';

import { settings, ThemeColor } from '../ReduxToolkit/useReducer';








export const PrimaryThemeColor = "#0058FF";
// export const PrimaryThemeColor = Color;



export const PrimaryFont = "Poppins, sans-serif";



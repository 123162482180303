import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import right from "../../Assets/Home/right.svg";
import left from "../../Assets/Home/left.svg";
import Reviewimg from "../../Assets/Home/rating.svg";
import star from "../../Assets/Home/star.png";
import starDis from "../../Assets/Home/starDis.png";
import { PrimaryThemeColor, PrimaryFont } from "../Constants";

import { useSelector } from "react-redux";

import { ThemeColor, token, uid } from "../../ReduxToolkit/useReducer";

const MainCont = styled(motion.div)`
  width: 70%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row;
  /* height:470px; */
  margin-top: 30px;
  margin-bottom: 200px;
  overflow: hidden;
  max-width: 1200px;
  @media (max-width: 1100px) {
    width: 95%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
    height: 100%;
    max-width: 450px;
    margin-bottom: 50px;
  }
`;

const CompanyCont = styled(motion.div)`
  width: 30%;
  display: flex;
  flex-direction: column;
  background-color: #000;
  justify-content: center;
  align-items: center;
  /* height:100%; */
  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
  }
`;

const CompanyTitle = styled(motion.h1)`
  font-family: ${PrimaryFont};
  font-weight: 600;
  font-size: 28px;
  color: #fff;
  text-align: center;
  @media (max-width: 1500px) {
    font-size: 24px;
  }
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;
const CompanySubTitle = styled(motion.h1)`
  font-family: ${PrimaryFont};
  font-weight: 100;
  font-size: 15px;
  color: #fff;
  /* line-height: 0px; */
  letter-spacing: 7px;
`;

const Arrows = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 50px;
  @media (max-width: 768px) {
    /* position: absolute;  */
    /* margin-top: 1280px;  */
    /* margin-top: 900px; */
    z-index: 50;
    width: 70%;
    /* max-width: 250px; */
    justify-content: space-between;
  }
`;

const ReviewCont = styled(motion.div)`
  width: 70%;
  background-color: #191919;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  height: 100%;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 100%;
    /* height: 400px; */
    justify-content: flex-start;
  }
  @media (max-width: 360px) {
    height: 400px;
    padding: 20px;
  }
`;

const Arrow = styled(motion.img)`
  height: 60px;
  width: 60px;
  cursor: pointer;
`;

const ProfilePhoto = styled(motion.img)`
  height: 60px;
  width: 60px;
  border-radius: 100px;
  object-fit: cover;
`;

const ProfileCont = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProfileData = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

const ContainerReview = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

const UserName = styled(motion.h1)`
  font-family: ${PrimaryFont};
  font-weight: 600;
  font-size: 16px;
  color: #fff;
`;

const Subtitle = styled(motion.h1)`
  font-family: ${PrimaryFont};
  font-weight: 400;
  font-size: 11px;
  color: #9d9d9d;
`;

const ReviewsText = styled(motion.h1)`
  font-family: ${PrimaryFont};
  font-weight: 400;
  font-size: 21px;
  color: #9d9d9d;
  width: 90%;
  margin-top: 5%;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 12px;
  }
`;

const ReviewImg = styled(motion.img)`
  height: 30px;
  /* width: 212px; */
  margin-left: 6px;
  margin-top: 10px;
`;

function ReviewsCont(prop) {
  const [index, setIndex] = useState(0);
  const [positive, setPositive] = useState(false);

  useEffect(() => {
    console.log("LEANGTH =>>>>>>>", prop.data.length);
  }, [prop]);

  const reviews = [
    {
      name: "June",
      desc: "CEO",
      review:
        "Consequat qui cillum irure elit laboris nisi non ullamco sit non voluptate eu. Consequat veniam quis fugiat mollit ut nisi Lorem elit ad. Fugiat eiusmod sunt excepteur duis fugiat nostrud irure incididunt esse nulla.",
      path: "https://images.unsplash.com/photo-1594819047050-99defca82545?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=694&q=80",
    },
    {
      name: "jully",
      desc: "Manager",
      review:
        "Consequat qui cillum irure elit laboris nisi non ullamco sit non voluptate eu. Consequat veniam quis fugiat mollit ut nisi Lorem elit ad. Fugiat eiusmod sunt excepteur duis fugiat nostrud irure incididunt esse nulla.",
      path: "https://images.unsplash.com/photo-1571512599285-9ac4fdf3dba9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    },
    {
      name: "August",
      desc: "HR",
      review:
        "Consequat qui cillum irure elit laboris nisi non ullamco sit non voluptate eu. Consequat veniam quis fugiat mollit ut nisi Lorem elit ad. Fugiat eiusmod sunt excepteur duis fugiat nostrud irure incididunt esse nulla.",
      path: "https://images.unsplash.com/photo-1601288496920-b6154fe3626a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80",
    },
  ];

  return (
    <MainCont>
      {prop.data.map((d: any, i: any) => {
        return (
          index === i && (
            <CompanyCont key={i}>
              <CompanyTitle>{d.company_name}</CompanyTitle>
              <CompanySubTitle>IMPORTER</CompanySubTitle>
              {prop.width > 768 && prop.data.length > 1 && (
                <Arrows>
                  <Arrow
                    whileTap={{ scale: 0.9 }}
                    onClick={() => {
                      console.log(index);
                      setPositive(false);
                      if (index != 0) {
                        setIndex((curr) => curr - 1);
                      } else {
                        setIndex(prop.data.length - 1);
                      }
                    }}
                    src={left}
                  />
                  <Arrow
                    whileTap={{ scale: 0.9 }}
                    onClick={() => {
                      setPositive(true);

                      console.log(index);
                      if (index < prop.data.length - 1) {
                        setIndex((curr) => curr + 1);
                      } else {
                        setIndex(0);
                      }
                    }}
                    src={right}
                  />
                </Arrows>
              )}
            </CompanyCont>
          )
        );
      })}

      <ReviewCont>
        {prop.data.map((d: any, i: any) => {
          return (
            index === i && (
              <ContainerReview
                key={i}
                initial={{ opacity: 0, x: 10, y: 10 }}
                animate={{
                  opacity: 1,
                  x: positive ? [1500, 0] : [-1500, 0],
                }}
                transition={{
                  type: "spring",
                  stiffness: 500,
                  damping: 30,
                  duration: 0.3,
                }}
              >
                <ProfileCont key={i}>
                  <ProfilePhoto src={d.image} />
                  <ProfileData>
                    <UserName>{d.person_name}</UserName>
                    <Subtitle>{d.person_position}</Subtitle>
                  </ProfileData>
                </ProfileCont>
                {/* <ReviewImg src={Reviewimg} /> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  {[...Array(Math.ceil(Number(d.star_rating)))].map((d, i) => {
                    console.log(i, "RATE");
                    return <ReviewImg key={i} src={star} />;
                  })}

                  {[...Array(Math.floor(5 - Number(d.star_rating)))].map(
                    (d, i) => {
                      console.log(i, "RATE");
                      return <ReviewImg key={i} src={starDis} />;
                    }
                  )}

                  <ReviewsText
                    style={{
                      marginTop: 12,
                      marginLeft: 15,
                    }}
                  >
                    {d.star_rating}
                  </ReviewsText>
                </div>

                <ReviewsText>{d.review}</ReviewsText>
              </ContainerReview>
            )
          );
        })}
        {prop.width < 768 && prop.data.length > 1 && (
          <Arrows>
            <Arrow
              whileTap={{ scale: 0.9 }}
              onClick={() => {
                console.log(index);
                setPositive(false);
                if (index != 0) {
                  setIndex((curr) => curr - 1);
                } else {
                  setIndex(prop.data.length - 1);
                }
              }}
              src={left}
            />
            <Arrow
              whileTap={{ scale: 0.9 }}
              onClick={() => {
                setPositive(true);

                console.log(index);
                if (index < prop.data.length - 1) {
                  setIndex((curr) => curr + 1);
                } else {
                  setIndex(0);
                }
              }}
              src={right}
            />
          </Arrows>
        )}
      </ReviewCont>
    </MainCont>
  );
}

export default ReviewsCont;

import React, { useEffect, useState, useRef } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	NavLink,
} from 'react-router-dom';

import styled from 'styled-components';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';

import About from '../Components/About';
import Blogs from '../Components/Blogs';
import Home from '../Components/Home';
import Products from '../Components/Products';

import openMenu from '../Assets/open.svg';
import closeMenu from '../Assets/close.svg';

import { ReactComponent as Down } from '../Assets/down.svg';

import SocialIcons from '../Components/Cards/SocialIcons';
import Product from '../Components/Product';
import Blog from '../Components/Blog';
import { PrimaryThemeColor, PrimaryFont } from '../Components/Constants';
import CbmCalc from '../Components/External/CbmCalc';
import Invoice from '../Components/External/Invoice';
import { Provider, useDispatch } from 'react-redux';
import Store from '../ReduxToolkit/Store';

import {
	getAboutData,
	getLogo,
	getProducts,
	getSettings,
	getThemeColor,
	getToken,
	getUid,
} from '../ReduxToolkit/useReducer';
import axios from 'axios';

import { useSelector } from 'react-redux';

import { ThemeColor, token, uid, mainLogo } from '../ReduxToolkit/useReducer';
import { type } from 'os';
import ComingSoon from '../Components/ComingSoon';
import Loader from '../Components/Loader';
import Notfound from '../Components/Notfound';

import deactivated from '../Assets/deactivated.json';
import notFound from '../Assets/404.json';
import settings from '../Assets/settings.json';

// =========================== Styles =======================================================

const MainCont = styled(motion.div)`
	width: 100%;

	@media (min-width: 768px) {
		margin-left: auto;
		margin-right: auto;
		/* width:70%; */
	}
`;

const ToolsData = styled(motion.div)`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	/* height: 50px; */
	overflow: hidden;
	font-family: ${PrimaryFont};
	font-weight: 600;
	color: #a2a2a2;
	font-size: 20px;
	cursor: pointer;
`;

const NavBar = styled(motion.nav)`
	/* display: flex; */

	overflow: hidden;
	/* background-color: black; */
	height: 100px;

	@media (max-width: 768px) {
		/* position: absolute; */
		position: fixed;

		z-index: 150;
		overflow: hidden;
	}
`;

const AllLinks = styled(motion.ul)`
	/* background-color: #000; */
	height: 100px;
	flex-direction: row;
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	width: 100%;
	list-style: none;
	text-decoration: none;
	text-transform: none;
	margin-left: auto;
	margin-right: auto;

	max-width: 1200px;
	@media (max-width: 1100px) {
		width: 95%;
	}

	@media (max-width: 768px) {
		/* position: absolute; */
		position: fixed;
		flex-direction: column;
		height: 100%;
		justify-content: space-around;
		background-color: #000;
		top: 0;
		bottom: 0;
		width: 100%;
		z-index: 15;
		overflow: hidden;
	}
`;
const MenuBarTabs = styled(motion.div)`
	height: 50px;
	flex-direction: row;
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	width: 40%;
	list-style: none;
	text-decoration: none;
	text-transform: none;
	margin-left: auto;
	/* margin-right: auto; */
	/* background-color:#131313; */

	@media (max-width: 1100px) {
		width: 50%;
	}

	@media (max-width: 768px) {
		flex-direction: column;
		justify-content: space-around;
		background-color: #000;
		top: 0;
		bottom: 0;
		width: 100%;
		z-index: 15;
		height: 40%;
	}
`;

const List = styled(motion.li)`
	list-style: none;
	text-decoration: none;
	text-transform: none;
	font-size: 16px;
	@media (min-width: 768px) {
		margin-top: 20px;
	}
`;

const Links = styled(NavLink)`
	/* width: 100%; */
	list-style: none;
	text-decoration: none;
	text-transform: none;
	font-family: ${PrimaryFont};
	font-weight: 600;
	color: #a2a2a2;
	font-size: 16px;
	@media (min-width: 768px) {
		margin-top: 10px;
	}
`;

const Logo = styled(motion.h1)`
	/* width: 100%; */
	list-style: none;
	text-decoration: none;
	text-transform: none;
	font-family: ${PrimaryFont};
	font-weight: 'Bold';
	color: #00fed7;
	/* font-size: 27px; */
`;

const DownArrow = styled(motion.img)`
	height: 10px;
	width: 20px;
`;

// =========================================================================================

function Routes() {
	const [clicked, setClicked] = useState(false);
	const [toggleDropdown, setToggleDropdown] = useState(false);
	const [toggleColor, setToggleColor] = useState(false);
	const [landingPage, setLandingPage] = useState(false);
	const [comingSoon, setComingSoon] = useState(null);
	const [domainMsg, setDomainMsg] = useState('');
	const [logo, setLogo] = useState('');
	const dispatch = useDispatch();
	const Color = useSelector(ThemeColor);
	const logoOfBusiness = useSelector(mainLogo);
	const isComingSoon = window.localStorage.getItem('comingSoon');

	const [animateFile, setAnimateFile] = useState();
	// const isComingSoon = "false";
	// API Block

	// 	let iconUrl = isDesktop() ? '/desktop.png' : '/mobile.png';
	// let manifest = {
	//   name: "App name",
	//   icons: [{ src: iconUrl, sizes: "512x512", type:"image/png" }]
	// };
	// let content = encodeURIComponent(JSON.stringify(manifest));
	// let url = "data:application/manifest+json,"+content;
	// let element = document.createElement('link');
	// element.setAttribute('rel', 'manifest');
	// element.setAttribute('href', url);
	// document.querySelector('head').appendChild(element);

	useEffect(() => {
		console.log('COMING SOON,', isComingSoon);
		console.log('HostName => ', document.location);

		var data = new FormData();
		// data.append("domain", document.location.origin + ".com");
		data.append('domain', document.location.origin);
		// data.append('domain', 'https://sun.com');
		// data.append('domain', 'https://fexporters.wc-online.com');
		// data.append('domain', 'https://vdhanyaglobal.com');

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_BASE_URL}api/domain-verification`,
			data: data,
		};

		axios(config)
			.then(function (response) {
				console.log('DATATATATATATAATAT =>', JSON.stringify(response.data));

				if (response.data.data.status === '0') {
					setAnimateFile(deactivated);
					setDomainMsg(
						'Enjoying our service? It’s time to renew subscription with us',
					);
				}
				const token = JSON.stringify(response.data.data.token);
				const uid = JSON.stringify(response.data.data.user_id);
				dispatch(getToken(response.data.data.token));
				dispatch(getUid(response.data.data.user_id));

				console.log(response.data.data.status, 'DDDDDDDDDDDDDD');

				try {
					window.localStorage.setItem('uid', response.data.data.user_id);
					window.localStorage.setItem('token', response.data.data.token);
				} catch {
					console.log('Token and uid local storage failed');
				}

				if (response.data.data.is_show_coming_soon === '0') {
					setComingSoon(false);
					window.localStorage.setItem('comingSoon', 'false');
				} else if (response.data.data.is_show_coming_soon === null) {
					setComingSoon(null);
					window.localStorage.setItem('comingSoon', 'null');

					setAnimateFile(settings);
					setDomainMsg(
						'Please add the the data of the products you wish to mention on the website',
					);
					setLandingPage(true);
				} else {
					setComingSoon(true);
					window.localStorage.setItem('comingSoon', 'true');
				}
				// Api Nested

				var data = new FormData();
				data.append('user_id', response.data.data.user_id);
				data.append('token', response.data.data.token);

				var configg = {
					method: 'post',
					url: `${process.env.REACT_APP_BASE_URL}api/user-setting`,

					data: data,
				};

				axios(configg)
					.then(function (response) {
						console.log('SETTINGS API =>>>', response.data.data);
						const settingsData = JSON.stringify(response.data);
						const themeCOLOR = JSON.stringify(response.data.data.color);

						let iconUrl = response.data.data.logo;
						document.getElementById('icon').href = iconUrl;
						document.title = response.data.data.company_name;
						let manifest = {
							name: response.data.data.company_name,
							short_name: 'Import & Export',
							icons: [{ src: iconUrl, sizes: '512x512', type: 'image/*' }],
							description: response.data.data.short_description,
							start_url: document.location.origin,
							theme_color: response.data.data.color,
							background_color: '#fff',
							display: 'standalone',
							orientation: 'any',
						};

						let content = encodeURIComponent(JSON.stringify(manifest));
						let url = 'data:application/manifest+json,' + content;
						let element = document.createElement('link');
						let icon = document.createElement('link');

						let ogTitle = document.createElement('meta');
						ogTitle.setAttribute('property', 'og:title');
						ogTitle.setAttribute('content', response.data.data.company_name);

						let ogDescri = document.createElement('meta');
						ogDescri.setAttribute('property', 'og:description');
						ogDescri.setAttribute(
							'content',
							response.data.data.short_description,
						);

						let ogType = document.createElement('meta');
						ogType.setAttribute('property', 'og:type');
						ogType.setAttribute('content', 'website');

						let ogUrl = document.createElement('meta');
						ogUrl.setAttribute('property', 'og:url');
						ogUrl.setAttribute('content', document.location.origin);

						let ogImage = document.createElement('meta');
						ogImage.setAttribute('property', 'og:image');
						ogImage.setAttribute('content', iconUrl);

						icon.setAttribute('rel', 'icon');
						icon.setAttribute('href', iconUrl);
						let description = document.createElement('meta');
						description.setAttribute('name', 'description');
						description.setAttribute(
							'content',
							response.data.data.short_description,
						);

						element.setAttribute('rel', 'manifest');
						element.setAttribute('href', url);
						document.querySelector('head').prepend(element);
						document.querySelector('head').prepend(description);
						document.querySelector('head').prepend(icon);
						document.querySelector('head').prepend(ogTitle);
						document.querySelector('head').prepend(ogDescri);
						document.querySelector('head').prepend(ogImage);
						document.querySelector('head').prepend(ogType);
						document.querySelector('head').prepend(ogUrl);

						setLogo(response.data.data.logo);
						dispatch(getSettings(response.data.data));
						dispatch(getThemeColor(response.data.data.color));
						dispatch(getLogo(response.data.data.logo));

						try {
							window.localStorage.setItem('settingsData', settingsData);
							window.localStorage.setItem('theme', response.data.data.color);
							window.localStorage.setItem('LOGO', response.data.data.logo);
						} catch (e) {
							console.log('Local Storage settings Error', e);
						}
					})
					.catch(function (error) {
						console.log('Error=>>>>>', error);
					});

				// Second Api Products

				// var pData = new FormData();
				// pData.append('user_id', response.data.data.user_id );
				// pData.append('token', response.data.data.token);
				// pData.append('limit', '12');

				//   var configProducts = {
				//     method: 'post',
				//     url: 'https://iportadmin.wc-online.com/api/product-list`,

				//     data : data
				//   };

				// axios(configProducts)
				// .then(function (response) {
				//   console.log("PRODUCT LIST",JSON.stringify(response.data.product));
				//   const productData = JSON.stringify(response.data.product)
				//   dispatch(getProducts(response.data.product))
				//   try{
				//     window.localStorage.setItem('products', productData);

				//   }catch(e){
				//     console.log("Local Storage settings Error",e)
				//   }
				// })
				// .catch(function (error) {
				//   console.log(error);

				// });
			})
			.catch(function (error) {
				console.log('E =>', error);
				setAnimateFile(notFound);
				setDomainMsg(
					"Looks like you don't have an account with India Port. Join us today to create an amazing website.",
				);
				setLandingPage(true);
			});
	}, []);

	// API Block

	function getWindowDimensions() {
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height,
		};
	}

	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions(),
	);

	const containerRef = useRef(null);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	// console.log(windowDimensions.width)

	const controls = useAnimation();

	useEffect(() => {
		controls.start((i) => ({
			opacity: [0, 0.1, 0.3, 0.8, 1],
			y: [-50, 10, 0],
			transition: { delay: i * 0.5 },
		}));
	}, [controls]);

	const sidebar = {
		open: (height = 1000) => ({
			clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
			transition: {
				type: 'spring',
				stiffness: 20,
				restDelta: 2,
			},
		}),
		closed: {
			opacity: 0,
			transition: {
				duration: 1,
				delay: 0.5,
				type: 'spring',
				stiffness: 400,
				damping: 40,
			},
		},
	};

	// if(comingSoon == null) {
	//   return <Loader />
	// }

	if (domainMsg !== '') {
		return (
			<Notfound
				file={animateFile}
				msg={domainMsg}
				dimensions={windowDimensions}
			/>
		);
	}

	const Navbar = (props: any) => {
		const containerRef = useRef(null);

		const height = windowDimensions.height;

		return (
			<NavBar
				variants={sidebar}
				custom={height}
				ref={containerRef}
				animate={props.mobile && (clicked ? 'open' : 'closed')}
				exit='closed'
				ref={containerRef}
			>
				<AllLinks>
					<List
						custom={0}
						animate={controls}
						onClick={() => {
							setToggleDropdown(false);
							setClicked(!clicked);
						}}
					>
						<Links exact to='/'>
							<img style={{ height: 60 }} src={logoOfBusiness} />
							{/* <Logo>
                       MANGE
                     </Logo>
                     <Logo
                     style={{
                      fontSize:11,
                      color: "#3E3E3E",
                      marginTop:-10,
                     }}
                     >
                       ORGANIC FARM
                     </Logo> */}
						</Links>
					</List>
					<MenuBarTabs>
						<List
							custom={0}
							animate={controls}
							onClick={() => {
								setToggleDropdown(false);
								setClicked(!clicked);
							}}
						>
							<Links
								activeStyle={{
									color: Color,
								}}
								exact
								to='/'
							>
								Home
							</Links>
						</List>
						<List
							custom={0}
							animate={controls}
							onClick={() => {
								setToggleDropdown(false);
								setClicked(!clicked);
							}}
						>
							<Links
								activeStyle={{
									color: Color,
								}}
								to='/about'
							>
								About
							</Links>
						</List>
						<List
							custom={0}
							animate={controls}
							onClick={() => {
								setToggleDropdown(false);
								setClicked(!clicked);
							}}
						>
							<Links
								activeStyle={{
									color: Color,
								}}
								to='/products'
							>
								Products
							</Links>
						</List>
						<List
							custom={0}
							animate={controls}
							onClick={() => {
								setToggleDropdown(false);
								setClicked(!clicked);
							}}
						>
							<Links
								activeStyle={{
									color: Color,
								}}
								to='/blogs'
							>
								Blogs
							</Links>
						</List>
						{windowDimensions.width < 768 ? (
							<>
								<List
									custom={0}
									animate={controls}
									onClick={() => setClicked(!clicked)}
								>
									<Links
										activeStyle={{
											color: Color,
										}}
										to='/cbm'
									>
										CBM
									</Links>
								</List>
								<List
									custom={0}
									animate={controls}
									onClick={() => setClicked(!clicked)}
								>
									<Links
										activeStyle={{
											color: Color,
										}}
										to='/invoice'
									>
										Invoice
									</Links>
								</List>
							</>
						) : (
							<ToolsData
								// style={{ position: "relative", height: 350 }}
								onHoverStart={() => setToggleDropdown(true)}
								onHoverEnd={() => setToggleDropdown(false)}
							>
								<List
									custom={0}
									animate={controls}
									// onClick={() => setToggleDropdown(!toggleDropdown)}

									style={{
										borderRadius: 15,
										padding: 10,
									}}
									whileTap={{ scale: 0.9 }}
									whileHover={{ color: Color }}
								>
									Tools <Down fill='none' stroke={Color} />
								</List>

								<AnimatePresence>
									{toggleDropdown && (
										<ToolsData
											style={{
												position: 'absolute',
												top: 60,
												// height: 100,
												zIndex: 100,
											}}
											initial={{ opacity: 0, y: -100 }}
											animate={{ opacity: 1, y: 10, type: 'string' }}

											//  onHoverEnd={() => setToggleDropdown(false)}
										>
											<List
												custom={0}
												animate={controls}
												onClick={() => setToggleDropdown(!toggleDropdown)}
												whileHover={{ color: Color }}
											>
												<Links
													activeStyle={{
														color: Color,
													}}
													to='/cbm'
												>
													CBM
												</Links>
											</List>
											<List
												custom={0}
												animate={controls}
												onClick={() => setToggleDropdown(!toggleDropdown)}
											>
												<Links
													activeStyle={{
														color: Color,
													}}
													to='/invoice'
												>
													Invoice
												</Links>
											</List>
										</ToolsData>
									)}
								</AnimatePresence>
							</ToolsData>
						)}
					</MenuBarTabs>
					{
						windowDimensions.width < 768 && <SocialIcons isNav={true} />
						// <List custom={0} animate={controls} >
						//   {/* <Links
						//  style={{fontSize:16 , color: `${PrimaryThemeColor}` ,textDecoration:"underline"}}
						//     to="/invoice">Invoice</Links> */}
						// </List>
						// :
						// <SocialIcons isNav={true}/>
					}
				</AllLinks>
			</NavBar>
		);
	};

	if (isComingSoon === 'true') {
		return <ComingSoon dimensions={windowDimensions} />;
	}

	return (
		<Router>
			<MainCont>
				{windowDimensions.width > 768 ? (
					<>
						<Navbar />
						<Links to='/products/product' />
					</>
				) : (
					<>
						{!clicked && (
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-around',
									width: '100%',
									alignContent: 'center',
									alignItems: 'center',
									marginTop: 20,
									// backgroundColor:"#000"
								}}
							>
								<motion.img
									custom={0}
									animate={controls}
									style={{
										height: '100%',
										width: 40,
										top: '50%',
										cursor: 'pointer',
									}}
									src={openMenu}
									alt='open Tab bar'
									onClick={() => setClicked(!clicked)}
								/>

								<List
									custom={0}
									animate={controls}
									onClick={() => setClicked(!clicked)}
									style={{
										justifyContent: 'center',
										alignContent: 'center',
										alignItems: 'center',
										// backgroundColor:"#000"
									}}
								>
									<Links exact to='/'>
										<img style={{ height: 60 }} src={logoOfBusiness} />
										{/* <Logo>
                       MANGE
                     </Logo>
                     <Logo
                     style={{
                      fontSize:15,
                      color: "#3E3E3E",
                      marginTop:-10,
                      textAlign:"center",
                      
                     }}
                     >
                       ORGANIC FARM
                     </Logo> */}
									</Links>
								</List>
								{/* <List custom={0} animate={controls} > */}
								{/* <Links
                 style={{fontSize:16 , color: `${PrimaryThemeColor}` ,textDecoration:"underline"}}
                    to="/CBM">CBM</Links> */}
								{/* </List> */}
							</div>
						)}
						<AnimatePresence>
							{clicked && (
								<>
									<motion.img
										initial={{ opacity: 0 }}
										animate={{
											rotate: 720,
											opacity: 1,
											transition: { ease: 'easeOut', duration: 1 },
										}}
										exit={{
											rotate: -180,
											opacity: 0,
											transition: { ease: 'easeOut', duration: 1 },
										}}
										style={{
											position: 'fixed',
											width: 30,
											zIndex: 350,
											top: 40,
											left: 30,

											cursor: 'pointer',
										}}
										src={closeMenu}
										alt='open Tab bar'
										onClick={() => setClicked(!clicked)}
									/>

									<Navbar mobile={true} />
								</>
							)}
						</AnimatePresence>
					</>
				)}

				{/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}

				<Switch>
					<Route path='/about'>
						<About dimensions={windowDimensions} />
					</Route>
					<Route path='/products/:product'>
						<Product dimensions={windowDimensions} />
					</Route>
					<Route path='/products'>
						<Products dimensions={windowDimensions} />
					</Route>
					<Route path='/blogs/blog'>
						<Blog />
					</Route>
					<Route path='/blogs'>
						<Blogs dimensions={windowDimensions} />
					</Route>
					<Route path='/cbm'>
						<CbmCalc dimensions={windowDimensions} />
					</Route>
					<Route path='/invoice'>
						<Invoice dimensions={windowDimensions} />
					</Route>

					<Route path='/'>
						<Home dimensions={windowDimensions} />
					</Route>
				</Switch>
			</MainCont>
		</Router>
	);
}

export default Routes;

import { createSlice } from '@reduxjs/toolkit';

var AboutDataFromLocalstorage = JSON.parse(window.localStorage.getItem("aboutData"))
// var loGoFromLocalStorage = JSON.parse(window.localStorage.getItem("LOGO"),)
// var ColorFromLocalStorage = JSON.parse(window.localStorage.getItem("theme"),)
var SettingsFromLocalStorage = JSON.parse(window.localStorage.getItem("settingsData"))
let productsFromLocalStorage = JSON.parse(window.localStorage.getItem("products"))
let homePageProducts = JSON.parse(window.localStorage.getItem("HomePageProducts"))
// var TokenFromLocalStorage = JSON.parse(window.localStorage.getItem("token"))

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    tokenData: window.localStorage.getItem("token"),
    themeColor:window.localStorage.getItem("theme") || "",
    uidData: window.localStorage.getItem("uid"),
    settingsData:SettingsFromLocalStorage || {},
    aboutData: AboutDataFromLocalstorage || [],
    logo:window.localStorage.getItem("LOGO") || "",
    productsData: productsFromLocalStorage || [],
    homeProductsData :homePageProducts ||  [],
    contactUsField : {},
    footerLoader:false,
   
  },
  reducers: {
    getToken: (state:any,data ) => {
      
      state.tokenData = data.payload;
     
    },
    getUid: (state:any,data:any ) => {
        state.uidData = data.payload;
      },
      getSettings: (state:any,data:any ) => {
        // console.log("Reducer => ",data.payload)
        state.settingsData = data.payload;
      },
      getThemeColor: (state:any,data:any ) => {
        // console.log("Reducer Theme=> ",data.payload)
        state.themeColor = data.payload;
      },
      getAboutData: (state:any,data:any ) => {
        // console.log("about Data=> ",data)
        state.aboutData = data.payload;
      },
      getLogo: (state:any,data:any ) => {
        // console.log("LOGOOO=> ",data)
        state.logo = data.payload;
      },
      getProducts: (state:any,data:any ) => {
        // console.log("products datatatatatatat => ",data)
        state.productsData = data.payload;
      },
      getHomeProducts: (state:any,data:any ) => {
        // console.log("products datatatatatatat => ",data)
        state.homeProductsData = data.payload;
      },
      getContactUsFields: (state:any,data:any ) => {
        // console.log("products datatatatatatat => ",data)
        state.contactUsField = data.payload;
      },
      getFooterLoader: (state:any,data:any ) => {
        // console.log("products datatatatatatat => ",data)
        state.footerLoader = !state.footerLoader;
      },
      


      

  },
}

);

export const { getToken , getUid ,getSettings,
  getThemeColor,getAboutData,getLogo,getProducts,getHomeProducts,getContactUsFields
  ,getFooterLoader
} = userSlice.actions;

export const token = (state:any) => state.user.tokenData;
export const uid = (state:any) => state.user.uidData;
export const settings = (state:any) => state.user.settingsData;
export const ThemeColor = (state:any) => state.user.themeColor;
export const about = (state:any) => state.user.aboutData;
export const mainLogo = (state:any) => state.user.logo;
export const products = (state:any) => state.user.productsData;
export const homeProducts = (state:any) => state.user.homeProductsData;
export const contactUsField = (state:any) => state.user.contactUsField;
export const contactLoader = (state:any) => state.user.footerLoader;

export default userSlice.reducer;
import React,{useEffect, useState} from 'react'
import styled from "styled-components"
import {motion ,AnimatePresence} from "framer-motion"
import { Formik, useFormik } from 'formik';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import * as yup from 'yup';

import emailjs from 'emailjs-com';
import closeMenu from "../../Assets/close.svg"
import { PrimaryThemeColor ,PrimaryFont } from '../Constants';
import { useSelector } from "react-redux";
import axios from "axios"
import { contactUsField, ThemeColor, token , uid } from '../../ReduxToolkit/useReducer';
import Loader from '../Loader';
import { toast } from 'react-toastify';
import Tostify from './Tostify';


const MainCont = styled(motion.div)`
position: fixed;
display: flex;
flex-direction: column;
width:${props => props.product ? "465px" :  "967px"}; 
border-radius:16px;
background-color: #fff;
margin-left: auto;
margin-right: auto;
overflow:hidden;
padding-bottom: 30px;
top: 55%;
transform: translateY(-50%);
left: 0;
  right: 0;
  border: none;
  outline: none;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
z-index: 500;

@media (max-width: 1366px) {
  width:${props => props.product ? "465px" :  "60%"}; 
  padding-bottom: 15px;
  top:${props => props.getQuotes ? "55%" :  "50%"}
}
@media (max-width: 768px) {
  width: 90%;
  padding-bottom: 10px;
  top:${props => props.getQuotes ? "50%" :  "45%"}
}
`;

const Titlediv = styled(motion.div)`
width:100%;
height: 80px;
display: flex;
justify-content: center;
align-items: center;
background-color: #333333;
`;

const TitleElement = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 600;
font-size: 21px;
color: #fff;
align-items: center;
text-align: right;
display: flex;


`;

const InputElementsCont = styled(motion.div)`
display: grid;
grid-template-columns: ${props => props.product ? "1fr" :  "1fr 1fr"};
width: 100%;
justify-content: space-around;
`;

const Form = styled(motion.form)`
width: 100%;
display: flex;
flex-direction:column;
justify-content: space-between;
margin-left: auto;
margin-right: auto;
/* height: 500px; */
`;


const InputContBlock = styled(motion.div)`
display: flex;
flex-direction: column;
`;



 const InputBox = styled.input`
  padding: 16px 16px 5px 32px;
  text-align: center;
  border: none;
  outline: none;
  border-bottom: 1px solid #B2B2B2;
  /* background: rgba(0, 0, 0, 0.003); */
  font-family: ${PrimaryFont};
font-weight: "Medium";
font-size: 16px;
outline: none;
color: #D1D1D1;
margin-top: ${props => props.product ? "8px" :  "20px"};
width: 80%;
margin-left: auto;
margin-right: auto;

@media (max-width: 1366px) {
  margin-top: 5px;
  font-size: 18px;

  }


@media (max-width: 768px) {
  margin-top: 10px;
  font-size: 12px;
}

`;

const ContentTitle = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 500;
font-size: 22px;
margin-top: 20px;
margin-bottom: 20px;
margin-left: 50px;
@media (max-width: 768px) {
  font-size: 15px;
margin-top: 20px;
margin-bottom: 20px;
margin-left: 20px;
}
`;

const InputBoxCont = styled.textarea`
height: 138px;
width: 90%;
border-radius: 15px;
border: 1px solid black;
font-family: ${PrimaryFont};
font-weight: 500;
font-size: 14px;
color: #A2A2A2;
margin-left: auto;
margin-right: auto;
padding: 10px;
@media (max-width: 768px) { 
  height: 88px;
}

`;

const ContactusBtn = styled(motion.button)`
display: flex;
  font-family: ${PrimaryFont};
font-weight: 600;
font-size: 27px;
text-align: right;
background-color: ${prop => prop.Color};
color: #fff;
width: ${props => props.product ? "80%" :  "40%"};
margin-left: auto;
margin-right: auto;
margin-top: 30px;
border-radius: 20px;
align-content: center;
justify-content: center;
align-items:center;
height: 85px;
outline: none;
border: none;
cursor: pointer;

@media (max-width: 1366px) {
  font-size: 25px;
 height: 70px;

  }


@media (max-width: 768px) {
  font-size: 15px;
height: 50px;
width:  ${props => props.product ? "90%" :  "40%"};
margin-top: 10px;
}
`;

const Popuptext = styled(motion.h1)`
  font-family: ${PrimaryFont};
font-weight: 500;
font-size:${props => props.small ? "14px" : "22px"} ;
text-align: ${props => props.small ? "left" : "center"};
margin-top: 10px;
width: 80%;
margin-left: auto;
margin-right: auto;
color:  ${props => props.small ? "#333333" : "#000"};
@media (max-width: 768px) {
  font-size: 15px;
}
`;

const ErrorMsg = styled(motion.h1)`
 font-family: 'Montserrat', sans-serif,;
 text-align: center;
 font-size: 12px;
 font-weight: 400;
 margin-top: 5px;
 color: red;
`;


const Extra = styled(motion.div)`
height: 15px;
`;



function Popups(props) {



  const Color = useSelector(ThemeColor)
  const Token = useSelector(token)
  const Uid = useSelector(uid)

  const [loader ,setLoader] = useState(false)


  const {name,email,phone} = useSelector(contactUsField)
  useEffect(() => {
    // console.log(props)
  },[])


  const validateAddDetails = yup.object().shape({
    name : yup.string().required("Please enter your name"),
    country : yup.string().required("Please enter your country"),
    product : yup.string().required("Please enter product"),
    expectedPrice : yup.number().typeError('Expected price must be a number').required("Please enter expected price"),
    content : yup.string().required("Required"),
    email : yup.string().email("Email is invalid").required("Please enter your email"),
    phone : yup.number().typeError('Phone must be a number').required("Please enter your phone").positive().integer(),
  })

  const validate = yup.object().shape({
    name : yup.string().required("Please enter your name"),
    country : yup.string().required("Please enter your country"),
    address : yup.mixed().required("Please enter your address"),
    email : yup.string().email("Email is invalid").required("Please enter your email"),
    phone : yup.number().typeError('Phone must be a number').required("Please enter your phone").positive().integer(),
  })


  const validateQuantity = yup.object().shape({
    companyName : yup.string().required("Please enter your company name"),
    country : yup.string().required("Please enter your country"),
    estimatedQuantity : yup.number().typeError('Estimated Quantity must be a number').required("Please enter estimated quantity"),
    email : yup.string().email("Email is invalid").required("Please enter your email"),
  })


    const formik = useFormik({

        initialValues: {       
        name:name || "",
          email: email || '',
          phone: phone || "",
          country:"",
          product:"",
          expectedPrice:"",
          content:"",
          address:"",
          companyName:"",
          estimatedQuantity:"",
   
        },

        validationSchema: props.product ? props.getQuotes ? validateQuantity : validate :validateAddDetails,
        validateOnChange:false,
        validateOnBlur:false,


        onSubmit: (values,{setSubmitting, setErrors, setStatus, resetForm}) => {
   
         setLoader(true)
          console.log("---------------------------------------------------",values)


          const {name,address,email,phone,country,product,expectedPrice,content,companyName,estimatedQuantity} = values

          if(props.product){
          if(props.getQuotes){
            var FormData = require('form-data');
            var data = new FormData();
            data.append('token', Token);
            data.append('user_id', Uid);
            data.append('product_id', props.id);
            data.append('company_name', companyName);
            data.append('email', email);
            data.append('country', country);
            data.append('quantity', estimatedQuantity);

            var config = {
              method: 'post',
              url: `${process.env.REACT_APP_BASE_URL}api/save-qoute`,
         
              data : data
            };

            axios(config)
            .then(function (response) {
              toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                });
              setLoader(true)
              props.onclick();
              resetForm();
              console.log( "Response from Get Quates =>>>>>" ,JSON.stringify(response.data));
            })
            .catch(function (error) {
              console.log(error);
              toast.error(error.response && error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
               
                });
            });
          }else{
            var FormData = require('form-data');
            var data = new FormData();
            data.append('token', Token);
            data.append('user_id', Uid);
            data.append('product_id', props.id);
            data.append('name', name);
            data.append('email', email);
            data.append('country', country);
            data.append('phone_no', phone);
            data.append('address', address);

            var config = {
              method: 'post',
              url: `${process.env.REACT_APP_BASE_URL}api/save-order-sample`,
          
              data : data
            };

            axios(config)
            .then(function (response) {
              toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                });
            setLoader(false)
            props.onclick();
            resetForm();
              console.log("Response from order =>>",JSON.stringify(response.data));
            })
            .catch(function (error) {
              console.log(error);
              toast.error(error.response && error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
               
                });
            });
          }
        }else{
          var FormData = require('form-data');
          var data = new FormData();
          data.append('token', Token);
          data.append('user_id', Uid);
          data.append('product_id', props.id);
          data.append('name', name);
          data.append('email', email);
          data.append('country', country);
          data.append('phone_no', phone);
          data.append('expected_pricing', expectedPrice);
          data.append('content', content);

          var config = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}api/contacted`,
           
            data : data
          };

          axios(config)
          .then(function (response) {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              });
            setLoader(false)
            props.onclick();
            resetForm();
          
            console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            toast.error(error.response && error.response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
             
              });
            setLoader(false)
            props.onclick();
            console.log(error);
          });

        }


          // emailjs.send('service_u55dlne', 'template_ebw57it',values, 'user_vczSbs7sebZUOZVFvBHaP')
          // .then((result) => {
          //     // console.log(result.text)
          //     alert("Success");
          // }, (error) => {
          //     // console.log(error.text)
          //     alert("Falied");
          // });
        
    
        
   
        },
   
      });

    return (
 <>
      <Tostify />

        <MainCont

        product={props.product ? true : false}
       getQuotes={props.getQuotes ? true : false}

          initial={{
            opacity: 0,
            y: props.width < 1368 ? props.width < 768 ? 250 : 350  :500,
            scale: 0.5,
          }}
          animate={{
            opacity: 1,
            y:props.width < 1368 ? props.width < 768 ? -250 : -300 :props.getQuotes ? -300 : -400,
            scale: 1,
          }}
          transition={{
            type: "spring",
            damping: 10,
            mass: 0.75,
            stiffness: 100,
        }}
        exit={{
          opacity: 0,
         scale: 0.8,
         y:props.width < 1368 ? props.width < 768 ? -500 : -700 :-1000,
        }}
        >
           {loader && <Loader />}
            <Titlediv>
                <TitleElement>{ props.product ? props.getQuotes ? "Get Quote" : "Order Sample" :  "Connect Us" }
                <img
                whileTap={{scale:0.8}}
                style={{
                  height: "30px",
                  width: "30px",
                  position: "absolute",
                  right: "5%",
                  cursor: "pointer",
                }}
                 src={closeMenu} 
                 onClick={() => props.onclick()}
                  />
                </TitleElement>
            </Titlediv>
        <Form  onSubmit={formik.handleSubmit}>
            <InputElementsCont product={props.product ? true : false}>
            {props.getQuotes
            ?
<>
              <InputBox

              product={props.product ? true : false}

              id="companyName"

              name="companyName"

              type="text"

              placeholder="Company Name"


              onChange={formik.handleChange}

              value={formik.values.companyName}

              />

              {formik.errors.companyName ? <ErrorMsg>{formik.errors.companyName}</ErrorMsg> : <Extra />}
              </>
           
            :
                <InputContBlock>
            <InputBox

            id="name"

            name="name"

            type="text"

            placeholder="Your Name"


            onChange={formik.handleChange}

            value={formik.values.name}

            />
            {formik.errors.name ? <ErrorMsg>{formik.errors.name}</ErrorMsg> : <Extra />}
            </InputContBlock>
            }
            <InputContBlock>
            <InputBox

            id="email"

            name="email"

            type="email"

            placeholder="Your Email Address"


            onChange={formik.handleChange}

            value={formik.values.email}

            />
               {formik.errors.email ? <ErrorMsg>{formik.errors.email}</ErrorMsg> : <Extra />}

               </InputContBlock>

        {props.product ?
        <>

            <InputBox
            product={props.product ? true : false}

            id="country"

            name="country"

            type="text"

            placeholder="Country"


            onChange={formik.handleChange}

            value={formik.values.country}

            />
                 {formik.errors.country ? <ErrorMsg>{formik.errors.country}</ErrorMsg> : <Extra />}

       { props.getQuotes
            ?
            <>
            <InputBox
            product={props.product ? true : false}

            id="estimatedQuantity"

            name="estimatedQuantity"

            type="text"

            placeholder="Estimated Quantity"


            onChange={formik.handleChange}

            value={formik.values.estimatedQuantity}

            />
          {formik.errors.estimatedQuantity ? <ErrorMsg>{formik.errors.estimatedQuantity}</ErrorMsg> : <Extra />}
            </>
          :
          <>
            <InputBox
            product={props.product ? true : false}

            id="phone"

            name="phone"

            type="text"

            placeholder="Your Phone Number"


            onChange={formik.handleChange}

            value={formik.values.phone}

            />
                    {formik.errors.phone ? <ErrorMsg>{formik.errors.phone}</ErrorMsg> : <Extra />}
            <InputBox
            product={props.product ? true : false}

            id="address"

            name="address"

            type="text"

            placeholder="Address"


            onChange={formik.handleChange}

            value={formik.values.address}

            />
           {formik.errors.address ? <ErrorMsg>{formik.errors.address}</ErrorMsg> : <Extra />}
            <Popuptext >1 Quantity</Popuptext>
            <Popuptext small={true} >logistic cost will be born by buyer *</Popuptext>
            </>
      }
</>

        :
        <>
        <InputContBlock>
            <InputBox

            id="phone"

            name="phone"

            type="text"

            placeholder="Your Phone Number"


            onChange={formik.handleChange}

            value={formik.values.phone}

            />
               {formik.errors.phone ? <ErrorMsg>{formik.errors.phone}</ErrorMsg> : <Extra />}
               </InputContBlock>
               <InputContBlock>
            <InputBox

            id="country"

            name="country"

            type="text"

            placeholder="Country"


            onChange={formik.handleChange}

            value={formik.values.country}

            />
        {formik.errors.country ? <ErrorMsg>{formik.errors.country}</ErrorMsg> : <Extra />}
        </InputContBlock>
        <InputContBlock>
              <InputBox

              id="product"

              name="product"

              type="text"

              placeholder="Product"


              onChange={formik.handleChange}

              value={formik.values.product}

              />
      {formik.errors.product ? <ErrorMsg>{formik.errors.product}</ErrorMsg> : <Extra />}
      </InputContBlock>
      <InputContBlock>
                <InputBox

              id="expectedPrice"

              name="expectedPrice"

              type="text"

              placeholder="Expected Pricing"


              onChange={formik.handleChange}

              value={formik.values.expectedPrice}

              />
    {formik.errors.expectedPrice ? <ErrorMsg>{formik.errors.expectedPrice}</ErrorMsg> : <Extra />}

     </InputContBlock>
     </>
      }
            </InputElementsCont>

            {!props.product &&
            <>

            <ContentTitle>
              Content
            </ContentTitle>

        <InputBoxCont

        id="content"

        name="content"

        type="textarea"

        placeholder="Start writing here ..."


        onChange={formik.handleChange}

        value={formik.values.content}

        />
            {formik.errors.content ? <ErrorMsg>{formik.errors.content}</ErrorMsg> : <Extra />}
        </>
            }

        {/* <ContactusBtn 
        whileTap={{scale:0.9}}
        Color={Color}
        product={props.product ? true : false} type="submit" >
          { props.product ? props.getQuotes ? "Get Quotes" : "Order Sample" :  "Connect Us" }</ContactusBtn> */}

<ContactusBtn 
        whileTap={{scale:0.9}}
        Color={Color}
        product={props.product ? true : false} type="submit" >
         Submit</ContactusBtn>
        </Form>

        </MainCont>
        </> 
    )
}

export default Popups

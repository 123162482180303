import React from 'react'
import Lottie from 'react-lottie';
import loader from '../Assets/loader.json'


function Loader(prop) {

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: loader,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    }

    return (
        <div
        style={{
            position: "fixed",
            display: "flex",
            justifyContent:"center",
            alignContent:"center",
            alignItems:"center",
            // height: "100vh",
            // width: "100vw",
            height: "100%",
            width: "100%",
            backgroundColor: prop.color ? "#fff" :"#000000d1",
            backdropFilter: "blur(4px)",
            zIndex:5000,
            bottom: 0,
            overflow: "hidden",
           
        }}
        >
             <Lottie 
             style={{
                 zIndex:11000,
             }}
            options={defaultOptions}
            height={200}
            width={200}
          
            />
        </div>
    )
}

export default Loader

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import ContactUs from './Cards/ContactUs';
import SocialIcons from './Cards/SocialIcons';
import { Link, NavLink } from 'react-router-dom';
import { PrimaryThemeColor, PrimaryFont } from './Constants';
import { useSelector } from 'react-redux';

import { ThemeColor, token, uid, settings } from '../ReduxToolkit/useReducer';

const SocialContainer = styled(motion.div)`
	width: 100%;
	background-color: ${(prop) => prop.Color};
	/* height: 650px; */

	align-items: center;
	align-content: center;
	display: flex;
`;
const SocialSubContainer = styled(motion.div)`
	width: 70%;
	/* height: 650px; */

	align-items: center;
	justify-content: space-between;
	align-content: center;
	display: flex;
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
	@media (max-width: 1100px) {
		width: 95%;
	}
	@media (max-width: 768px) {
		flex-direction: column;
		justify-content: space-around;
		width: 90%;
		overflow: hidden;
		max-width: 450px;
	}
`;

const MainContainer = styled(motion.div)`
	width: 100%;
	background-color: #000000;
	/* height: 550px; */
	/* bottom: 100%; */
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
`;

const SubComp = styled(motion.div)`
	display: flex;
	width: 70%;
	flex-direction: row;
	justify-content: space-between;
	/* align-items: center;
align_content:center; */
	/* background-color: red; */
	margin-bottom: 50px;
	margin-top: 50px;
	max-width: 1200px;
	@media (max-width: 1100px) {
		width: 95%;
	}
	@media (max-width: 768px) {
		flex-direction: column;
		justify-content: space-around;
		width: 90%;
		overflow: hidden;
		max-width: 450px;
	}
`;

const MianDetailsCont = styled(motion.div)`
	display: flex;
	flex-direction: column;
	/* width: 90%;
marginleft: auto;
marginright: auto; */
`;

const SideComp = styled(motion.div)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* margin-left: auto; */
	width: 30%;
	max-height: 200px;
	/* background-color: royalblue; */
	@media (max-width: 1100px) {
		width: 40%;
	}
	@media (max-width: 768px) {
		width: 90%;
		margin-top: 50px;
	}
`;

const CompanyName = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 700;
	font-size: 45px;
	line-height: 55px;
	width: 70%;
	color: #fff;
	margin-bottom: 20px;
	@media (max-width: 768px) {
		width: 70%;
	}
`;

const CompanyAddr = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 100;
	font-size: 14px;
	width: 400px;
	color: #fff;
`;

const SideCompSub = styled(motion.div)`
	display: flex;
	flex-direction: column;
	justify-content: space-around;

	/* width:40%; */
`;

const SideCompText = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 100;
	font-size: 14px;
	width: 150px;
	color: #fff;
`;

function Footer(props: any) {
	const Color = useSelector(ThemeColor);
	const settingsData = useSelector(settings);

	// console.log("Data In FOOOOOTER =>",settingsData)

	return (
		<>
			<SocialContainer Color={Color}>
				<SocialSubContainer>
					<SocialIcons />
					<ContactUs onclick={props.onclick} />
				</SocialSubContainer>
			</SocialContainer>
			<MainContainer>
				<SubComp>
					<MianDetailsCont>
						<CompanyName>{settingsData.company_name}</CompanyName>
						<CompanyAddr>{settingsData.short_description}</CompanyAddr>
					</MianDetailsCont>

					<SideComp>
						<SideCompSub>
							<SideCompText>Quick Links</SideCompText>

							<NavLink
								activeStyle={{
									color: Color,
								}}
								style={{
									textDecoration: 'none',
									listStyleType: 'none',
									color: '#A2A2A2',
									fontFamily: 'Poppins',
									fontSize: '13px',
									fontWeight: 600,
								}}
								exact
								to='/'
							>
								Home
							</NavLink>
							<NavLink
								activeStyle={{
									color: Color,
								}}
								style={{
									textDecoration: 'none',
									listStyleType: 'none',
									color: '#A2A2A2',
									fontFamily: 'Poppins',
									fontSize: '13px',
									fontWeight: 600,
								}}
								to='/about'
							>
								About
							</NavLink>

							<NavLink
								activeStyle={{
									color: Color,
								}}
								style={{
									textDecoration: 'none',
									listStyleType: 'none',
									color: '#A2A2A2',
									fontFamily: 'Poppins',
									fontSize: '13px',
									fontWeight: 600,
								}}
								to='/products'
							>
								Products
							</NavLink>
							<NavLink
								activeStyle={{
									color: Color,
								}}
								style={{
									textDecoration: 'none',
									listStyleType: 'none',
									color: '#A2A2A2',
									fontFamily: 'Poppins',
									fontSize: '13px',
									fontWeight: 600,
								}}
								to='/blogs'
							>
								Blogs
							</NavLink>
							<NavLink
								activeStyle={{
									color: Color,
								}}
								style={{
									textDecoration: 'none',
									listStyleType: 'none',
									color: '#A2A2A2',
									fontFamily: 'Poppins',
									fontSize: '13px',
									fontWeight: 600,
								}}
								to='/cbm'
							>
								CBM
							</NavLink>
							<NavLink
								activeStyle={{
									color: Color,
								}}
								style={{
									textDecoration: 'none',
									listStyleType: 'none',
									color: '#A2A2A2',
									fontFamily: 'Poppins',
									fontSize: '13px',
									fontWeight: 600,
								}}
								to='/invoice'
							>
								Invoice
							</NavLink>
						</SideCompSub>

						<SideCompSub>
							<SideCompText>Address</SideCompText>
							<SideCompText>{settingsData.address}</SideCompText>

							<SideCompText>{settingsData.phone1}</SideCompText>
							<SideCompText>{settingsData.phone2}</SideCompText>
						</SideCompSub>
					</SideComp>
				</SubComp>
			</MainContainer>
		</>
	);
}

export default Footer;

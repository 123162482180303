import React,{useEffect,useState} from 'react'
import styled from "styled-components"
import { motion } from 'framer-motion'
import viewAll from "../../Assets/Home/ViewAllIcon.svg"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { PrimaryThemeColor ,PrimaryFont } from '../Constants';
import { useSelector } from "react-redux";

import { homeProducts, ThemeColor, token , uid } from '../../ReduxToolkit/useReducer';



const w = 450+"px" ;

const MainComt = styled(motion.div)`
width: 85%;
background-color: #ffffff;
/* height:500px; */
margin-left: auto;
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: space-between;
align-items: center;
overflow: visible;
margin-top: 100px;
margin-bottom: 100px;
margin-left: auto;
margin-right: auto;
max-width: 1400px;
align-items:center;
align-content:center;

@media (min-width: 2250px) {
    overflow: visible;

}


@media (max-width: 1100px) {
  width:95%;

}

@media (max-width: 768px) {
width: 90%;
max-width: ${w};
margin-top: 20px;
flex-direction: column;
align-items: inherit;
margin-top: 30px;
margin-bottom: 30px;
}
`;

const Heading = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 800;
font-size: 85px;
width: 25%;
line-height: 81px;


@media (max-width: 1366px) {

    font-size: 65px;

} 
@media (max-width: 1100px) {
    font-size: 50px;
}
@media (max-width: 768px) {
    font-size: 55px;
    width: 100%;
    flex-wrap: wrap;
}
`;

const AllProductsCont = styled(motion.div)`
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
width: 60%;
gap:10px;



@media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    width: 100%;

}
`;

const Image = styled(motion.img)`
height:240px;
width: 240px;
border-radius:15px;
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.41);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.41);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.41);
object-fit: cover;
/* margin-left: 5px; */


@media (max-width: 1366px) {
    height:200px;
width: 200px;

  }

  @media (max-width: 1100px) {
    height:150px;
width: 150px;
}

@media (max-width: 768px) {
    height:131px;
width: 131px;
margin-left: auto;
margin-right: auto;
}

`;


const ViewAll = styled(motion.button)`
display: flex;
 font-family: ${PrimaryFont};
font-weight: 600;
font-size: 18px;
background-color: ${prop => prop.Color};
color: #fff;
/* width:20%; */
width:50%;
/* margin-top: ${props => props.blog ? "-300px" : "-500px"}; */
border-radius: 100px;
justify-content: center;
align-items: center;
padding:20px;
padding-right: 60px;
height: 52px;
outline: none;
border: none;
margin-bottom: 50px;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
text-align: center;
cursor: pointer;
margin-top:20px;
@media (max-width: 1366px) {
    width:70%;
    /* margin-top: ${props => props.blog ? "-250px" : "-400px"}; */
    /* margin-top: -400px; */
}

@media (max-width: 1100px) {
    /* margin-top: ${props => props.blog ? "-250px" : "-300px"}; */
    width:25%;
    font-size: 15px;
    width:80%;
}

@media (max-width: 768px) {
width: 90%;
margin-left: auto;
margin-right: auto;
margin-top: 20px;
padding-right: 30px;
}
`;

const AllBlogsCont = styled(motion.div)`
display: flex;
flex-direction: row;
width: 70%;
/* gap:50px; */
overflow: hidden;



@media (max-width: 768px) {
    width: 100%;
    gap:20px;
}
`;

const BlogBlock = styled(motion.div)`
min-width: 381px;
border-radius: 15px;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
margin-bottom: 50px;
background-color: "#fff";
overflow: hidden;
margin-left: 50px;

@media (max-width: 768px) {
    min-width: 90%;
    margin-left: 5px;
    margin-right: 5px;
    /* height: 461px; */
}
`;

const BlogImg = styled(motion.img)`
width: 100%;
height: 281px;
object-fit: cover;
border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;
margin-bottom: 30px;
@media (max-width: 1366px) {

    height: 201px;

} 
@media (max-width: 768px) {
    height: 170px;
    margin-bottom: 10px;
}
`;

const BlogPr = styled(motion.h1)`
 font-family: ${PrimaryFont};
font-weight: 400;
font-size: 12px;
margin-left: 20px;
color:#FF5C5C;
@media (max-width: 1366px) {
    font-size: 10px;
} 
@media (max-width: 768px) {

}

`;

const BlogText = styled(motion.h1)`
 font-family: ${PrimaryFont};
font-weight: 400;
font-size: 25px;
margin-left: 20px;
margin-top: 40px;
width: 90%;
@media (max-width: 1366px) {
    font-size: 21px;
} 
@media (max-width: 768px) {
    margin-top: 10px;
    font-size: 21px;
}
`;

const BlogDate = styled(motion.h1)`
 font-family: ${PrimaryFont};
font-weight: 400;
font-size: 12px;
color:#969696;
text-align: right;
margin-right: 20px;
`;


const BlogButton = styled(motion.h1)`
 font-family: ${PrimaryFont};
font-weight: 600;
font-size: 21px;
color:${PrimaryThemeColor};
text-align: center;
text-decoration: underline;
cursor: pointer;
margin-top: 50px;
margin-bottom: 50px;
@media (max-width: 1366px) {
    font-size: 18px;
} 
@media (max-width: 768px) {
    margin-top: 20px;
margin-bottom: 10px;
}
`;

const MangoContainer = styled(motion.div)`
display: flex;
flex-direction: column;
/* justify-content: center; */
align-items: center;
cursor: pointer;

`;

const MangoName = styled(motion.h1)`
 font-family: ${PrimaryFont};
font-weight: 600;
font-size: 21px;
margin-top:10px;
@media (max-width: 768px) {
    font-size: 13px;
}
`;



// gsap.registerPlugin(ScrollTrigger);


function AllProducts(props) {

 
    const [ mobile , setMobile] = useState(false)
    const [ blogs , setBlogs] = useState([])
    const Color = useSelector(ThemeColor)
    const history = useHistory();
    const products = useSelector(homeProducts)

  
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
        }
    
     const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
  
      useEffect(() => {

        console.log("PRODUCST from HOME Page" ,products)
            function handleResize() {
            setWindowDimensions(getWindowDimensions());
            }
  
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, [products]);
  
        // console.log(windowDimensions.width)
        const [ numberOfItems , setNumberOfItems] = useState(windowDimensions.width < 1400 ? windowDimensions.width < 768 ? 1 : 1 :2)


        useEffect(() => {
            var qs = require('qs');
            var data = qs.stringify({
              'token': 'f91c74e65cba358f355c041395e4376d',
              'entity': 'blogs',
              'limit':'4'
            });
            var config = {
              method: 'post',
              url: 'https://adminn.indiaport.in/api/getData?token=f91c74e65cba358f355c041395e4376d&entity=news',
              headers: { 
                'Content-Type': 'application/x-www-form-urlencoded', 
                'Cookie': 'ci_session=rnl2r9khjmck303p5ns0noeohh973igh'
              },
              data : data
            };
            if(props.blog != undefined){
            axios(config)
            .then(function (response) {
              // console.log(JSON.stringify(response.data));
              const data = response.data.data;
              setBlogs(data)
            })
            .catch(function (error) {
              // console.log(error);
            });}
          
        
  
        
        },[])

    // useEffect(() => {

    //     ScrollTrigger.clearScrollMemory( ) ;
    //     ScrollTrigger.refresh();
    //     let sections = gsap.utils.toArray(".items");
    
     
    //     gsap.to(sections, {
    //       xPercent: -100 * (sections.length - numberOfItems),
    //       ease: "none",
    //       scrollTrigger: {
    //           trigger:".animate",
          
    //           id:"#hello",
    //           start:windowDimensions.width < 768 ? "center -60%" :"center -50%",
    //           end:windowDimensions.width < 768 ? "center -80%" : "center -80%",
    //         //   start:windowDimensions.width < 768 ? "center -60%" :"center center",
    //         //   end:windowDimensions.width < 768 ? "center -80%" : "center center",
    //         scrub: 1,
    //         snap: 1 / (sections.length + 1 ),
    //         // markers:true,
    //       }
    //     });
    // // },100)

    //     return () => {
    //         ScrollTrigger.getAll().forEach(t => t.kill());
    //       };
    //   },[windowDimensions.width])


    useEffect(() => {



      
        setTimeout(() => {


            ScrollTrigger.clearScrollMemory( ) ;
            ScrollTrigger.refresh();
            let sections = gsap.utils.toArray(".items");
        
                if(props.blog != undefined){
                    gsap.to(sections, {
                        xPercent: -100 * (sections.length - numberOfItems),
                        ease: "none",
                        overwrite: true,
                        
                        scrollTrigger: {
                            trigger:".animate",
                            immediateRender: false,
                            id:"#hello",
                            start:windowDimensions.width < 768 ? "30% center" :"30% center",
                            end:windowDimensions.width < 768 ? "60% center" : "60% center",
                        scrub: 1,
                        //   snap: 1 / (sections.length + 1 ),
                        // markers:true, 

                        //   fixedMarkers:true,
                        //   anticipatePin: 1,
                        //   pin:true,
                        
            
                        }
                    });


                }

            const scroll = ScrollTrigger.getAll()
            // console.log("SCROLL",scroll)

        },1000)

        return () => {
            ScrollTrigger.getAll().forEach(t => t.kill());
          
          }
     
      },[props.blog])





    // console.log( ".........",props.blog)

    const fruits = [
        {
            name:"Mango",
            path:"https://images.unsplash.com/photo-1528825871115-3581a5387919?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=715&q=80"
        },
        {
            name:"Mango",
            path:"https://images.unsplash.com/photo-1601004890684-d8cbf643f5f2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60"
        },
        {
            name:"Mango",
            path:"https://images.unsplash.com/photo-1550258987-190a2d41a8ba?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8M3x8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60"
        },
        {
            name:"Mango",
            path:"https://images.unsplash.com/photo-1547514701-42782101795e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Nnx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60"
        },
        {
            name:"Mango",
            path:"https://images.unsplash.com/photo-1574856344991-aaa31b6f4ce3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80"
        },
        {
            name:"Mango",
            path:"https://images.unsplash.com/photo-1557800636-894a64c1696f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80"
        },
        {
            name:"Mango",
            path:"https://images.unsplash.com/photo-1559181567-c3190ca9959b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=764&q=80"
        },
        {
            name:"Mango",
            path:"https://images.unsplash.com/photo-1523049673857-eb18f1d7b578?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1075&q=80"
        },
        {
            name:"Mango",
            path:"https://images.unsplash.com/photo-1548808918-a33260f83b25?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=984&q=80"
        },
        {
            name:"Mango",
            path:"https://images.unsplash.com/photo-1528825871115-3581a5387919?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=715&q=80"
        },
        {
            name:"Mango",
            path:"https://images.unsplash.com/photo-1528825871115-3581a5387919?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=715&q=80"
        },
        {
            name:"Mango",
            path:"https://images.unsplash.com/photo-1548808918-a33260f83b25?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=984&q=80"
        },
        
    ]


    


    const blogsData = [
        {
            image_url:"https://cdn.pixabay.com/photo/2016/11/21/13/20/port-1845350_960_720.jpg",
            pro:"Standard Program",
            title:"A Look into Chinas Pandemic Recovery & Whats Happening in its Retail and Foodservice Sectors",
            added_on:"AUGUST 06, 2021",
        },
        {
            image_url:"https://cdn.pixabay.com/photo/2021/08/20/15/04/ship-6560671_960_720.jpg",
            pro:"Standard Program",
            title:"A Look into Chinas Pandemic Recovery & Whats Happening in its Retail and Foodservice Sectors",
            added_on:"AUGUST 06, 2021",
        },
        {
            image_url:"https://cdn.pixabay.com/photo/2018/01/30/13/28/container-3118783_960_720.jpg",
            pro:"Standard Program",
            title:"A Look into Chinas Pandemic Recovery & Whats Happening in its Retail and Foodservice Sectors",
            added_on:"AUGUST 06, 2021",
        },
        {
            image_url:"https://cdn.pixabay.com/photo/2020/07/12/09/31/ship-5396662_960_720.jpg",
            pro:"Standard Program",
            title:"A Look into Chinas Pandemic Recovery & Whats Happening in its Retail and Foodservice Sectors",
            added_on:"AUGUST 06, 2021",
        },
    ]



    return (
        <MainComt
        className={props.blog ? "animate" : "none"}
        >
            <div>

         
            <Heading >{props.title}</Heading>

{windowDimensions.width > 768  &&
            <ViewAll
           Color={Color}
           blog={props.blog ? true : false}
           onClick={() => props.blog ? history.push('/blogs') : history.push('/products')}
           whileTap={{scale:0.98}}
           >

                   
               VIEW ALL
  
               <img
               style={{
           height: 13,
           width:19,
           marginLeft:10,
               }}
                src={viewAll} alt="View all button icon"/>
           </ViewAll>}
           </div>

            {props.blog ?

            <AllBlogsCont

            >
                {
            blogs.map((d,i)=>{

                var title = ""

                const filter = () => {

                  if(d.title.length > 30){
                    // console.log("/*/*/*/*/*/*/*/*/*/*/",typeof(d.title))
                    title = d.title.slice(0,30) + "...."
                    // setTitleNews(d.title.slice(0,40) + "....")
                    return title;
                  }else{
                    // setTitleNews(d.title)
                    title = d.title;
                    return title;
                  }

  
                };
              
                 
                filter();
                return(
                    <BlogBlock
                    className="items"
                    key={i}
                    >
                        <BlogImg src={d.image_url} />
                        <BlogPr>Standard Program</BlogPr>
                        <BlogText>{title}</BlogText>
                        <BlogDate>{d.added_on}</BlogDate>
                        <Link
                        key={i}
                        style={{
                          textDecoration:"none",
                          color: "#000000",
                        }}
                        to={{
                          pathname:"blogs/blog",
                          state:{
                         d
                          }
                        }}
                        >
                        <BlogButton >Read Full Story</BlogButton>
                        </Link>
                    </BlogBlock>
                )
            })
        }

        </AllBlogsCont>
            :
            <AllProductsCont>
      
                
        {
            products.map((d,i) => {

                let replaceSpaceText = d.product_title.toLowerCase().replaceAll(" ","-")

                console.log(d,"PRODUCT In ALL PRoduct")
                
                if(d.product_title){
                    // console.log("/*/*/*/*/*/*/*/*/*/*/",d.title.length)
                       var title = ""
  
                  const filter = () => {
  
                    if(d.product_title.length > 30){
                      // console.log("/*/*/*/*/*/*/*/*/*/*/",d.title.length)
                      title = d.product_title.slice(0,30) + "...."
                      // setTitleNews(d.title.slice(0,40) + "....")
                      return title;
                    }else{
                      // setTitleNews(d.title)
                      title = d.product_title;
                      return title;
                    }
    
                  };
                
                   
                  filter();
                  }
                return (
                    <Link
                    key={i}
                    style={{
                      textDecoration:"none",
                      color: "#000000",
                    }}
                    to={{
                      pathname:`products/${replaceSpaceText}`,
                      state:{
                        id:d.id
                      }
                    }}
                    >
                    <MangoContainer key={i}
                    whileHover={{scale:0.9}}
                    >

                        <Image  src={d.banner_image} alt="All Products" />
                        <MangoName>{title}</MangoName>
                    </MangoContainer>
                    </Link>
                )
            })
        }
     
    
            </AllProductsCont>
        }


        {windowDimensions.width < 768  &&
            <ViewAll
           Color={Color}
           blog={props.blog ? true : false}
           onClick={() => props.blog ? history.push('/blogs') : history.push('/products')}
           whileTap={{scale:0.98}}
           >

                   
               VIEW ALL
  
               <img
               style={{
           height: 13,
           width:19,
           marginLeft:10,
               }}
                src={viewAll} alt="View all button icon"/>
           </ViewAll>}
        </MainComt>
    )
}

export default AllProducts

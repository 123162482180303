import React ,{useEffect,useState}from 'react'
import styled from 'styled-components'
import { motion, useAnimation,AnimatePresence } from 'framer-motion'
import AllProducts from './Cards/AllProducts'
import Footer from './Footer'
import Bus from "../Assets/bus.svg"
import cert1 from "../Assets/About/cert1.svg"
import cert2 from "../Assets/About/cert2.svg"
import cert3 from "../Assets/About/cert3.svg"
import cert4 from "../Assets/About/cert4.svg"
import cert5 from "../Assets/About/cert5.svg"
import cert6 from "../Assets/About/cert6.svg"
import PhotoCont from './Cards/PhotoCont'
import Popups from './Cards/Popups'
import { PrimaryThemeColor  ,PrimaryFont} from './Constants';
import { useSelector ,useDispatch } from "react-redux";

import { about, contactLoader, getAboutData, settings, ThemeColor, token,  uid } from '../ReduxToolkit/useReducer';

import axios from "axios"
import Loader from './Loader'



// * USING JSX BECAUSE PROPS WEREN'T WORKING PROPERLY FOR STYLED COMPONENTS ========================>


const MainCont = styled(motion.div)`
width: 85%;
display: flex;
/* margin-top: 40px; */
justify-content: space-around;
/* align-content: center; */
align-items: center;
height: 80vh;
max-width: 1200px;
margin-left: auto;
margin-right: auto;
@media (max-width: 1100px) {
  width:95%;
}
@media (max-width: 768px) {
width: 90%;
height: auto;
flex-direction: column;
margin-top: 40px;
max-width: 450px;
}
`;

const BusImage = styled(motion.img)`
height:378px;
max-width: 600px;
@media (max-width: 1366px) {
    height:278px;
}
@media (max-width: 1100px) {
    height:190px;
}
@media (max-width: 768px) {
    height:150px;
    margin-bottom: 30px;
    object-fit: contain;
}
`;

const HeroContent = styled(motion.div)`
display: flex;
flex-direction: column;
width: 40%;
@media (max-width: 1100px) {
    width: 40%;
}
@media (max-width: 768px) {
    width: 90%;

}
`;

const TitleHero = styled(motion.h1)`
font-family: ${PrimaryFont};
/* font-family: Poppins', sans-serif; */
font-weight: 700;
font-size: 45px;
width: 100%;
line-height: 55px;
@media (max-width: 1366px) {
    font-size: 46px;  
    line-height: 56px;
}
@media (max-width: 1100px) {
    font-size: 40px;
    line-height: 47px;
}
@media (max-width: 768px) {
    width: 90%;

}
`;

const InfoHero = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 500;
font-size: 21px;
line-height: 28px;
margin-top: 30px;
color:#0F0F0F;
/* text-align: justify; */
@media (max-width: 1366px) {
    font-size: 18px;  
    line-height: 20px;
}
@media (max-width: 1100px) {
    font-size: 16px;
    line-height: 18px;
}
`;


const DescHero = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 400;
font-size: 14px;
line-height: 19px;
margin-top: 30px;
color:#0F0F0F;
/* text-align: justify; */
@media (max-width: 1366px) {
    font-size: 12px;  
    line-height: 14px;
}
@media (max-width: 1100px) {
    font-size: 10px;
    line-height: 12px;
}
`;


//! Certifications ==========================================================>


const CerificatesCont = styled.div`
width:70%;
margin-top: 200px;
margin-bottom: 200px;
margin-left: auto;
margin-right: auto;
max-width: 1200px;
/* overflow: hidden; */
@media (max-width: 1100px) {
  width:95%;
}
@media (max-width: 768px) {
    width: 90%;
    margin-top: 100px;
margin-bottom: 100px;
max-width: 450px;
}
`;

const CertTitle = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 700;
font-size: 56px;
/* line-height: 22px; */
color:#0F0F0F;
@media (max-width: 768px) {
    font-size: 42px;
    line-height: 16px;
}
`;

const CertiImagesCont = styled(motion.div)`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
gap: 50px;
margin-top: 50px;

@media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
`;

const CertImage = styled(motion.img)`
max-width:300px;
max-height:500px;
margin-left: auto;
margin-right: auto;
`;



//! Comppany ==========================================================>


const CompanyContainer = styled.div`
width:70%;
margin-top: 200px;
margin-bottom: 200px;
margin-left: auto;
margin-right: auto;
max-width: 1200px;
@media (max-width: 1100px) {
  width:95%;
}
@media (max-width: 768px) {
    width: 90%;
    margin-top: 100px;
margin-bottom: 100px;
max-width: 450px;
}
`;

const Company = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 700;
font-size: 61px;
line-height: 22px;
margin-top: 100px;
margin-bottom: 100px;
text-align: center;
color:#0F0F0F;
@media (max-width: 1366px) {
    font-size: 51px;  
}
@media (max-width: 768px) {
    font-size: 42px;
    line-height: 16px;
    margin-top: 50px;
margin-bottom: 50px;

}
`;

const CompanyCont = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-around;
`;

const CompanyText = styled(motion.div)`
display: flex;
flex-direction: column;
width: 30%;
@media (max-width: 1366px) {
    width: 60%; 
}
@media (max-width: 1100px) {
    width: 60%; 
}

@media (max-width: 768px) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}
/* position: relative; */
`;


const CoFounder = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 700;
font-size: 40px;
@media (max-width: 768px) {
    font-size: 30px;
}
`;

const Symbol = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 700;
font-size: 230px;
color:#E3E3E3;
line-height: 10px;
margin-top: 150px;
font-style: italic;
width: 100%;
text-align: ${(props) => props.right ? "right" : "left"};

`;



const CompanyDesc = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 400;
font-size: 24px;
line-height: 32px;
text-align: center;
`;


const CompanyEmp = styled(motion.div)`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
gap: 20px;
width: 30%;
justify-items: center;
@media (max-width: 1366px) {
    width: 95%; 
    margin-left: auto;
    margin-right: auto;

}
@media (max-width: 768px) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    gap: 10px;
    max-width: 450px;
}
/* position: relative; */
`;


//! About Us ==========================================================>

const AboutUsContainer = styled.div`
width:70%;
margin-top: 200px;
margin-bottom: 200px;
margin-left: auto;
margin-right: auto;
max-width: 1200px;
@media (max-width: 1100px) {
  width:95%;
}
@media (max-width: 768px) {
    width: 90%;
    margin-top: 100px;
margin-bottom: 100px;
max-width: 450px;
}
`;

const AboutUsCont = styled(motion.div)`
display: flex;
flex-direction: column;
`;

const AboutUsContRow = styled(motion.div)`
display: flex;
flex-direction: row;
`;

const AboutUsContRowItem = styled(motion.div)`
background-color: ${props => props.color};
width:${props => props.width};
display: flex;
align-items: center;
min-height: 100px;
@media (max-width: 768px) {
    width: 50%;
    min-height: 50px;

}
`;

const Column = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 400;
font-size: 16px;
width: 100%;
margin-left:${props => props.right ? "0px" : "50px"} ;
margin-right:${props => props.right ? "50px" : "00px"} ;
text-align: ${props => props.right ? "right" : "left"};
background-color: ${props => props.color};
width:${props => props.width};
@media (max-width: 768px) {
    /* line-height: 50px; */
    font-size: 10px;
    margin-left:${props => props.right ? "0px" : "20px"} ;
margin-right:${props => props.right ? "20px" : "0px"} ;
}
`;

const PopupMainCont = styled(motion.div)`
display: flex;
justify-content: center;
align-items: center;
height: 120vh;
width: 100%;
position: absolute;
background-color: #000000d1;
z-index:100;
top: 0;
left:0;
position: fixed;
backdrop-filter: blur(4px);

`;



//! =======================<The End>===================================>

function About(props) {



    const controls = useAnimation()

    const [popUp , setPopUp] = useState(false);

    const [aboutData , setAboutData] = useState(null)
    const [aboutUsData , setAboutUsData] = useState(null)
    const [cert , setCert] = useState(null)
    const settingsData = useSelector(settings)
    const Uid = useSelector(uid)
    const Token = useSelector(token)
    const aboutFromRedux = useSelector(about)
    const dispatch = useDispatch();
    const contactUsLoader = useSelector(contactLoader)
    console.log("ABOUTPAGE => " ,aboutFromRedux)

    document.title = settingsData.company_name ? `${settingsData.company_name} / About Us`: "";

    useEffect(() => {
        window.scrollTo(0, 0);

      controls.start(i => ({
        opacity: [0,0.1,0.3,0.8,1],
        x: [-1000,0],
        scale:[0.1,0.8,1],
        transition: { delay: i * 1 ,duration:1},
      }))
    }, [controls])




    useEffect(() => {


        var data = new FormData();
        data.append('user_id',Uid );
        data.append('token', Token);



        var aboutConfig = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}api/about-us`,
            // headers: { 
            //     ...data.getHeaders()
            // },
            data :data
            };

        // if(aboutFromRedux.length == 0){
            axios(aboutConfig)
            .then(function (response) {
            console.log("AAAAAAAAAA >>>>>>>>>><<<<<<<<<<>>>>>>>>>>>><<<<><>>",JSON.stringify(response.data.certificates_data));
            const aboutInfo = JSON.stringify(response.data.about_us)
            setAboutData(response.data.about_us)
            dispatch(getAboutData(response.data.about_us));
            const aboutVal = JSON.parse(response.data.about_us.about_us)
            setAboutUsData(aboutVal)
            setCert(response.data.certificates_data)
      
              try{
                window.localStorage.setItem('aboutData', aboutInfo);
              }catch(e){
                console.log("Local Storage Error",e)
              }

        
            })
            .catch(function (error) {
            console.log(error);
            });
        // }else{
        //     setAboutData(aboutFromRedux)

        // }

          


    },[Uid])










    const onclick = () => {
        setPopUp(!popUp)
      }


    const Columns = (props) => {
        return(
            <AboutUsContRow>
                <AboutUsContRowItem color="#F4F4F4" width="40%">
                <Column   right>{props.txt1}</Column>
                </AboutUsContRowItem>
                <AboutUsContRowItem color="#F7F7F7" width="60%">
                <Column >{props.txt2}</Column>
                </AboutUsContRowItem>
            </AboutUsContRow>
        )
    }


    // if(aboutData === null) {
    //     return null
    // }

    console.log("ABOUT DATATATATATATA  =>>>>", aboutUsData)

    return (
        <div>
            {(aboutData === null || contactUsLoader) && <Loader />}
        <AnimatePresence>
          {
            popUp &&  <>
            <PopupMainCont
            whileTap={{opacity:0,scale:1.5}}
            transition={{
              duration:0.3,
            }}
            exit={{opacity:0}}
             onClick={onclick}>
         
             </PopupMainCont>
           
                   <Popups width={props.dimensions.width} onclick={onclick}/>
             
                   </>
          }
        </AnimatePresence>
        <MainCont>

        <BusImage
        initial={{opacity:0}}
         animate={controls} 
         transition={{
            type: "spring",
            damping: 10,
            mass: 0.75,
            stiffness: 100,
        }}
         src={Bus} />
        <HeroContent
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{
           delay:0.3
        }}
        >
            <TitleHero>
               {settingsData.company_name}
            </TitleHero>
            <InfoHero>
           {aboutData != null && aboutData.sec1 }

            </InfoHero>
            <DescHero>
            {aboutData != null && aboutData.sec2}
            </DescHero>
        </HeroContent>
        </MainCont>
        {aboutData != null && Number(aboutData.is_display_certificates)  ?
        <>
        <CerificatesCont>
            <CertTitle>CERTIFICATES</CertTitle>
         <CertiImagesCont>
                {
              cert != null &&  cert.map((d,i) => {
                        return (
                            <CertImage src={d}/>
                        )
                    })
                }
               
            </CertiImagesCont> 
        </CerificatesCont></>:null}
        {aboutData != null && Number(aboutData.is_display_lkprofile) ?
        <CompanyContainer>
        <Company>Company</Company>
        <CompanyCont>
            <PhotoCont 
            name={aboutData != null && aboutData.linkedin_profile[0].name} 
            job={aboutData != null && aboutData.linkedin_profile[0].job}
            position={aboutData != null && aboutData.linkedin_profile[0].position}
            experience={aboutData != null && aboutData.linkedin_profile[0].experience}
            image={aboutData != null && aboutData.linkedin_profile[0].image}
            small/>
            <CompanyText>
                <CoFounder>Co-Founder</CoFounder>
                <Symbol>"</Symbol>
                <CompanyDesc>{aboutData != null && aboutData.linkedin_quote}</CompanyDesc>
                <Symbol right>"</Symbol>
            </CompanyText>
          {  aboutData != null && aboutData.linkedin_profile.length > 1 && 
          <CompanyEmp>
                {
                    aboutData != null && aboutData.linkedin_profile.map((d,i) => {
                        return (
                            
                            i !== 0 && <PhotoCont 
                            key={i}
                            name={d.name} 
                            job={d.job}
                            position={d.position}
                            experience={d.experience}
                            image={d.image}
                            smaller/> 
                        )
                    })
                }
    
         </CompanyEmp> 
        }
        </CompanyCont>
        </CompanyContainer>: null
            }
        <AboutUsContainer>
        <Company>About Us</Company>
        <AboutUsCont>
        {
                   aboutUsData != null &&  aboutUsData.map((d,i) => {
                        return(
                            <Columns txt1={d.title} txt2={d.value} />
                        )
                    })
        }
            
        </AboutUsCont>
        </AboutUsContainer>
        <AllProducts title="ALL PRODUCTS"/>
        <Footer onclick={onclick}/>
        </div>
    )
}

export default About

import React ,{useEffect} from 'react';
import Routes from './Router/Routes';
import GlobalStyle from './GlobalStyles';
import axios from 'axios';
import { Provider } from 'react-redux';
import Store from './ReduxToolkit/Store';
// import { Cursor } from './Components/Cursor';
import "./app.css"





function App() {


 




  // document.getElementById("icon").href = "https://thumbs.dreamstime.com/b/export-vector-stamp-isolated-white-background-export-vector-stamp-150749515.jpg"
  
  return (
<Provider store={Store}>
    <div className="App">
      {/* <Cursor/> */}
      <GlobalStyle />
    <Routes/>
    </div>
    </Provider>
  );
}

export default App;

import React from 'react'
import styled from 'styled-components'





function EximFile(prop) {
    return (



<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 500.964 603.931">
  <defs>
    <filter id="Path_3967" x="0" y="0" width="500.964" height="311.787" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Path_3968" x="0" y="293.299" width="497.841" height="310.631" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-2"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-2"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="Group_6723" data-name="Group 6723" transform="translate(-391 -5353.684)">
    <g transform="matrix(1, 0, 0, 1, 391, 5353.68)" filter="url(#Path_3967)">
      <path id="Path_3967-2" data-name="Path 3967" d="M-58.382,0H341.187l-83.4,293.786H-141.777Z" transform="translate(150.78 6)" fill="#fff"/>
    </g>
    <g transform="matrix(1, 0, 0, 1, 391, 5353.68)" filter="url(#Path_3968)">
      <path id="Path_3968-2" data-name="Path 3968" d="M0,0H399.569l80.273,292.631H80.273Z" transform="translate(488.84 591.93) rotate(180)" fill="#fff"/>
    </g>
    <path id="Path_3973" data-name="Path 3973" d="M62.7,10.934,88.122,32.793l56.007-21.859L113.85,93.924l20.678,70.809L90.874,185.572,53.1,164.733,30.853,93.924Z" transform="translate(532.676 5558.861)" fill={prop.color}/>
  </g>
</svg>

    )
}

export default EximFile

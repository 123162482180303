import React from 'react'
import styled from 'styled-components'
import { motion} from "framer-motion"

import Lottie from 'react-lottie';


function Notfound(props) {

    console.log(props,"PROP")

    // document.title="Indiaport"

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: props.file,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    }
  return (
   <div
   style={{ 
       display: "flex",
 
       justifyContent: 'center',
       alignItems: 'center',
        height:"100vh"
   }}
   >
       <div
       style={{
           display: "flex",
           justifyContent: 'center',
           flexDirection: 'column',
           alignItems: 'center',
       }}
       >
           <Lottie 
          options={defaultOptions}
          height={props.dimensions.width < 600 ? "70%" : "30%"}
          width={props.dimensions.width < 600 ? "70%" : "30%"}
          style={{
             position: "relative" 
  
          }}
            />
        <h1
        style={{ 
            fontFamily:"Poppins, sans-serif",
            fontSize:21,
            fontWeight: 600,
            textAlign: "center",
            width: props.dimensions.width < 600 ? "90%" :"50%",
            color: "gray"
        }}
        >{props.msg}</h1>

        {
            props.msg === "It seems that you haven't added any data to your page, Please enter settings data to your page or contact your administrator to know more about it" && 
            <a
          style={{
              textDecoration: "none",
          }}
          href="https://iportadmin.wc-online.com/"
        
            >
                <motion.button
                whileHover={{backgroundColor:"#FBA426" , color:"#fff"}}
                  style={{ 
                    fontFamily:"Poppins, sans-serif",
                    fontWeight:500,
                    paddingTop:10,
                    paddingBottom:10,
                    paddingLeft:30,
                    paddingRight:30,
                    backgroundColor:"#fff",
                    borderRadius:10,
                    marginTop:30,
                    outline: "none",
                    border: "none",
                    cursor: "pointer",
                  
                    textAlign: "center",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                }}
                >
                    Add Data
                </motion.button>
            </a>
        }
            </div>
     
   </div>
  )
}

export default Notfound
import React from 'react'
import styled from 'styled-components'
import {motion} from "framer-motion"
import blueCont from "../../Assets/bluePath.svg"
import linkdin from "../../Assets/Home/linkdin.svg"
import { PrimaryThemeColor ,PrimaryFont } from '../Constants';
import { useSelector } from "react-redux";

import { ThemeColor, token , uid } from '../../ReduxToolkit/useReducer';

const MainCont = styled(motion.div)`
 position:relative;
display: flex;
flex-direction: column;
margin-bottom: 30px;
height: ${props => props.small ? "458px": props.smaller ? "196px" : "811px"} ;
width: ${props => props.small ? "280px": props.smaller ? "135px" : "100%"};
max-width: 475px;

border-top-left-radius:  ${props => props.smaller ? "30px" : "50px"};
border-top-right-radius:  ${props => props.smaller ? "30px" : "50px"};
overflow: hidden;
@media (max-width: 1500px) {
    height: ${props => props.small ? "458px": props.smaller ? "196px" : "711px"} ;
}
@media (max-width: 1366px) {
    height: ${props => props.small ? "458px": props.smaller ? "196px" : "600px"} ;
}
@media (max-width: 768px) {
    height: ${props => props.smaller ? "145px" : "466px"};
    width:${props => props.smaller ? "100px" : " 286px"};
    margin-left: auto;
margin-right: auto;
}

`;

const Image = styled(motion.img)`
position: absolute;
object-fit: cover;
height: 100%;
width: 100%;
z-index: 1;

`;

const Blue = styled(motion.img)`
position: absolute;
object-fit: cover;
z-index: 15;
width:${props => props.smaller ? "135px" : "auto"};
bottom: ${props => props.small ? "-100px":  props.smaller ? "-10px": "-25px"};

@media (max-width: 1366px) {
    bottom: ${props => props.small ? "-100px":  props.smaller ? "-10px": "-80px"};
}
@media (max-width: 768px) {
    bottom:${props => props.smaller ? "-10px" : "-100px"} ;
    width:${props => props.smaller ? "100px" : "auto"}
}
`;

const Linkdin = styled(motion.img)`
height: ${props => props.small ? "48px": props.smaller ? "25px": "58px"};
width: ${props => props.small ? "48px": props.smaller ? "25px": "58px"} ;
margin-left:${props => props.small ? "75%":props.smaller ? "75%": " 80%"};

margin-top: ${props => props.smaller ? "20px" : "50px"};
margin-right: 40px;
z-index: 50;

@media (max-width: 768px) {
 margin-left: 75%;
 height:${props => props.smaller ? "20px" : "48px"};
width:${props => props.smaller ? "20px" : "48px"};
}
`;

const NameOfFounder = styled(motion.h1)`
 font-family: ${PrimaryFont};
font-weight: 600;
font-size: ${props => props.small ? "36px":props.smaller ? "10px": "55px"};
line-height: ${props => props.small ? "36px":props.smaller ? "10px": "60px"};
color: white;
z-index: 20;
margin-top:${props => props.small ? "210px":props.smaller ? "95px": "480px"} ;
margin-left: 20px;
margin-bottom: 10px;
@media (max-width: 1500px) {
    margin-top:${props => props.small ? "210px":props.smaller ? "95px": "380px"} ;
}
@media (max-width: 1366px) {
    font-size: ${props => props.small ? "36px":props.smaller ? "10px": "40px"};
    line-height: ${props => props.small ? "36px":props.smaller ? "10px": "45px"};
    margin-top:${props => props.small ? "210px":props.smaller ? "95px": "320px"} ;
}
@media (max-width: 768px) {
    font-size:${props => props.smaller ? "10px" : "30px"}; 
    line-height: ${props => props.smaller ? "10px": "32px"};
    margin-top:${props => props.smaller ? "60px" : "230px"};
margin-left:${props => props.smaller ? "5px" : "20px"};
margin-bottom: 5px;
}
`;

const Occupation = styled(motion.h1)`
 font-family: ${PrimaryFont};
font-weight: 600;
font-size: ${props => props.small ? "15px": props.smaller ? "8px": "31px"};
color: #679BFC;
z-index: 21;
margin-left: 25px;
margin-top: ${props => props.small ? "-10px":  props.smaller ? "-2px":"-20px"} ;
@media (max-width: 1500px) {
    margin-top:${props => props.small ? "210px":props.smaller ? "95px": "-10px"} ;
}
@media (max-width: 1366px) {
    margin-top: ${props => props.small ? "-10px":  props.smaller ? "-2px":"-16px"} ;

    font-size: ${props => props.small ? "15px": props.smaller ? "8px": "21px"};

}
@media (max-width: 768px) {
    font-size: ${props => props.smaller ? "8px" : "20px"}; 
    margin-top: ${props => props.smaller ? "-4px" : "-10px"};
    margin-left:${props => props.smaller ? "5px" : "20px"};
}
`;

const Job = styled(motion.h1)`
position: absolute;
bottom: 10px;
 font-family: ${PrimaryFont};
font-weight: 600;
font-size: ${props => props.small ? "13px": props.smaller ? "8px": "26px"};
color: white;
z-index: 22;
margin-left: 25px;
margin-top:${props => props.smaller ? "10px" :"10px"};
@media (max-width: 1366px) {
    font-size: ${props => props.small ? "13px": props.smaller ? "8px": "21px"};
 
}
@media (max-width: 768px) {
    font-size:${props => props.smaller ? "5px" : "18px"}; 
    margin-left:${props => props.smaller ? "5px" : "20px"};
}
`;

const ExperianceTray = styled(motion.div)`
width: ${props => props.small ? "40px": props.smaller ? "40px": "110px"} ;
z-index: 250;
position: absolute;
bottom:${props => props.smaller ? "10px" : "35px"};
left: ${props => props.smaller ? "65%" : "70%"};
text-align: center;
justify-content: center;
align-items: center;


@media (max-width: 768px) {
/* left: 60%;
bottom: 20px; */
bottom:${props => props.smaller ? "10px" : "20px"};
left: ${props => props.smaller ? "55%" : "60%"};
}
`;

const Years = styled(motion.h1)`
 font-family: ${PrimaryFont};
font-weight: 400;
font-size:${props => props.small ? "32px": props.smaller ? "20px": "63px"} ;
color: black;
z-index: 30;
text-align: center;
margin-bottom: 8px;
@media (max-width: 1366px) {
    font-size:${props => props.small ? "32px": props.smaller ? "20px": "45px"} ;
}
@media (max-width: 768px) {
    font-size:${props => props.smaller ? "10px" : "35px"}; 

}
`;

const Exp = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 400;
font-size: ${props => props.small ? "8px": props.smaller ? "8px": "15px"};
color: black;
z-index: 250;
margin-top:${props => props.small ? "-10px": props.smaller ? "-5px": "-25px"} ;
line-height:${props => props.small ? "8px": props.smaller ? "8px": "18px"};
text-align: center;
@media (max-width: 1366px) {
    margin-top:${props => props.small ? "-10px": props.smaller ? "-5px": "-20px"} ;
    font-size: ${props => props.small ? "8px": props.smaller ? "8px": "15px"};
}
@media (max-width: 768px) {
    font-size: ${props => props.smaller ? "6px" : "8px"}; 
    margin-top:${props => props.smaller ? "-5px": "-20px"};
    line-height:${props => props.smaller ? "6px": "10px"};
}
`;


function PhotoCont(props) {
    console.log("TYPE OF EXP",props.experience)

    return (
        props.small ? 
        <MainCont small >
        <Linkdin small src={linkdin} />
        {/* <Image  src={"https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80"} /> */}
        <Image  src={props.image} />
        <Blue  small src={blueCont} />
        <NameOfFounder small >{props.name}</NameOfFounder>
        <Occupation small >{props.job}</Occupation>
        <Job small >{props.position}</Job>
        <ExperianceTray small>
            <Years small >{typeof(props.experience) == "string" && props.experience.slice(0, 1)}</Years>
            <Exp small >{typeof(props.experience) == "string" && props.experience.slice(2)} of Experiance</Exp>
        </ExperianceTray>
        </MainCont>
        :
        props.smaller ? 
        <MainCont smaller >
        <Linkdin smaller src={linkdin} />
        <Image  src={props.image} />
        <Blue  smaller src={blueCont} />
        <NameOfFounder smaller >{props.name}</NameOfFounder>
        <Occupation smaller >{props.job}</Occupation>
        <Job smaller >{props.position}</Job>
        <ExperianceTray smaller>
            <Years smaller >{typeof(props.experience) == "string" && props.experience.slice(0, 1)}</Years>
            <Exp smaller >{typeof(props.experience) == "string" && props.experience.slice(2)} of Experiance</Exp>
        </ExperianceTray>
        </MainCont>

        :

        <MainCont >
            <Linkdin src={linkdin} />
             <Image  src={props.image} />
            <Blue src={blueCont} />
            <NameOfFounder>{props.name}</NameOfFounder>
            <Occupation>{props.job}</Occupation>
            <Job>{props.position}</Job>
            <ExperianceTray>
                <Years>{typeof(props.experience) == "string" && props.experience.slice(0, 1)}</Years>
                <Exp>{typeof(props.experience) == "string" && props.experience.slice(2)} of Experiance</Exp>
            </ExperianceTray>
        </MainCont>
    )
}

export default PhotoCont

import React ,{useState ,useEffect ,useRef} from 'react'
import Footer from './Footer'
import styled from 'styled-components';
import { motion ,AnimatePresence} from 'framer-motion';
import search from "../Assets/Product/search.svg"
import { Link, useHistory } from 'react-router-dom';
import Popups from './Cards/Popups'
import { PrimaryThemeColor ,PrimaryFont } from './Constants';
import { useDispatch, useSelector } from "react-redux";

import { contactLoader, getProducts, products, ThemeColor, token , uid ,settings } from '../ReduxToolkit/useReducer';

import axios from "axios"
import { setLocale } from 'yup';
import ScrollContainer from 'react-indiana-drag-scroll'
import Loader from './Loader';

const MainCont = styled(motion.div)`
width:70%;
margin-bottom: 200px;
margin-top: 100px;
margin-left: auto;
margin-right: auto;
/* overflow: hidden; */
max-width: 1200px;
@media (max-width: 1100px) {
  width:95%;
}
@media (max-width: 768px) {
    width: 90%;
    margin-top: 40px;
margin-bottom: 100px;
max-width: 450px;
}
`;
//! Header ==========================================================>
const HeaderImgCont = styled(motion.div)`
width:100%;
display: flex;
justify-content: center;
/* background-color: black; */
margin-top:100px;
margin-left: auto;
margin-right: auto;
@media (max-width: 768px) {
    width: 90%;
    margin-top: 100px;
margin-bottom: 100px;
}
`;

const ProductsTitle = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 700;
font-size: 46px;
line-height: 50px;
@media (max-width: 768px) {
  font-size: 36px;
line-height: 40px;
}
`;

const ProductImg = styled(motion.img)`
width: 50%;
object-fit: cover;
height: 451px;
border-radius: 30px;
margin-left: auto;
margin-right: auto;

@media (max-width: 768px) {
    width: 100%;
    height: 300px;
}
`;
 
//! All Products With input Box ==========================================================>


const AllProductsHeader = styled(motion.div)`
display: flex;
justify-content: space-between;
/* margin-top:100px; */
flex-wrap: wrap;


@media (max-width: 768px) {
    width: 100%;
    /* margin-top: 100px; */
margin-bottom: 20px;
flex-direction: column;
}
`;

const InputContainer = styled(motion.div)`
display: flex;
width: 40%;
@media (max-width: 768px) {
  width: 90%;
}
`;

const InputIcon = styled(motion.div)`
height: 45px;
background-color: ${prop => prop.Color};
display: flex;
width: 50px;
border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
justify-content: center;
align-items: center;
@media (max-width: 768px) {
margin-top: 50px;
}
`;

const InputBox = styled.input`
  padding: 10px;
  text-align: center;
  border: none;
  outline: none;
  background-color: #E3E3E3;
  font-family: ${PrimaryFont};
font-weight: 500;
font-size: 21px;
height: 45px;
outline: none;
color: #A2A2A2;
width: 100%;
border-top-left-radius: 10px;
border-bottom-left-radius: 10px;


@media (max-width: 1366px) {
 
  font-size: 18px;
  width: 100%;

  }


@media (max-width: 768px) {
margin-top: 50px;
  font-size: 15px;
}

`;


const SearchImage = styled(motion.img)`
height: 27px;
width: 27px;
cursor: pointer;
`;


//! Paginatiom ==========================================================>



const PaginationBlock = styled(motion.div)`
display: flex;
flex-direction: row;
margin-right: "100%";
gap: 20px;
margin-top: 50px;
-webkit-box-pack: end;
-webkit-justify-content: flex-end;
    -ms-flex-pack: end;
        justify-content: flex-end;
@media (max-width: 768px) {
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
  -webkit-box-pack: start;
-webkit-justify-content: flex-start;
    -ms-flex-pack: start;
        justify-content: flex-start;
}
`;

const PageBlock = styled(motion.div)`
width: 54px;
height:62px;
border-radius: 8px;
border: 1px solid ${prop => prop.Color};
display: flex;
cursor: pointer;

justify-content: center;
align-items: center;
background-color: ${props => props.Color};
@media (max-width: 1366px) {
  width: 44px;
height:52px;
}
@media (max-width: 768px) {
  width: 37px;
height:43px;
}
`;


const BlockNumber = styled(motion.h1)`
font-family: 'Montserrat', sans-serif;
font-weight: 500;
font-size: 32px;
color:${props => props.color};
line-height: 43px;
`;


//! Pagination Items ==========================================================>

const ContentPagination = styled(motion.div)`
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
gap: 20px;
margin-top: 50px;
margin-left: auto;
margin-right: auto;
min-height:300px;

@media (max-width: 768px) {
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin-top: 10px;
  /* min-height:150px; */
}
`;

const ContentBlock = styled(motion.div)`
display: flex;
flex-direction: column;
cursor: pointer;


`;

const ImageBlock = styled(motion.img)`
height: 244px;
width: 244px;
border-radius: 15px;
margin-left: auto;
margin-right: auto;
object-fit: cover;
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.41);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.41);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.41);
@media (max-width: 1556px) {
  width: 200px;
height:200px;
}
@media (max-width: 1250px) {
  width: 150px;
height:150px;
}
@media (max-width: 768px) {
  height: 85px;
width: 85px;
}
`;


const ContentName = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 600;
font-size: 21px;
line-height: 30px;
text-align: center;
height: 100px;
margin-top:10px;
@media (max-width: 768px) {
  font-size: 18px;
  line-height: 18px;
  height: 40px;
}
`;

const PopupMainCont = styled(motion.div)`
display: flex;
justify-content: center;
align-items: center;
height: 120vh;
width: 100%;
position: absolute;
background-color: #000000d1;
z-index:100;
top: 0;
left:0;
position: fixed;
backdrop-filter: blur(4px);

`;


const FilterCont = styled(motion.div)`
display: flex;
align-items: center;
flex-direction: row;
gap: 20px;
margin-top: 50px;
width: 100%;
margin-left: auto;
margin-right: auto;
overflow-y: auto;
overflow-x: scroll;
/* -webkit-overflow-scrolling: touch; */
/* overflow: auto;  */
scrollbar-width: none;

-ms-overflow-style: none;
height: 100px;

::-webkit-scrollbar {
    display: none;
} 
@media (max-width: 768px) {
  gap: 10px;
  width: 100%;
  margin-left: "none";
margin-right: "none";
height: 50px;
margin-top: 20px;

}
`;


const FilterBlock = styled(motion.div)`
height: 50px;
/* border: 1px solid #333333; */
border-radius: 30px;
display: flex;
justify-content: center;
align-items: center;
font-family: ${PrimaryFont};
font-weight: 500;
font-size: 18px;
margin-right:20px;
color: ${prop => prop.color};
background-color: ${prop => prop.backgroundColor};
padding: 10px;
cursor: pointer;
min-width: 10rem;


@media (max-width: 1366px) {
  font-size: 15px;
}
@media (max-width: 768px) {
  font-size: 10px;
  height: 20px;
}
`;






function Products(props) {







  const history = useHistory();

    const [page , setPage] = useState(1)
    const [ searchText, setSearchText] = useState("")
    const [ searchDone, setSearchDone] = useState(false)
    const [pageChange ,setPageChange] = useState(false)
    const [all ,setAll] = useState(false)
    const [filter ,setFilter] = useState(false)
    const [contents, setContents] = useState([]);
    const [lengthSmall , setLengthSmall] = useState(12)
    const [lengthBig , setLengthBig] = useState(16)
    const [popUp , setPopUp] = useState(false);
    const [productList , setProductList] = useState([])
    const [categoryList , setCategoryList] = useState([])
    const [imgLoaded , setImgLoaded] = useState(false)
    const [paginationNumber , setPaginationNumber] = useState(0)
    const [selectedCategory , setSelectedCategory] = useState("All")
    const Uid = useSelector(uid)
    const Token = useSelector(token)

      const Color = useSelector(ThemeColor)
      const contactUsLoader = useSelector(contactLoader)
      const settingsData = useSelector(settings);



      document.title = settingsData.company_name ? `${settingsData.company_name} / Products`: "";

    const fruits = [
      {
        path:"https://images.unsplash.com/photo-1517282009859-f000ec3b26fe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80",
        name:"Papaya"
      },
      {
        path:"https://images.unsplash.com/photo-1421167418805-7f170a738eb4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
        name:"Pear",
      },
      {
        path:"https://images.unsplash.com/photo-1545822733-8347bd07d381?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80",
        name:"Mango",
      },
      {
        path:"https://images.unsplash.com/photo-1581375074612-d1fd0e661aeb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
        name:"Melon"
      },
      {
        path:"https://images.unsplash.com/photo-1581375321224-79da6fd32f6e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
        name:"Coconut",
      },
      {
        path:"https://images.unsplash.com/photo-1543158181-1274e5362710?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        name:"Nuts",
      },
      {
        path:"https://images.unsplash.com/photo-1502825751399-28baa9b81efe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1469&q=80",
        name:"Pista"
      },
      {
        path:"https://images.unsplash.com/photo-1517282009859-f000ec3b26fe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80",
        name:"Papaya"
      },
      {
        path:"https://images.unsplash.com/photo-1421167418805-7f170a738eb4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
        name:"Pear",
      },
      {
        path:"https://images.unsplash.com/photo-1545822733-8347bd07d381?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80",
        name:"Mango",
      },
      {
        path:"https://images.unsplash.com/photo-1581375074612-d1fd0e661aeb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
        name:"Melon"
      },
      {
        path:"https://images.unsplash.com/photo-1581375321224-79da6fd32f6e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
        name:"Coconut",
      },
      {
        path:"https://images.unsplash.com/photo-1543158181-1274e5362710?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        name:"Nuts",
      },
      {
        path:"https://images.unsplash.com/photo-1502825751399-28baa9b81efe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1469&q=80",
        name:"Pista"
      },
      {
        path:"https://images.unsplash.com/photo-1517282009859-f000ec3b26fe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80",
        name:"Papaya"
      },
      {
        path:"https://images.unsplash.com/photo-1421167418805-7f170a738eb4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
        name:"Pear",
      },
      {
        path:"https://images.unsplash.com/photo-1545822733-8347bd07d381?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80",
        name:"Mango",
      },
      {
        path:"https://images.unsplash.com/photo-1581375074612-d1fd0e661aeb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
        name:"Melon"
      },
      {
        path:"https://images.unsplash.com/photo-1581375321224-79da6fd32f6e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
        name:"Coconut",
      },
      {
        path:"https://images.unsplash.com/photo-1543158181-1274e5362710?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        name:"Nuts",
      },
      {
        path:"https://images.unsplash.com/photo-1502825751399-28baa9b81efe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1469&q=80",
        name:"Pista"
      },
      {
        path:"https://images.unsplash.com/photo-1517282009859-f000ec3b26fe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80",
        name:"Papaya"
      },
      {
        path:"https://images.unsplash.com/photo-1421167418805-7f170a738eb4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
        name:"Pear",
      },
      {
        path:"https://images.unsplash.com/photo-1545822733-8347bd07d381?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80",
        name:"Mango",
      },
      {
        path:"https://images.unsplash.com/photo-1581375074612-d1fd0e661aeb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
        name:"Melon"
      },
      {
        path:"https://images.unsplash.com/photo-1581375321224-79da6fd32f6e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
        name:"Coconut",
      },
      {
        path:"https://images.unsplash.com/photo-1543158181-1274e5362710?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        name:"Nuts",
      },
      {
        path:"https://images.unsplash.com/photo-1502825751399-28baa9b81efe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1469&q=80",
        name:"Pista"
      },
      {
        path:"https://images.unsplash.com/photo-1517282009859-f000ec3b26fe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80",
        name:"Papaya"
      },
      {
        path:"https://images.unsplash.com/photo-1421167418805-7f170a738eb4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
        name:"Pear",
      },
      {
        path:"https://images.unsplash.com/photo-1545822733-8347bd07d381?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80",
        name:"Mango",
      },
      {
        path:"https://images.unsplash.com/photo-1581375074612-d1fd0e661aeb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
        name:"Melon"
      },
      {
        path:"https://images.unsplash.com/photo-1581375321224-79da6fd32f6e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
        name:"Coconut",
      },
      {
        path:"https://images.unsplash.com/photo-1543158181-1274e5362710?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        name:"Nuts",
      },
      {
        path:"https://images.unsplash.com/photo-1502825751399-28baa9b81efe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1469&q=80",
        name:"Pista"
      },
      {
        path:"https://images.unsplash.com/photo-1517282009859-f000ec3b26fe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80",
        name:"Papaya"
      },
      {
        path:"https://images.unsplash.com/photo-1421167418805-7f170a738eb4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
        name:"Pear",
      },
      {
        path:"https://images.unsplash.com/photo-1545822733-8347bd07d381?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80",
        name:"Mango",
      },
      {
        path:"https://images.unsplash.com/photo-1581375074612-d1fd0e661aeb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
        name:"Melon"
      },
      {
        path:"https://images.unsplash.com/photo-1581375321224-79da6fd32f6e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
        name:"Coconut",
      },
      {
        path:"https://images.unsplash.com/photo-1543158181-1274e5362710?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        name:"Nuts",
      },
      {
        path:"https://images.unsplash.com/photo-1502825751399-28baa9b81efe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1469&q=80",
        name:"Pista"
      },
      {
        path:"https://images.unsplash.com/photo-1517282009859-f000ec3b26fe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80",
        name:"Papaya"
      },
      {
        path:"https://images.unsplash.com/photo-1421167418805-7f170a738eb4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
        name:"Pear",
      },
      {
        path:"https://images.unsplash.com/photo-1545822733-8347bd07d381?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80",
        name:"Mango",
      },
      {
        path:"https://images.unsplash.com/photo-1581375074612-d1fd0e661aeb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
        name:"Melon"
      },
      {
        path:"https://images.unsplash.com/photo-1581375321224-79da6fd32f6e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
        name:"Coconut",
      },
      {
        path:"https://images.unsplash.com/photo-1543158181-1274e5362710?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        name:"Nuts",
      },
      {
        path:"https://images.unsplash.com/photo-1502825751399-28baa9b81efe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1469&q=80",
        name:"Pista"
      },
      {
        path:"https://images.unsplash.com/photo-1517282009859-f000ec3b26fe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80",
        name:"Papaya"
      },
      {
        path:"https://images.unsplash.com/photo-1421167418805-7f170a738eb4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
        name:"Pear",
      },
      {
        path:"https://images.unsplash.com/photo-1545822733-8347bd07d381?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80",
        name:"Mango",
      },
      {
        path:"https://images.unsplash.com/photo-1581375074612-d1fd0e661aeb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
        name:"Melon"
      },
      {
        path:"https://images.unsplash.com/photo-1581375321224-79da6fd32f6e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
        name:"Coconut",
      },
      {
        path:"https://images.unsplash.com/photo-1543158181-1274e5362710?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        name:"Nuts",
      },
      {
        path:"https://images.unsplash.com/photo-1502825751399-28baa9b81efe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1469&q=80",
        name:"Pista"
      },
      {
        path:"https://images.unsplash.com/photo-1517282009859-f000ec3b26fe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80",
        name:"Papaya"
      },
      {
        path:"https://images.unsplash.com/photo-1421167418805-7f170a738eb4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
        name:"Pear",
      },
      {
        path:"https://images.unsplash.com/photo-1545822733-8347bd07d381?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80",
        name:"Mango",
      },
      {
        path:"https://images.unsplash.com/photo-1581375074612-d1fd0e661aeb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
        name:"Melon"
      },
      {
        path:"https://images.unsplash.com/photo-1581375321224-79da6fd32f6e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
        name:"Coconut",
      },
      {
        path:"https://images.unsplash.com/photo-1543158181-1274e5362710?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        name:"Nuts",
      },
      {
        path:"https://images.unsplash.com/photo-1502825751399-28baa9b81efe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1469&q=80",
        name:"Pista"
      },
      {
        path:"https://images.unsplash.com/photo-1517282009859-f000ec3b26fe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80",
        name:"Papaya"
      },
      {
        path:"https://images.unsplash.com/photo-1421167418805-7f170a738eb4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
        name:"Pear",
      },
      {
        path:"https://images.unsplash.com/photo-1545822733-8347bd07d381?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80",
        name:"Mango",
      },
      {
        path:"https://images.unsplash.com/photo-1581375074612-d1fd0e661aeb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
        name:"Melon"
      },
      {
        path:"https://images.unsplash.com/photo-1581375321224-79da6fd32f6e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
        name:"Coconut",
      },
      {
        path:"https://images.unsplash.com/photo-1543158181-1274e5362710?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        name:"Nuts",
      },
      {
        path:"https://images.unsplash.com/photo-1502825751399-28baa9b81efe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1469&q=80",
        name:"Pista"
      },

    ]
    const dispatch = useDispatch();


    

    function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
          width,
          height
      };
      }
  
   const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

   const containerRef = useRef(null);


    useEffect(() => {
      window.scrollTo(0, 0);
          function handleResize() {
          setWindowDimensions(getWindowDimensions());
          }

          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
      }, []);


      useEffect(() => {


        console.log("Products Page =>", Token,Uid)
        setProductList([])


        var data = new FormData();
        data.append('user_id', Uid);
        data.append('token', Token);
        data.append('limit', '12');

          var config = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}api/product-list`,
        
            data : data
          };

  

          axios(config)
          .then(function (response) {
            console.log("PRODUCT LIST",JSON.stringify(response.data.product));
            setProductList(response.data.product)
            setCategoryList(response.data.category)
  
          
          })
          .catch(function (error) {
            console.log(error);

          })
        


      },[all,searchDone,Uid])



    useEffect(() => {
      setContents([])
      if(windowDimensions.width > 768){
        const filter = productList.slice((page-1) * lengthBig , page*lengthBig)
        const pages = productList.length / lengthBig;
        setPaginationNumber(Math.ceil(pages))
        console.log("PAGE =>>>>>",paginationNumber)
        setTimeout(() => {
          setContents(filter)
        },10)
      }else{
        const filter = productList.slice((page-1) * lengthSmall , page*lengthSmall)
        const pages = productList.length / lengthSmall;
        setPaginationNumber(Math.ceil(pages))
        setTimeout(() => {
          setContents(filter)
        },100)
        
      }

     console.log("DATATA",contents)

    },[page,windowDimensions.width,productList.length,all,filter,searchDone])




    useEffect(() => {

      var data = new FormData();
      data.append('name', searchText);
      data.append('token', Token);
      data.append('user_id', Uid);

      var config = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}api/product-search`,
      
        data : data
      };

      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if(response.data.product.length > 0){
          setProductList(response.data.product)
        }
        
      })
      .catch(function (error) {
        console.log(error);
        setSearchDone(!searchDone)
      });


    },[searchText])















    const onclick = () => {
      setPopUp(!popUp)
    }






    const filterItms = ( id ) => {

      setSelectedCategory(id )
      var data = new FormData();
      data.append('category_id', id);
      data.append('token', Token);
      data.append('user_id', Uid);

      var config = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}api/category-filter`,
       
        data : data
      };

      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
 
        setProductList(response.data.product)
        setFilter(!filter)
      })
      .catch(function (error) {
        console.log(error);
      });


    }








    

    const container = {
      hidden: { opacity: 0,x:-10 },
      show: {
        opacity: 1,
        x:[10,0],
        transition: {
          staggerChildren: 0.1,
        }
      }
    };
    
    const listItem = {
      hidden: { opacity: 0 ,x:-30},
      show: { opacity: 1 ,x:[20,0]}
    };














    return (
        <div>
              {(productList.length === 0 || contactUsLoader )&& <Loader />}
            <MainCont
            initial={{opacity:0}}
            animate={{opacity:1}}
            >
            {/* <ProductsTitle>Product</ProductsTitle>
            <HeaderImgCont>
            <ProductImg src={"https://images.unsplash.com/photo-1501746877-14782df58970?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=735&q=80"} />
            </HeaderImgCont> */}
          <AnimatePresence>
          {
            popUp &&  <>
            <PopupMainCont
            whileTap={{opacity:0,scale:1.5}}
            transition={{
              duration:0.3,
            }}
            exit={{opacity:0}}
             onClick={onclick}>
         
             </PopupMainCont>
           
                   <Popups width={props.dimensions.width} onclick={onclick}/>
             
                   </>
          }
             </AnimatePresence>
            <AllProductsHeader>
            <ProductsTitle>All Products</ProductsTitle>
            <InputContainer>
            <InputBox 
            type="text"
             placeholder="Search"
             value={searchText}
             onChange={(event) => {
               setSearchText(event.target.value)
               console.log(event.target.value)
              
              }}
             />
            <InputIcon
            Color={Color}
            >
            <SearchImage 
            whileTap={{scale:0.8}}
             src={search}/>
            </InputIcon>
            </InputContainer>
            </AllProductsHeader>
            {
              productList.length > 16 &&
            
            <PaginationBlock>
              {
                [...Array(paginationNumber)].map((d,i) => {
                  return (
                    <PageBlock
                    Color={Color}
                    whileHover={{scale:0.9 , y:2}}
                    key={i}
                    whileTap={{scale:0.9}}
                    color={ page == i +1 ? Color : "white"}
                    onClick={() => {

                      setPage(i+1)
                     
                     
                      
                    }}
                    >
                      <BlockNumber
                       color={ page == i +1 ?"white" : Color}
                      >
                      {i+1}
                      </BlockNumber>
                    </PageBlock>
                  )
                })
              }
            </PaginationBlock>
              }
            <FilterCont>
            <ScrollContainer
            horizontal={true}
            vertical={false}
            style={{
              display: "flex",
              flexDirection:"row",
            }}>
            <FilterBlock
             style={{ 
              color: selectedCategory === "All"  ? "#fff" : Color,  
              backgroundColor: selectedCategory === "All"   ?  Color : "#fff",
            }}
                  onClick={ () => {setSelectedCategory("All"); setAll(!all);  }}
                  // color={ selectedCategory  ===  "All" ? "#fff" : Color}
                  // whileHover={{scale:0.9 , y:2,backgroundColor:Color,color:"#fff"}}
                  // backgroundColor={ selectedCategory ===  "All" ? Color :"transparent"}
                  >
                    All
            </FilterBlock>
            {
              categoryList.map((d,i) => {
                console.log(selectedCategory,d.id,Number(selectedCategory) == d.id)

                let colorOfText = selectedCategory == d.id   ? "#fff" : Color;
                let background = selectedCategory == d.id   ?  Color : "#fff";

                
                return(
                  <FilterBlock
               
                  key={d.id}
                  onClick={ () => filterItms(d.id)}
                  color={colorOfText}
                  backgroundColor={background}
                  // whileHover={{scale:0.9 , y:2 , backgroundColor:Color,color:"#fff"}}
                  
                  >
                    {d.name}
                  </FilterBlock>
                )
              })
            }
            </ScrollContainer>
            </FilterCont>

            <ContentPagination
            variants={contents.length !== 0 && container} initial="hidden" animate="show"
            >
       
              {
                contents.map((d,i) => {


                  if(d.product_title){
                    // console.log("/*/*/*/*/*/*/*/*/*/*/",d.title.length)
                       var title = ""
  
                  const filter = () => {
  
                    if(d.product_title.length > 30){
                      // console.log("/*/*/*/*/*/*/*/*/*/*/",d.title.length)
                      title = d.product_title.slice(0,30) + "...."
                      // setTitleNews(d.title.slice(0,40) + "....")
                      return title;
                    }else{
                      // setTitleNews(d.title)
                      title = d.product_title;
                      return title;
                    }
    
                  };
                
                   
                  filter();
                  }

                  let replaceSpaceText = d.product_title.toLowerCase().replaceAll(" ","-")
               





                  return (
                    <Link
                    key={i}
                    style={{
                      textDecoration:"none",
                      color: "#000000",
                    }}
                    to={{
                      pathname:`products/${replaceSpaceText}`,
                      state:{
                        id:d.id
                      }
                    }}
                    >
                    <ContentBlock key={i}
                    whileTap={{scale:0.9}}
                    whileHover={{scale:1.05}}
                    variants={listItem}
                
                    
                    // onClick={() => history.push("products/product")}
                    >
                      <ImageBlock
                     
                       src={d.banner_image} />
                      <ContentName>{title}</ContentName>
                    </ContentBlock>
                    </Link>
                  
                  )
                })
                
              }
        
            </ContentPagination>
            </MainCont>
            <Footer onclick={onclick}/>
        </div>
    )
}

export default Products

import React ,{useEffect,useState} from 'react'
import styled from 'styled-components'
import {AnimatePresence, motion} from "framer-motion"


import down from "../Assets/Product/DownArrow.svg"
import up from "../Assets/Product/UpArrow.svg"
import upD from "../Assets/Product/upDisabled.svg"
import downD from "../Assets/Product/downDis.svg"
import right from "../Assets/Product/rightSide.svg"
import left from "../Assets/Product/leftSide.svg"
import rightD from "../Assets/Product/rightSideDis.svg"
import leftD from "../Assets/Product/leftSideDis.svg"
import download from "../Assets/Product/downloadFile.svg"
import Footer from './Footer'
import Popups from './Cards/Popups'
import { useLocation } from 'react-router'
import { PrimaryThemeColor ,PrimaryFont } from './Constants';
import { useSelector } from "react-redux";

import { contactLoader, ThemeColor, token , uid ,settings} from '../ReduxToolkit/useReducer';
import Loader from './Loader'



const MainCont = styled(motion.div)`
width:70%;
margin-bottom: 200px;
display: flex;
margin-top: 100px;
margin-left: auto;
margin-right: auto;
flex-direction: row;
justify-content: space-between;
@media (max-width: 1100px) {
width: 95%;
}
@media (max-width: 768px) {
    width: 90%;
    margin-top: 50px;
    margin-bottom: 50px;
    flex-direction: column;
    max-width: 450px;
}
`;


//! Left Side Hero Section ==========================================================>

const LeftSideCont = styled(motion.div)`
width: 40%;
display: flex;
flex-direction: column;

@media (max-width: 1100px) {
width: 50%;
}
@media (max-width: 768px) {
    width: 100%;
}
`;

const LeftSide = styled(motion.div)`
width: 100%;
display: flex;
flex-direction: row;
@media (max-width: 768px) {
    flex-direction: column-reverse;  
}
`;

const LeftSideImagesCont = styled(motion.div)`
width: 20%;
display: flex;
flex-direction: column;

@media (max-width: 768px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

}
`;

const LeftSideImages = styled(motion.div)`
width: 20%;
display: flex;
flex-direction: column;
min-height:320px;
@media (max-width: 768px) {
    flex-direction: row;
    width: 90%;
    justify-content: space-evenly;
    align-items: center;
    min-height:150px;
}
`;

const LeftSideImage = styled(motion.img)`
width: 92px;
height: 87px;
border-radius: 15px;
margin-top: 10px;
margin-bottom: 10px;
cursor: pointer;
object-fit: cover;

@media (max-width: 768px) {
    width: 70px;
    height: 70px;

}

`;

const ArrowImages = styled(motion.img)`
width: 90px;
cursor: pointer;
@media (max-width: 768px) {
    width: 26px;
    height: 60px;
    margin-top: 10px;
margin-bottom: 10px;
}
`;

const LeftSideFullImg = styled(motion.img)`
width: 75%;
height: 410px;
object-fit: cover;
border-radius: 15px;
margin-left: auto;
@media (max-width: 1366px) {
    width: 70%;
    height: 300px;
}
@media (max-width: 1100px) {
    height: 280px;
}
@media (max-width: 768px) {
    width: 100%; 
    width: 100%;
    height: 320px;
    margin-right: auto;
}
`;

const LeftSideButtonsCont = styled(motion.div)`
display: flex;
margin-left: auto;
justify-content: space-between;
width: 75%;
margin-top: 50px;
@media (max-width: 1366px) {
    margin-top: -60px;
    width: 70%;
}
@media (max-width: 768px) {
    width: 90%;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    bottom: 5px;
    margin-right: auto;
}
`;

const Buttons = styled(motion.button)`
height: 50px;
font-family: ${PrimaryFont};
position: relative;
font-weight: 600;
font-size: 16px;
border-radius: 10px;
border: 2px solid ${prop => prop.Color};
align-content: center;
justify-content: space-between;
align-items:center;
outline: none;
cursor: pointer;
width: 48%;
text-align: center;
color: ${props => props.txtColor} ;
background-color: ${props => props.color};
@media (max-width: 1366px) {
    font-size: 14px;
}
@media (max-width: 768px) {
    width: 100%;
    max-width: 450px;

}
`;
 
//! Right Side Hero Section ==========================================================>

const RightSide = styled(motion.div)`
width: 50%;
display: flex;
flex-direction: column;
@media (max-width: 1100px) {
    width: 40%;
}
@media (max-width: 768px) {
    width: 100%;
}

`;

const RightSideDashed = styled(motion.div)`
display: flex;
border-radius: 10px;
border: 2px dashed #707070;
gap: 20px;
justify-content: space-around;
align-items: center;
height: 86px;
margin-top: 50px;
@media (max-width: 768px) {
    gap: 10px;
}
`;

const ColorCont = styled(motion.div)`
display: flex;
/* justify-content: space-around; */

/* width: 100%; */
`;

const CodeCont = styled(motion.div)`
display: flex;
justify-content: space-between;
`;



const RightSideTitle = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 600;
font-size: 28px;
@media (max-width: 1100px) {
    font-size: 25px;
}
@media (max-width: 768px) { 
    font-size: 18px;
}
`;

const RightSideSubtitle = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 400;
font-size: 21px;
color:#A5A5A5;
margin-top: 30px;
@media (max-width: 1100px) {
    font-size: 18px;
}
@media (max-width: 768px) { 
    font-size: 14px;
}
`;
const Code = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 400;
width: 80%;
font-size: 21px;
margin-top: 30px;
@media (max-width: 1100px) {
    font-size: 18px;
}

@media (max-width: 768px) { 
    font-size: 14px;
    width: 60%;
}

`;

const DashedItem = styled(motion.div)`
display: flex;
flex-direction: column;
justify-content: center; 
align-items: center;
width: 100%;
/* border-left: ${props => props.center ? "2px solid black" : "none"}; */
/* border-left: 2px solid black; */
/* border-right: ${props => props.center ? "2px solid black" : "none"}; */
border-right:${props => props.price ? "none" : "2px solid black"};;

`;

const DahedText = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 500;
font-size: 28px;
@media (max-width: 1100px) {
    font-size: 25px;
}
@media (max-width: 768px) { 
    font-size: 18px;
}
`;

const DahedSubText = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 500;
font-size: 8px;
margin-top: -6px;
@media (max-width: 1100px) {
    font-size: 7px;
}
@media (max-width: 768px) { 
    font-size: 6px;
}
`;

const ColorBlock = styled(motion.div)`
height: 45px;
width: 100px;
display: flex;
border-radius: 15px;
justify-content: center;
align-items: center;
margin-right: 50px;
background-color: ${props => props.color};
@media (max-width: 1100px) {
    width: 80px;
    height: 40px;
    margin-right: 40px;
}
@media (max-width: 768px) { 
    width: 60px;
    height: 30px;
    margin-right: 30px;
}
`;

const SpecsExpand = styled(motion.div)`
display: flex;
flex-direction: column;
overflow: hidden;
`;


const ReadMore = styled(motion.h1)`
text-align: right;
font-family: ${PrimaryFont};
font-weight: 500;
font-size: 14px;
color: ${prop => prop.Color};
cursor: pointer;
`;

const Specs = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 500;
font-size: 14px;
line-height: 23px;
/* height: 48px; */
color: #3B3B3B;
@media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
 }
`;


const SpecSubBlock = styled(motion.div)`
display: flex;
flex-direction: row;
justify-content: space-evenly;
/* border: 2px solid #707070; */
border-bottom: 2px solid #707070;
border-left: 2px solid #707070 ;
border-top: 2px solid #707070 ;
height: 100px;

`;

const SpecifivationBlock = styled(motion.div)`
display: flex;
flex-direction: column;

width: 100%;
`;

const SpecQuantity = styled(motion.div)`
display: flex;
flex-direction: row;
justify-content: space-evenly;
border-bottom: 2px solid #707070;
border-left: 2px solid #707070 ;
/* border-right: 2px solid #707070 ; */
height: 50px;
`;


const SpecsQ = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 400;
font-size: 21px;
color:#A5A5A5;
width: 100%;

text-align: center; 

@media (max-width: 1100px) {
    font-size: 18px;
}
@media (max-width: 768px) {
    font-size: 14px;
    line-height: 14px;
 }
`;


//! Specifications section ==========================================================>


const SpecBlockMain = styled(motion.div)`
width:70%;
margin-bottom: 100px;
display: flex;
/* margin-top: 100px; */
margin-left: auto;
margin-right: auto;
flex-direction: column;
justify-content: space-between;
@media (max-width: 1100px) {
    width: 95%;
    margin-left: auto;
margin-right: auto;
}
@media (max-width: 768px) {
    width: 90%;
    margin-top: 50px;
    margin-bottom: 50px;
    flex-direction: column;
    max-width: 450px;
}
`;


const SpecificationTitle = styled(motion.h1)`
font-family: 'Open Sans', sans-serif;
font-weight: 600;
font-size: 46px;
line-height: 29px;
margin-left: auto;
margin-right: auto;
margin-bottom: 100px;
@media (max-width: 768px) { 
    font-size: 21px;
    margin-bottom: 50px;
}
`;

const SpecBlock = styled(motion.div)`
display: grid;
grid-template-columns: 1fr 1fr;
/* flex-direction: column; */
flex-wrap: wrap;
/* height: 40vh; */
width: 100%;
gap: 20px;
/* margin-top: 50px; */
/* margin-bottom: 50px; */

@media (max-width: 768px) { 
    /* height: auto;
    flex-wrap: no-wrap; */
    grid-template-columns: 1fr;

}

`;

const SpecSubTextBlock = styled(motion.div)`
display: flex;
flex-direction: row;
justify-content: center;

`;

const SpecificationText = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: ${props => props.weight};
font-size: 18px;
color:${props => props.color};
margin-left: ${props => props.left ? "20px" : "0px"};
margin-top: 15px;
margin-bottom: 15px;
width: ${props => props.left ? "70%" : "30%"};;

@media (max-width: 1100px) {
    font-size: 16px;
}
@media (max-width: 768px) { 
    font-size: 13px;
}
`;




//! Youtube Video ==========================================================>

const YoutubeBlock = styled(motion.div)`
display: flex;
width: 100%;
flex-direction: column;
margin-left: auto;
margin-right: auto;

@media (max-width: 768px) { 
    /* width: 80%; */
    /* margin-left: auto;
margin-right: auto; */
}
@media (max-width: 320px) { 
    /* width: 100%; */
}
`;

const YoutubeBlockSub = styled(motion.div)`
display: flex;
flex-direction: column;
margin-left: auto;
margin-right: auto;

`;
const Youtube = styled(motion.iframe)`

outline: none;
border: none;
border-radius: 15px;
height: 340px;
width: 670px;
margin-left: auto;
margin-right: auto;


@media (max-width: 768px) { 
    height: 200px;
    width: 300px;

}
`;

const YoutubeText = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 600;
font-size: ${props => props.size};
color:${props => props.color};
text-align: center;
margin-top: 10px;

@media (max-width: 768px) { 
    font-size: ${props => props.sizeSmall};

}
`;

//! Supply ==========================================================>


const SupplyCont = styled(motion.div)`
display: flex;
flex-direction: column;

`;

const SupplyContSub = styled(motion.div)`
display: flex;
flex-direction: row;
width: 100%;

@media (max-width: 768px) { 
    flex-direction: column;
}
`;

const SupplyChainTitle = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 600;
font-size: 46px;
margin-top:60px;
@media (max-width: 1100px) {
    font-size: 36px;
}
@media (max-width: 768px) { 
font-size: 21px;
}
`;

const SupplyChainText = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: ${props => props.weight};
font-size: 20px;
color:#8D8D8D;
 /* margin-left: ${props => props.left ? "60px" : "0px"}; */
 width: ${props => props.left ? "80%" : "20%"};
 /* background-color: #8D8D8D; */
margin-top: 15px;
margin-bottom: 15px; 
@media (max-width: 1100px) {
    font-size: 17px;
}
@media (max-width: 768px) { 
    font-size: 14px;
    width: 100%;
}
`;



const SupplyBoxText = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: ${props => props.weight};
font-size: 20px;
text-align: center;
color:#8D8D8D;
@media (max-width: 1100px) {
    font-size: 17px;
}
@media (max-width: 768px) { 
    font-size: 10px;
}
`;

const DownloadBtn = styled(motion.a)`
 font-family: ${PrimaryFont};
 display: flex;
font-weight: 600;
font-size: 22px;
margin-top: 50px;
text-align: right;
background-color: ${prop => prop.Color};
color: #fff;
width:30%;
border-radius: 15px;
align-content: center;
justify-content: space-around;
align-items:center;
height: 52px;
outline: none;
border: none;
cursor: pointer;
list-style: none;
text-decoration: none;
text-align: center;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
@media (max-width: 1100px) {
    font-size: 19px;
   
}
@media (max-width: 768px) {
width: 90%;
margin-left: auto;
margin-right: auto;
margin-top: 20px;
font-size: 15px;

}
`;

//! Suggested for you ==========================================================>

const SuggestedTitle = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 600;
font-size: 46px;
@media (max-width: 1100px) {
    font-size: 36px;
}
@media (max-width: 768px) { 
font-size: 21px;
}
`;

const SuggestedProduct = styled(motion.div)`
/* display: flex;
align-items: center;
justify-content: center; */
flex-wrap: nowrap;
overflow: hidden;
/* background-color: #85c6ff; */
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;

@media (max-width: 768px) { 
    /* flex-wrap: wrap; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
}
`;

const SuggestedItemBlock = styled(motion.div)`
display: flex;
flex-direction: column;
align-items: center;
justify-content:center;
margin-right: 100px;
/* width: 50%; */
/* margin-top: 50px; */
height: 300px;
padding: 10px;
margin-top: 20px;
cursor: pointer;

/* background-color: aliceblue; */

@media (max-width: 1366px) {
    margin-right: 60px;
}

@media (max-width: 1100px) {
    margin-right: 40px;
}

@media (max-width: 768px) { 
    margin-top: 10px;

    margin-left: auto;
margin-right: auto;
}
`;


const SuggestedItemText = styled(motion.h1)`
font-family: ${PrimaryFont};
font-weight: 600;
font-size: 21px;
margin-top: 10px;

@media (max-width: 1100px) {
    font-size: 24px;
}
@media (max-width: 768px) { 
font-size: 11px;
}
`;


const SuggestedItem = styled(motion.img)`
width: 100%;
height: 70%;
border-radius: 15px;
object-fit: cover;
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.41);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.41);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.41);
/* @media (max-width: 1366px) {
    height: 165px;
    width: 175px;
} */

@media (max-width: 768px) { 
    height: 100px;
width: 100px;
}
`;


const PopupMainCont = styled(motion.div)`
display: flex;
justify-content: center;
align-items: center;
height: 120vh;
width: 100%;
position: absolute;
background-color: #000000d1;
z-index:100;
top: 0;
left:0;
position: fixed;
backdrop-filter: blur(4px);

`;




function Product(props) {

    

    const [sidebarArray , setSidebarArray] = useState([])
    const [initial ,setInitial] = useState(0)
    const [number ,setNumber] = useState(3)
    const [readMore ,setReadMore] = useState(false)
    const [path , setPath] = useState("")
    const Color = useSelector(ThemeColor)
    const Uid = useSelector(uid)
    const Token = useSelector(token)
    const contactUsLoader = useSelector(contactLoader)
    const settingsData = useSelector(settings);
    const [popUp , setPopUp] = useState(false);
    const [getQuotes , setGetQuotes] = useState(false);
    const [addDetails , setAddDetails] = useState(false);


    const [productDetails , setProductDetails] = useState(null)
    const [productOtherImg , setProductOtherImg] = useState([])
    const [suggested , setSuggested] = useState(null)
    const [categoryList , setCategoryList] = useState(null)
    const [price , setPrice] = useState(null)
    const [colors , setColors] = useState(null)
    const [size , setSize] = useState(null)
    const [specification , setSpecification] = useState(null)
    const [loadTime , setLoadTime] = useState(null)
   

    const location = useLocation()
    const pId = location.state.id;
   

    const [id ,setId] = useState(pId)



    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
        }
    
     const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
  
  
      useEffect(() => {
        window.scrollTo(0, 0);
       
            function handleResize() {
            setWindowDimensions(getWindowDimensions());
            }
  
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);


        useEffect(() => {
            window.scrollTo(0, 0);
            var axios = require('axios');
            var FormData = require('form-data');
            var data = new FormData();
            data.append('product_id', id);
            data.append('token', Token);
            data.append('user_id', Uid);
            
            var config = {
              method: 'post',
              url: `${process.env.REACT_APP_BASE_URL}api/product-detail`,
         
              data : data
            };
            
            axios(config)
            .then(function (response) {
                document.title = settingsData.company_name ? `${settingsData.company_name} / ${response.data.product.product_title}`: "";
                // document.title = "Mangesh Export | " + response.data.product.product_title;
              console.log("PRODUCT DETAILS +>>>>>>>",response.data);
              console.log(response.data.product.price_piece,"PRICE API")
              setProductDetails(response.data.product)
              setProductOtherImg(response.data.productotherimage)
              setPath(response.data.product.banner_image)
              setSuggested(response.data.suggested_product)
             
              console.log(JSON.parse(null), "HELLLLLLLLLLLLLLLLLL")
              setPrice(JSON.parse(response.data.product.price_piece))
              setSpecification( JSON.parse(response.data.product.specification))
              setSize(JSON.parse(response.data.product.multi_size))
              setLoadTime(JSON.parse(response.data.product.load_time))
              setColors(JSON.parse(response.data.product.multi_color))
              
           
              
              console.log("SIZEEEE =<><><><><><><>=",response.data.product.multi_color)
         
            })
            .catch(function (error) {
              console.log(error);
            });





            
        },[id])

  


        const SpecBlockComponent = (props) => {
            return (
                <SpecSubTextBlock>
                <SpecificationText  weight="600" color="black"
                >{props.title}</SpecificationText>
                 <SpecificationText weight="400" color="#8D8D8D"
                 left
                >{props.value}</SpecificationText>
            </SpecSubTextBlock>
            )
        }




    const mangos = [
        {
            name:"mango3",
          src:"https://images.unsplash.com/photo-1551649001-9c071b61f26c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
        },
        {
            name:"mango4",
          src:"https://images.unsplash.com/photo-1601493700631-2b16ec4b4716?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80"
        },
        {
            name:"mango5",
            src:"https://images.unsplash.com/photo-1501746877-14782df58970?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=735&q=80"
          },
      
          {name:"mango5",
            src:"https://images.unsplash.com/photo-1551649001-9c071b61f26c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
          },
          {name:"mango5",
            src:"https://images.unsplash.com/photo-1601493700631-2b16ec4b4716?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80"
          },
          {name:"mango5",
            src:"https://images.unsplash.com/photo-1582655299221-2b6bff351df0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=881&q=80"
          },
         
      ]

      useEffect(() => {
        // const filter = mangos.slice(initial , number)
        const filter = productOtherImg.length > 0 && productOtherImg.slice(initial , number)
        setSidebarArray(filter)
      },[initial,number,productOtherImg])


      const onclick = () => {
        setPopUp(!popUp)
      }
      

    return (
        <div>
            {(productDetails === null ||  contactUsLoader) && <Loader />}
            <MainCont>
            <AnimatePresence>
          {
            popUp &&  <>
            <PopupMainCont
            whileTap={{opacity:0,scale:1.5}}
            transition={{
              duration:0.3,
            }}
            exit={{opacity:0}}
             onClick={onclick}>
         
             </PopupMainCont>
                {
                    addDetails ?  <Popups width={windowDimensions.width} onclick={onclick}/> :   <Popups product={true} id={productDetails.id} getQuotes={getQuotes} width={windowDimensions.width} onclick={onclick}/>
                }
                  
             
                   </>
          }
             </AnimatePresence>
                <LeftSideCont>
                <LeftSide>
                    <LeftSideImagesCont>
                        {initial === 0 ?

                            windowDimensions.width < 768 
                            ?
                            <ArrowImages 
                                
                            src={leftD} />
                            :
                              <ArrowImages 
                                
                              src={upD} />
                        :
                        windowDimensions.width < 768 
                        ?
                        <ArrowImages 
                        whileTap={{scale:0.8}}
                        onClick={() => {
                            if(initial != 0) {
                            setInitial(curr => curr -3)
                            setNumber(curr => curr - 3)
                            }
                        }}
                        src={left} />
                        :
                        <ArrowImages 
                        whileTap={{scale:0.8}}
                        onClick={() => {
                            if(initial != 0) {
                            setInitial(curr => curr -3)
                            setNumber(curr => curr - 3)
                            }
                        }}
                        src={up} />
                    }
                   <LeftSideImages>

                        {
                            
                         sidebarArray.length > 0 && sidebarArray.map((d,i) => {
                                return (
                              <LeftSideImage
                              whileTap={{scale:0.9}}
                              onClick={() => {
                                  setPath(d)
                              }}
                               key={i} src={d} />
                                )
                            })
                
                        }
                                               
                   </LeftSideImages>
                        {number < productOtherImg.length
                        ?

                        windowDimensions.width < 768 
                        ?
                        <ArrowImages 
                        whileTap={{scale:0.8}}
                        onClick={() => {
                            if(number < productOtherImg.length) {
                          setInitial(curr => curr + 3)
                          setNumber(curr => curr + 3)
                            }
                      }}
                       src={right}/>

                       :
                       <ArrowImages 
                       whileTap={{scale:0.8}}
                       onClick={() => {
                           if(number < productOtherImg.length) {
                         setInitial(curr => curr + 3)
                         setNumber(curr => curr + 3)
                           }
                     }}
                      src={down}/>


                        :
                        windowDimensions.width < 768 
                        ?
                        <ArrowImages 
                   
                         src={rightD}/>
                        :
                         <ArrowImages 
                   
                         src={downD}/>
                    }
                    </LeftSideImagesCont>
                    
                    <LeftSideFullImg
                    initial={{opacity:0,x:-100}}
                    animate={{opacity:1,x:0}}
                    
                     src={path}/>
                 
                </LeftSide>
                <LeftSideButtonsCont>
                    <Buttons 
                    Color={Color}
                    whileTap={{scale:0.8}}
                    onClick={() => {
                        setGetQuotes(true)
                        setAddDetails(false)
                        onclick()
                        }} color="white" txtColor={Color}>
                        Get Quotes
                    </Buttons>
                    <Buttons 
                    Color={Color}
                 
                    whileTap={{scale:0.8}}
                   
                    onClick={() => {
                        setGetQuotes(false)
                        setAddDetails(false)
                        onclick()
                        }} color={Color} txtColor="white">
                            
                        Order Sample
                     
                    </Buttons>
                </LeftSideButtonsCont>
                </LeftSideCont>
                <RightSide>
                    <RightSideTitle>{productDetails != null && productDetails.product_title}</RightSideTitle>
                    <CodeCont>
                    <RightSideSubtitle>Hsn Code</RightSideSubtitle>
                    <Code>{productDetails != null && productDetails.hsn_code}</Code>
                    </CodeCont>
                    
                    {price != null  &&
                    <RightSideDashed>
                        { price.length > 0 && price.map((d,i) => {
                            console.log(i,"PRICE")
                            return (
                                <DashedItem
                                key={i}
                                price={true}
                                >
                                <DahedText>{d.price}</DahedText>
                                <DahedSubText>{d.piece} Pieces</DahedSubText>
                                </DashedItem>
                            )
                        })
                       
                    }
                    {/* <DashedItem>
                        <DahedText>₹ 94</DahedText>
                        <DahedSubText>3000 - 19999 Pieces</DahedSubText>
                    </DashedItem>
                    <DashedItem>
                        <DahedText>₹ 54</DahedText>
                        <DahedSubText>3000 - 19999 Pieces</DahedSubText>
                    </DashedItem>
                    <DashedItem>
                        <DahedText>₹ 34</DahedText>
                        <DahedSubText>3000 - 19999 Pieces</DahedSubText>
                    </DashedItem> */}
                    </RightSideDashed>}
                    { productDetails != null &&  Number(productDetails.is_display_multi_color) !== 0 &&
                    colors != null && 
                    <>
                    
                    <RightSideSubtitle>Color</RightSideSubtitle>
                   
                    <ColorCont>
                    {colors.map((d,i) => {
                    
                            return (
                                <ColorBlock key={i} color={d.c1} />
                            )
                        })}
                    {/* <ColorBlock color="#FF4D4D" />
                    <ColorBlock color="#9E00BA" />
                    <ColorBlock color="#3900BA" />
                    <ColorBlock color="#00ACBA" /> */}
                    </ColorCont>
                    
                    </>}
                    { productDetails != null &&  Number(productDetails.is_display_multi_size) !== 0 && size !== null &&
                    <>
                    <RightSideSubtitle>Size</RightSideSubtitle>
                    <ColorCont>
                    { size.map((d,i) => {
                            return (
                                <ColorBlock color="#E6E6E6"  key={i}>{d.s1}</ColorBlock>
                            )
                        })}
                    {/* <ColorBlock color="#E6E6E6" >1</ColorBlock>
                    <ColorBlock color="#E6E6E6" >5</ColorBlock>
                    <ColorBlock color="#E6E6E6" >4</ColorBlock>
                    <ColorBlock color="#E6E6E6" >8</ColorBlock> */}
                 
                    </ColorCont>
                    </>}
                    <RightSideSubtitle>Specification</RightSideSubtitle>
                    <SpecsExpand>
                    <Specs
                    initial={{height:"50px"}}
                    animate={readMore ? {height:"auto"} : windowDimensions.width < 768 ? {height:"20px"} :{height:"50px"}}
                    >
                    {productDetails != null && productDetails.specification_1}
                    </Specs>
                  
                    </SpecsExpand>
                    <ReadMore
                    Color={Color}
                    onTap={{scale:0.8}}
                    onClick={() => setReadMore(!readMore)}
                    >
                        {readMore ? "Read Less" : "Read More"}
                    </ReadMore>
                    
                    {/* <RightSideSubtitle>Specification</RightSideSubtitle>
                    <SpecifivationBlock>
                    <SpecSubBlock>
                    <DashedItem>
                        <DahedText>₹ 34</DahedText>
                        <DahedSubText>3000 - 19999 Pieces</DahedSubText>
                    </DashedItem>
                    <DashedItem center>
                        <DahedText>₹ 34</DahedText>
                        <DahedSubText>3000 - 19999 Pieces</DahedSubText>
                    </DashedItem>
                    <DashedItem >
                        <DahedText>₹ 34</DahedText>
                        <DahedSubText>3000 - 19999 Pieces</DahedSubText>
                    </DashedItem>
                    </SpecSubBlock>
                    <SpecQuantity>
                     
                    <DashedItem>

                    <SpecsQ>Quantity</SpecsQ>
                    </DashedItem>
                    <DashedItem center>

                    <SpecsQ >Quantity</SpecsQ>
                    </DashedItem>
                    <DashedItem>

                    <SpecsQ>Quantity</SpecsQ>
                    </DashedItem>
              

          
               
              
                    </SpecQuantity>
                    </SpecifivationBlock> */}

                </RightSide>
             
            </MainCont>
            {specification !== null &&
            <SpecBlockMain>
               
            <SpecificationTitle>Specification</SpecificationTitle>
            <SpecBlock>
                {
                     specification.map((d,i) => {
                        return (
                            <SpecBlockComponent  key={i} title={d.title} value={d.value}/>
                        )
                    })
                }
                   {/* <SpecBlockComponent />
                   <SpecBlockComponent />
                   <SpecBlockComponent />
                   <SpecBlockComponent />
                   <SpecBlockComponent />
                   <SpecBlockComponent />
                   <SpecBlockComponent />
                   <SpecBlockComponent />
                   <SpecBlockComponent />
                   <SpecBlockComponent />
                   <SpecBlockComponent /> */}
            </SpecBlock>
            </SpecBlockMain>}
            {
                productDetails != null && productDetails.video_url !== null && 
            
            <SpecBlockMain>
                <YoutubeBlock>
                <YoutubeBlockSub>
            <Youtube  src={productDetails != null && productDetails.video_url} title="YouTube video player"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></Youtube>
            <YoutubeText size="32px" sizeSmall="18px" color="black">
            {productDetails != null && productDetails.product_title} Review
            </YoutubeText>
            <YoutubeText size="21px" sizeSmall="14px" color="#8D8D8D">
            {productDetails != null && productDetails.product_title} Review
            </YoutubeText>
            </YoutubeBlockSub>
            </YoutubeBlock>
            </SpecBlockMain>
}



            <SpecBlockMain>


            <SupplyCont>
                <SupplyChainTitle>Supply Ability</SupplyChainTitle>
                    <SupplyContSub>
                    <SupplyChainText weight="600">Supply ability</SupplyChainText>
                    <SupplyChainText left weight="500" >{productDetails != null && productDetails.supply_ability}</SupplyChainText>
                    </SupplyContSub>
                    <SupplyChainTitle>Packaginng And Delivery</SupplyChainTitle>
                    <SupplyContSub>
                    <SupplyChainText weight="600">Packaging Details</SupplyChainText>
                    <SupplyChainText left weight="500" >{productDetails != null && productDetails.package_details}</SupplyChainText>
                    </SupplyContSub>
                    <SupplyContSub>
                    <SupplyChainText weight="600">Port</SupplyChainText>
                    <SupplyChainText left weight="500" >{productDetails != null && productDetails.port}</SupplyChainText>
                    </SupplyContSub>
                    <SupplyContSub>
                    <SupplyChainText weight="600">Load Time</SupplyChainText>
                    <SpecifivationBlock>
                    <SpecSubBlock>

                        
                    <DashedItem>
                      <SupplyBoxText weight="700">Quantity(Pieces)</SupplyBoxText>
                    </DashedItem>
                    {loadTime != null && loadTime.map((d,i) => {
                            return (
                                <DashedItem key={i} >
                                <SupplyBoxText weight="500">{d.quantity}</SupplyBoxText>
                             </DashedItem>
                            )
                        })}
            

                    </SpecSubBlock>
                    <SpecQuantity>
                     
                    <DashedItem>

                    <SupplyBoxText weight="700">Est.  Time(Days)</SupplyBoxText>
                    </DashedItem>
                    {loadTime != null && loadTime.map((d,i) => {
                            return (
                                <DashedItem key={i} >
                                <SupplyBoxText weight="500">{d.time}</SupplyBoxText>
                             </DashedItem>
                            )
                        })}
                    {/* <DashedItem center={true}>

                    <SupplyBoxText weight="500">3</SupplyBoxText>
                    </DashedItem>

                    <DashedItem>
                    <SupplyBoxText weight="500">To be negotiated</SupplyBoxText>
                    </DashedItem>
                    <DashedItem>
                    <SupplyBoxText weight="500">To be negotiated</SupplyBoxText>
                    </DashedItem> */}
              
              

          
               
              
                    </SpecQuantity>
                    </SpecifivationBlock>
                    </SupplyContSub>
                
            </SupplyCont>
            {productDetails !== null && productDetails.brochure !== null &&
            <DownloadBtn
            onClick={() => console.log(productDetails.brochure)}
                // { <a href={productDetails.brochure} target="_blank"/>}}
            Color={Color}
            whileTap={{scale:0.98}}
            href={productDetails != null && productDetails.brochure}
            target="_blank"
             >
            Download Brochure
            <img
               style={{
           height: 25,
           width:25,
               }}
                src={download} alt="View all button icon"/>
            </DownloadBtn>}
            </SpecBlockMain>
            <SpecBlockMain>
                <SuggestedTitle>
                    Suggested Product
                </SuggestedTitle>
                <SuggestedProduct>
                    {
                        suggested != null && suggested.map((d,i) => {

                            if(d.product_title){
                                // console.log("/*/*/*/*/*/*/*/*/*/*/",d.title.length)
                                   var title = ""
              
                              const filter = () => {
              
                                if(d.product_title.length > 30){
                                  // console.log("/*/*/*/*/*/*/*/*/*/*/",d.title.length)
                                  title = d.product_title.slice(0,30) + "...."
                                  // setTitleNews(d.title.slice(0,40) + "....")
                                  return title;
                                }else{
                                  // setTitleNews(d.title)
                                  title = d.product_title;
                                  return title;
                                }
                
                              };
                            
                               
                              filter();
                              }
                            return(
                                <>
                                {windowDimensions.width > 800 ?
                                i < 4 && 
                                
                                <SuggestedItemBlock
                                onClick={() => setId(d.id)}
                                whileHover={{scale:0.9,y:2}}
                                >
                                <SuggestedItem src={d.banner_image} />
                               <SuggestedItemText>
                                {title}
                               </SuggestedItemText>
                            </SuggestedItemBlock>
                            :
                            <SuggestedItemBlock
                            onClick={() => setId(d.id)}
                            >
                            <SuggestedItem src={d.banner_image} />
                           <SuggestedItemText>
                            {title}
                           </SuggestedItemText>
                        </SuggestedItemBlock>
                                }
                              
                                </>
                            )
                        })
                    }
                </SuggestedProduct>
            </SpecBlockMain>
            <Footer onclick={() => {
                setAddDetails(true)
                onclick()
            }}/>
        </div>
    )
}

export default Product

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import insta from '../../Assets/SocialIcons/insta.png';
import fb from '../../Assets/SocialIcons/fb.svg';
import gmail from '../../Assets/SocialIcons/gmail.png';
import linkedin from '../../Assets/SocialIcons/linkedin.png';
import wp from '../../Assets/SocialIcons/whatsapp.png';
import { PrimaryThemeColor, PrimaryFont } from '../Constants';

import { useSelector } from 'react-redux';

import {
	settings,
	ThemeColor,
	token,
	uid,
} from '../../ReduxToolkit/useReducer';

const MainCont = styled(motion.div)`
	width: 40%;
	height: 500px;
	border-radius: 30px;
	background-color: ${(props) => props.color};
	display: flex;
	flex-direction: column;
	/* align-content: center;
justify-content: center; */
	padding-left: 10px;
	padding-right: 10px;
	/* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
	margin-top: 50px;
	margin-bottom: 50px;

	@media (max-width: 1366px) {
		width: 45%;
		height: 400px;
	}

	@media (max-width: 768px) {
		width: 90%;
		margin-bottom: 0px;
		padding-bottom: 40px;
		height: ${(props) => (props.color == '#000' ? `${150}px` : `${350}px`)};
		margin-top: ${(props) => (props.color == '#000' ? `${0}px` : `${50}px`)};
	}
`;

const SubComp = styled(motion.div)`
	display: flex;
	justify-content: space-around;
`;

const MapComp = styled(motion.div)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	margin-top: 30px;
	cursor: pointer;
	@media (max-width: 768px) {
		margin-top: 15px;
	}
`;

const Image = styled(motion.img)`
	height: 60px;
	width: 60px;

	@media (max-width: 1366px) {
		height: 50px;
		width: 50px;
	}

	@media (max-width: 768px) {
		height: 35px;
		width: 35px;
	}
`;

const HeadTitle = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 700;
	font-size: 33px;
	color: ${(props) => props.color};
	text-align: center;
	margin-top: 40px;
	margin-bottom: 60px;

	@media (max-width: 1366px) {
		font-size: 25px;
		margin-bottom: 40px;
	}

	@media (max-width: 768px) {
		font-size: 22px;
		margin-bottom: 40px;
	}
`;

const Titles = styled(motion.h1)`
	font-family: ${PrimaryFont};
	font-weight: 400;
	font-size: 18px;
	color: #5c5c5c;

	@media (max-width: 1366px) {
		font-size: 14px;
	}

	@media (max-width: 768px) {
		font-size: 10px;
	}
`;

function SocialIcons(props: any) {
	const settingsData = useSelector(settings);

	const social = [
		{
			title: 'instagram',
			path: insta,
			value: settingsData.instagram,
		},
		{
			title: 'facebook',
			path: fb,
			value: settingsData.facebook,
		},
		{
			title: 'whatsapp',
			path: wp,
			value: settingsData.phone1,
		},
	];

	const seconRow = [
		{
			title: 'linkedin',
			path: linkedin,
			value: settingsData.linkdin,
		},
		{
			title: 'email',
			path: gmail,
			value: settingsData.master_email,
		},
	];

	return (
		<MainCont color={props.isNav ? '#000' : '#fff'}>
			{!props.isNav && (
				<HeadTitle color={props.isNav ? '#fff' : '#000'}>
					CONNECT US EASILY
				</HeadTitle>
			)}
			<SubComp>
				{social.map((data, i) => {
					return (
						<MapComp
							onClick={() => {
								if (data.title == 'instagram') {
									window.open(data.value);
								} else if (data.title == 'facebook') {
									window.open(data.value);
								} else {
									window.open(
										'https://api.whatsapp.com/send?phone=91' + data.value,
									);
								}
							}}
						>
							<Image
								whileHover={{ scale: 0.8 }}
								src={data.path}
								alt={data.title}
							/>
							<Titles>{data.title}</Titles>
						</MapComp>
					);
				})}
			</SubComp>
			<SubComp
				style={{
					width: '70%',
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
			>
				{seconRow.map((data, i) => {
					return (
						<MapComp
							onClick={() => {
								if (data.title == 'email') {
									window.open(
										`mailto:${data.value}?subject=Subject&body=Body%20goes%20here`,
									);
								} else if (data.title == 'linkedin') {
									window.open(data.value);
								}
							}}
						>
							<Image
								whileHover={{ scale: 0.8 }}
								src={data.path}
								alt={data.title}
							/>
							<Titles>{data.title}</Titles>
						</MapComp>
					);
				})}
			</SubComp>
		</MainCont>
	);
}

export default SocialIcons;

import React ,{useState , useEffect} from 'react'
import styled from 'styled-components'
import { motion} from "framer-motion"
import { useSelector } from "react-redux";

import { ThemeColor, token , uid, mainLogo,about, settings } from '../ReduxToolkit/useReducer';
import { PrimaryFont } from './Constants';
import Lottie from 'react-lottie';
import insta from "../Assets/SocialIcons/insta.png"
import call from "../Assets/SocialIcons/accept-call.png"
import fb from "../Assets/SocialIcons/fb.svg"
import gmail from "../Assets/SocialIcons/gmail.png"
import linkedin from "../Assets/SocialIcons/linkedin.png"
import wp from "../Assets/SocialIcons/whatsapp.png" 
import rocketFlying from '../Assets/rocketflying.json'
import moment from 'moment';
import Helmet from "react-helmet"

const MainCont = styled(motion.div)`
width: 80%;
/* margin-top:100px; */
margin-left:auto;
margin-right:auto;
display: flex;

flex-direction: row;
height:100vh;
background-color:#ffffff; 

@media (max-width: 1366px) {
    width: 90%;
}
@media (max-width: 1100px) {
    width: 95%;
}

@media (max-width: 900px) {
    flex-direction: column-reverse;
    width: 90%;
    height:100%;
    margin-top:50px;
}

`

const ImageCont= styled(motion.div)`
position: relative;
display: flex;
justify-content:flex-end;
align-items:center;
align-content:center;
width: 60%;
/* padding-right: 50px; */
/* height: 600px; */
/* background-color:#000000;  */
/* border-radius: 50px; */
/* border-top-right-radius: 50px;
border-bottom-right-radius: 50px; */

@media (max-width: 900px) {
    
    width: 100%;
}

`;


const ContentCont= styled(motion.div)`
display: flex;
justify-content:center;
flex-direction:column;
align-items:center;
align-content:center;
width: 60%;
/* height: 600px; */
/* background-color:#131313;  */
@media (max-width: 900px) {
    /* flex-direction: column; */
    width: 100%;
}
`;

const ComingSoonText= styled(motion.h1)`
    font-family: ${PrimaryFont};
    font-size:45px;
    font-weight:700;
    margin-top:40px;
    color:#131313;
    @media (max-width: 1366px) {
        font-size:35px;
    }
    @media (max-width: 1100px) {
        font-size:30px;
        
    }
    @media (max-width: 768px) {  
        font-size:20px;
        margin-top:20px;
    }

`;
const ComingSoonDescription= styled(motion.h1)`
    font-family: ${PrimaryFont};
    font-size:18px;
    font-weight:400;
    margin-top:80px;
    text-align:center;
    color:#131313;
    @media (max-width: 1366px) {
        font-size:15px;
    }
    @media (max-width: 1100px) {
            font-size:12px;
        }
        @media (max-width: 768px) {  
        font-size:10px;
        margin-top:40px;
    }
`;
const ComingSoonNumber= styled(motion.h1)`
    font-family: ${PrimaryFont};
    font-size:40px;
    font-weight:700;
    /* margin-top:20px; */
    color:${prop => prop.Color};

    @media (max-width: 1366px) {
        font-size:30px;
    }
    @media (max-width: 1100px) {
        font-size:25px;
    }
    @media (max-width: 768px) {  
        font-size:23px;
        
    }

`;

const MapComp = styled(motion.div)`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
align-content: center;
margin-top: 30px;

cursor:pointer;
@media (max-width: 768px) {
    margin-top: 15px;
}
`;

const SubComp = styled(motion.div)`
display: flex;
justify-content: space-between;
/* background-color:#131313; */
width:100%;
`;

const Image = styled(motion.img)`
height:40px;
width: 40px;
margin-right:30px;


@media (max-width: 1366px) {
    margin-right:10px;
 height:30px;
width: 30px;
  }


@media (max-width: 768px) {
height:25px;
width: 25px;
}

`;

const Titles = styled(motion.h1)`
  font-family: ${PrimaryFont};
font-weight: 400;
font-size: 15px;
color: #5C5C5C;

@media (max-width: 1366px) {
    font-size: 14px;
 }


@media (max-width: 768px) {
    font-size: 6px;
}
`;

const ComingSoonTitle= styled(motion.h1)`
    font-family: ${PrimaryFont};
    font-size:20px;
    font-weight:500;
    color:${prop => prop.Color};
    /* margin-top:5px; */
    @media (max-width: 768px) {  
        font-size:10px;
        
    }
`;

    const LogoImg = styled(motion.img)`
    width: 150px;
    @media (max-width: 768px) {
        width: 100px;
        /* margin-top: 50px; */
    }
`;


function ComingSoon(props) {

  

    const Color = useSelector(ThemeColor)
    const logoOfBusiness = useSelector(mainLogo)
    const aboutData = useSelector(about)
    const [days ,setDays] = useState("00")
    const [hours ,setHours] = useState("00")
    const [minutes ,setMinutes] = useState("00")
    const [seconds ,setSeconds] = useState("00")

    const settingsData = useSelector(settings)


    const social = [
        {
            title:"instagram",
            path:insta,
            value:settingsData.instagram,
        },
        {
            title:"facebook",
            path:fb,
            value:settingsData.facebook,
        },
        {
            title:"whatsapp",
            path:call,
            value:settingsData.phone1,
        },
        {
            title:"linkedin",
            path:linkedin,
            value:settingsData.linkdin,
        },
        {
            title:"email",
            path:gmail,
            value:settingsData.master_email,
        },
      
    ]


    console.log(moment(settingsData.releasing_on).format("DD MMM YYYY, h:mm:ss"))

    const dateTime = moment(settingsData.releasing_on).format("DD MMM YYYY, h:mm:ss")

  
    var countDownDate = new Date(dateTime).getTime();

// Update the count down every 1 second
    var x = setInterval(function() {

  // Get today's date and time
    var now = new Date().getTime();
    
  // Find the distance between now and the count down date
    var distance = countDownDate - now;

    console.log("DATATA",distance , countDownDate ,now)
    
  // Time calculations for days, hours, minutes and seconds
  if(distance < 0){
    setDays("00")
    setHours("00")
    setMinutes("00")
    setSeconds("00");
    clearInterval(x);
  }else{

  
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    setDays(days)
    setHours(hours)
    setMinutes(minutes)
    setSeconds(seconds);
  }

    
       
  // If the count down is over, write some text 
           
                    
    }, 1000);


    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: rocketFlying,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    }

    document.getElementById("icon").href = logoOfBusiness;

    const ReverseTimeCont = (props) => {
        console.log(props.time !== NaN)
        return (
            <div
            style={{
                display: 'flex',
                flexDirection: "column",
                justifyContent:"center",
                alignItems: "center",
                alignContent:"center",
                paddingLeft: "20px",
                paddingRight: "20px",
                // backgroundColor:"blue",
            }}
            >
            <ComingSoonNumber
             initial={{opacity:0.8,y:[3]}}
             animate={{opacity:1,y:[-3,5]}}
             Color={Color}>{props.time}</ComingSoonNumber> 
            <ComingSoonTitle
         
             animate={{scale:0.99}}
             Color={Color} >{props.title}</ComingSoonTitle>
            </div>
        )
    }
    

   



    return (
        <div
        style={{
            display: 'flex',
            flex: 1,
            backgroundColor:"#ffffff",
            overflow:props.dimensions.width < 800  ? "none" : "hidden",
        }}
        >
           <Helmet> 
                <title>
                {settingsData && settingsData.company_name}
                </title>
            </Helmet>
        <MainCont>
         <ImageCont
         initial={{opacity:0,y:500}}
         animate={{opacity:1,y:[-300,100,0]}}
         >
            <Lottie 
          options={defaultOptions}
          height={props.dimensions.width < 800 ? props.dimensions.height /3 : props.dimensions.height }
          width={props.dimensions.width < 800 ? props.dimensions.width : props.dimensions.width / 1.5}
          style={{
             position:props.dimensions.width < 900 ? "relative" : 'absolute',
            //  display: props.dimensions.width < 800 && "none",
            minHeight:200,
            maxHeight:props.dimensions.width < 900 && 600,
            maxWidth:props.dimensions.width < 900  && 400,
            right: props.dimensions.width < 800 ? 10 :0 ,
             bottom: props.dimensions.width < 1400 ? props.dimensions.width < 800 ? -30 : -50 : -10,
          }}
            />
         </ImageCont>
         <ContentCont>
            <LogoImg 
              initial={{opacity:0,y:50}}
              animate={{opacity:1,y:[-50,20,0]}}
           
            src={logoOfBusiness}/>
            <ComingSoonText
                   initial={{opacity:0,y:50}}
                   animate={{opacity:1,y:[-50,20,0]}}
            >We are coming soon !</ComingSoonText>
       
           <div
           style={{
               display: "flex",
               flexDirection: "row",
               justifyContent: "space-between",
               alignItems: "center",
               alignContent:"center",
            //    width: "80%",
               marginleft:"auto",
               marginright:"auto",
               marginTop:props.dimensions.width < 800 ? 40 : 60,
           }}
           >
               <ReverseTimeCont time={days} title="Days" />
               <div 
               style={{
                   height: 50,
                   backgroundColor:"#A2D2FF",
                   width: "2px",
                //    marginTop:20,
               }}
                />
               <ReverseTimeCont time={hours} title="Hours" />
               <div 
               style={{
                   height: 50,
                   backgroundColor:"#A2D2FF",
                   width: "2px",
                //    marginTop:20,
               }}
                />
               
               <ReverseTimeCont time={minutes} title="Minutes" />
               <div 
               style={{
                   height: 50,
                   backgroundColor:"#A2D2FF",
                   width: "2px",
                //    marginTop:20,
               }}
                />
               <ReverseTimeCont time={seconds} title="Seconds" />
           </div>
           <ComingSoonDescription
           initial={{opacity:0,y:500}}
           animate={{opacity:1,y:[-50,0]}}
           >
               { settingsData  &&  settingsData.short_description}
           </ComingSoonDescription>

           <SubComp>
         {/* {social.map((data,i)=>{
             return(
                data.value !== null &&
                 <MapComp 
               
                 onClick={() =>
                    {
                       if(data.title == "instagram"){
                        window.open(data.value)
                       }else if(data.title == "facebook"){
                        window.open(data.value)
                       }else if(data.title == "whatsapp"){
                        // window.open("https://api.whatsapp.com/send?phone="+data.value)
                        window.open("tel:"+data.value)
                       }else if(data.title == "email"){
                        window.open(`mailto:${data.value}?subject=Subject&body=Body%20goes%20here`)
                       }else if(data.title == "linkedin"){
                        window.open(data.value)
                       }
                    
                 }}
                 >
       
                      <Image
                        whileHover={{scale:0.8}}
                       src={data.path} alt={data.title} />
                        <Titles>{data.value}</Titles> 
           
         
                    
                    
                 </MapComp>
             )
         })}    */}


            <MapComp 
               
               onClick={() =>
                  {
                     if(social[2].title == "whatsapp"){
                    //   window.open("https://api.whatsapp.com/send?phone="+social[2].value)
                      window.open("tel:"+social[2].value)
                     }
                  
               }}
               >
     
                    <Image
                      whileHover={{scale:0.8}}
                     src={social[2].path} alt={social[2].title} />
                      <Titles>{social[2].value}</Titles> 
         
       
                  
                  
               </MapComp>


               <MapComp 
               
               onClick={() =>
                  {
                     if(social[4].title == "email"){
                        window.open(`mailto:${social[4].value}?subject=Subject&body=Body%20goes%20here`)
                     }
                  
               }}
               >
     
                    <Image
                      whileHover={{scale:0.8}}
                     src={social[4].path} alt={social[4].title} />
                      <Titles>{social[4].value}</Titles> 
         
       
                  
                  
               </MapComp>

         </SubComp>
         </ContentCont>
        </MainCont>
        </div>
    )
}

export default ComingSoon

